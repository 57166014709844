import PropTypes from 'prop-types';
import "./styles/FullWidthArticle.scss";
import {FormattedMessage} from "react-intl";

const FullWidthArticle = ({article}) => (
    <div className="fw-article">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div
                className="fw-article-background"
                style={{
                  backgroundImage: `url(${article.image})`
                }}/>
          </div>
          <div className="col-12 col-md-6">
            <div className="fw-article-content">
              <div className="fw-article-content__wrapper">
                <h3>
                  {
                    article.title &&
                    <>
                      <FormattedMessage
                          {...article.title}
                          values={{
                            sup: (...chunks) => <sup>{chunks}</sup>
                          }}/>
                    </>
                  }
                  <br/>
                  {
                    article.subtitle &&
                    <small>
                      <FormattedMessage
                          {...article.subtitle}/>
                    </small>
                  }
                </h3>
                {
                  article.summary &&
                  <h4>
                    <FormattedMessage
                        {...article.summary}/>
                  </h4>
                }
                {
                  article.text &&
                  <FormattedMessage
                      {...article.text}
                      values={{
                        p: (...chunks) => <p>{chunks}</p>,
                        sup: (...chunks) => <sup>{chunks}</sup>
                      }}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

FullWidthArticle.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.object.isRequired,
    titleContainsHtml: PropTypes.bool,
    subtitle: PropTypes.object,
    summary: PropTypes.object,
    text: PropTypes.object.isRequired,
    textContainsHtml: PropTypes.bool,
    image: PropTypes.string
  }).isRequired
};

export default FullWidthArticle;