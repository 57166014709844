import gql from "graphql-tag";

//     OLD -> for createContactMutation -> mutation CreateContact($input: CreateContactInput!) {
export const createContactMutation = gql`
  mutation createContactHubspot($input: CreateContactHubSpotInput!) {
    createContactHubspot(input: $input) {
      contact {
        id
      }
    }
  }
`;

export const createResourceContactMutation = gql`
  mutation createServiceContact($input: CreateServiceContactInput!) {
    createServiceContact(input: $input) {
      contactService {
        id
      }
    }
  }
`;
