import { COUNTRY_MX } from "components/AuthModal/components/SignUpForm/components/CountriesField/consts"
import { isMEXICO } from "services/instances"

export const handleCountryIdForMX = (countryId) => {
  if (isMEXICO()) {
    countryId = COUNTRY_MX[0].value
    return countryId
  }

  return countryId
}