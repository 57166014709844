import PropTypes from "prop-types";
import classNames from "classnames/bind";
import HeaderCircleBtn from "components/Header/components/HeaderCircleBtn";

const HeaderSearchCircleButton = ({headerReducer, openSearchBar, closeSearchBar}) => (
    <HeaderCircleBtn
        className={classNames({
          "ignore-react-onclickoutside": headerReducer.searchBarIsOpen
        })}
        onClick={headerReducer.searchBarIsOpen ? closeSearchBar : openSearchBar}
        isActive={headerReducer.searchBarIsOpen}>
      <i className="sp-search"/>
    </HeaderCircleBtn>
);

HeaderSearchCircleButton.propTypes = {
  headerReducer: PropTypes.object,
  openSearchBar: PropTypes.func,
  closeSearchBar: PropTypes.func
};

export default HeaderSearchCircleButton;
