import { DEFAULT_ORDER_BY_VALUE } from "modules/products/ProductListPage/components/ProductList/constants/query_default_values";
import { getCurrentInstanceId } from "services/instances";
import {
  QUERY_PARAM_AB_1998_COMPLIANT,
  QUERY_PARAM_ANTIBACTERIAL,
  QUERY_PARAM_ANTIFUNGAL,
  QUERY_PARAM_ANTISTATIC,
  QUERY_PARAM_HEAT_SEALABLE,
  QUERY_PARAM_BACKING_DESCRIPTION,
  QUERY_PARAM_BLEACH_CLEANABLE,
  QUERY_PARAM_COMPASS_HB,
  QUERY_PARAM_COMPOSITION,
  QUERY_PARAM_DENIM_DYE_RESISTANT,
  QUERY_PARAM_DESIGN,
  QUERY_PARAM_ECOSENSE,
  QUERY_PARAM_FORMALDEHYDE_FREE,
  QUERY_PARAM_HAS_NOT_HEAVY_METALS,
  QUERY_PARAM_HYDROLYSIS,
  QUERY_PARAM_INTAGLIODESIGNS,
  QUERY_PARAM_IPA_CLEANABLE,
  QUERY_PARAM_MILDEW_RESISTANT,
  QUERY_PARAM_NO_BIOCIDES,
  QUERY_PARAM_NO_FLAME_RETARDANTS,
  QUERY_PARAM_OUTDOOR,
  QUERY_PARAM_POLYCARBONATE_RESIN_SYSTEM,
  QUERY_PARAM_PREDOMINANT_COLOR,
  QUERY_PARAM_PROP_65_COMPLIANT,
  QUERY_PARAM_PROSTOCK,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_SOFTSIDE,
  QUERY_PARAM_SULFIDE_STAIN_RESISTANT,
  QUERY_PARAM_TOPCOAT,
  QUERY_PARAM_PET_FRIENDLY,
  QUERY_PARAM_SUSTAINAILITY_SEARCH
} from "modules/products/ProductListPage/components/FiltersBar/constants/query_params";
import {
  QUERY_PARAM_SECTOR,
  QUERY_PARAM_BRAND,
  QUERY_PARAM_GROUPS_VALUES,
  QUERY_PARAM_GROUP,
} from "modules/products/ProductListPage/constants";
import _ from "lodash";
import {
  OUTDOOR_NO,
  OUTDOOR_YES,
} from "modules/products/ProductListPage/components/FiltersBar/constants/outdoor";
import QueryParams from "services/browser-history/QueryParams";
import { useLocation } from "react-router";
import arrayify from "services/browser-history/arrayify";

export default function useProductsQueryVariables() {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const pageSize = 12;

  let outdoorParam = queryParams.find(QUERY_PARAM_OUTDOOR) || null;
  let outdoor;
  if (outdoorParam) {
    switch (outdoorParam) {
      case OUTDOOR_YES:
        outdoor = true;
        break;
      case OUTDOOR_NO:
        outdoor = false;
        break;
      default:
        outdoor = null;
        break;
    }
  }

  // Sustainability Search params
  const sustainability_param = queryParams.find(QUERY_PARAM_SUSTAINAILITY_SEARCH);
  
  const convertSustainabilityParamToValidArray = arrayify(sustainability_param);

  const sustainabilityProperties = convertSustainabilityParamToValidArray.map(str => JSON.parse(str));
  
  const finalSustainabilityAttrs = {}

  sustainabilityProperties.map((property) => {
    Object.assign(finalSustainabilityAttrs, property);
  })

  let queryVariables = {
    // Filters
    orderBy: DEFAULT_ORDER_BY_VALUE,
    instanceIds: [getCurrentInstanceId()],
    predominantColor: queryParams.find(QUERY_PARAM_PREDOMINANT_COLOR),
    // This is the number of products to fetch per pattern, by default is 1 (because
    // I need the small thumbnail from the first one)
    // productFirst: queryParams.find(QUERY_PARAM_PREDOMINANT_COLOR) ? 10 : 1,
    sectorIds: queryParams.find(QUERY_PARAM_SECTOR),
    design: queryParams.find(QUERY_PARAM_DESIGN),
    composition: queryParams.find(QUERY_PARAM_COMPOSITION),
    outdoor: outdoor,
    topcoat: queryParams.find(QUERY_PARAM_TOPCOAT),
    backingDescription: queryParams.find(QUERY_PARAM_BACKING_DESCRIPTION),
    search: queryParams.find(QUERY_PARAM_SEARCH),
    // Other attributes boolean filters
    noBiocides: queryParams.find(QUERY_PARAM_NO_BIOCIDES) !== undefined,
    formaldehydeFree:
      queryParams.find(QUERY_PARAM_FORMALDEHYDE_FREE) !== undefined,
    noFlameRetardants:
      queryParams.find(QUERY_PARAM_NO_FLAME_RETARDANTS) !== undefined,
    antifungal: queryParams.find(QUERY_PARAM_ANTIFUNGAL) !== undefined,
    prop65Compliant:
      queryParams.find(QUERY_PARAM_PROP_65_COMPLIANT) !== undefined,
    polycarbonateResinSystem:
      queryParams.find(QUERY_PARAM_POLYCARBONATE_RESIN_SYSTEM) !== undefined,
    ecosense: queryParams.find(QUERY_PARAM_ECOSENSE) !== undefined,
    softside: queryParams.find(QUERY_PARAM_SOFTSIDE) !== undefined,
    prostock: queryParams.find(QUERY_PARAM_PROSTOCK) !== undefined,
    compassHb: queryParams.find(QUERY_PARAM_COMPASS_HB) !== undefined,
    intagliodesigns:
      queryParams.find(QUERY_PARAM_INTAGLIODESIGNS) !== undefined,
    ipaCleanable: queryParams.find(QUERY_PARAM_IPA_CLEANABLE) !== undefined,
    bleachCleanable:
      queryParams.find(QUERY_PARAM_BLEACH_CLEANABLE) !== undefined,
    ab1998Compliant:
      queryParams.find(QUERY_PARAM_AB_1998_COMPLIANT) !== undefined,
    denimDyeResistant:
      queryParams.find(QUERY_PARAM_DENIM_DYE_RESISTANT) !== undefined,
    isAntistatic: queryParams.find(QUERY_PARAM_ANTISTATIC) !== undefined,
    heatSealable: queryParams.find(QUERY_PARAM_HEAT_SEALABLE) !== undefined,
    sulfideStainResistant:
      queryParams.find(QUERY_PARAM_SULFIDE_STAIN_RESISTANT) !== undefined,
    hydrolysis: queryParams.find(QUERY_PARAM_HYDROLYSIS) !== undefined,
    mildewResistant:
      queryParams.find(QUERY_PARAM_MILDEW_RESISTANT) !== undefined,
    isAntibacterial: queryParams.find(QUERY_PARAM_ANTIBACTERIAL) !== undefined,
    hasNotHeavyMetals:
      queryParams.find(QUERY_PARAM_HAS_NOT_HEAVY_METALS) !== undefined,
    brandsIds: queryParams.find(QUERY_PARAM_BRAND),
    saleable: true,
    petFriendly: queryParams.find(QUERY_PARAM_PET_FRIENDLY) !== undefined,
    ...finalSustainabilityAttrs,
    // WARNING: slugs param for now used only for group
    slugs:
      QUERY_PARAM_GROUPS_VALUES[queryParams.find(QUERY_PARAM_GROUP)]?.slugs,
    // Pagination
    ...queryParams.paginationGraphQLVariables(pageSize),
  };

  // For unchecked checkboxes, so the filter won't omit them.
  // Sending them as "false" means the API will search for those with that value in the database, which isn't the expected behaviour.
  queryVariables = _.omitBy(queryVariables, (value) => value === false);

  // If there's a predominant color, I need to fetch all products with that color. So, let's remove the default limit.
  if (queryVariables.predominantColor) delete queryVariables.productFirst;

  // IMPORTANT: THIS SHOULD BE A TEMPORAL FIX
  // This is done because the api expects it not to be an array but a comma separated list, but as it happens with all
  // lists, there should be a more global solution.
  // TODO make the .find() method in QueryParams class to return a comma separated list instead of an array
  if (_.isArray(queryVariables.predominantColor))
    queryVariables.predominantColor = queryVariables.predominantColor.join(",");

  return queryVariables;
}
