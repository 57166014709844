import {connect} from "react-redux";
import RecordsPage from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const RecordsPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordsPage);

export default RecordsPageContainer;