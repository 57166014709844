const COUNTRIES_INSTANCE_USA = [
  {
    label: "United States",
    value: "Q291bnRyeU5vZGU6MjM0",
  },
  {
    label: "Canada",
    value: "Q291bnRyeU5vZGU6Mzg=",
  },
  {
    label: "Puerto Rico",
    value: "Q291bnRyeU5vZGU6MTgz",
  },
];

export const COUNTRY_MX = [
  {
    label: "México",
    value: "Q291bnRyeU5vZGU6MTU4",
  },
];

export const COUNTRIES_BY_INSTANCE = {
  [process.env.REACT_APP_USA_INSTANCE_ID]: COUNTRIES_INSTANCE_USA,
  [process.env.REACT_APP_SM_INSTANCE_ID]: COUNTRIES_INSTANCE_USA,
  [process.env.REACT_APP_RC_INSTANCE_ID]: COUNTRIES_INSTANCE_USA,
  [process.env.REACT_APP_EU_INSTANCE_ID]: (countries) =>
    countries.edges.filter(({ node: { continent } }) => continent === "Europe"),
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: (countries) =>
    countries.edges.filter(
      ({ node: { name, continent } }) =>
        ["North America", "South America"].includes(continent) &&
        !["Canada", "United States", "México", "Puerto Rico"].includes(name)
    ),
  [process.env.REACT_APP_MX_INSTANCE_ID]: COUNTRY_MX,
};
