import nextArrow from "../../TransformlifesSection/img/right-arrow.png";
import prevArrow from "../../TransformlifesSection/img/left-chevron.png";

export const SLIDER_OPTIONS_PLANTS_COMPANY = {
  dots: true,
  infinite: true,
  lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  arrows: true,
  nextArrow: (
   <div>
    <img className="company-plants--slider-next-arrow" src={nextArrow} width={30} height={30} alt="icon-next-img" />
   </div> 
  ),
  prevArrow: (
   <div>
    <img className="company-plants--slider-prev-arrow" src={prevArrow} width={30} height={30} alt="icon-prev-img" />
   </div> 
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
      }
    }
  ]
};