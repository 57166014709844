import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { SLIDER_OPTIONS_PLANTS_COMPANY } from './constants/sliderOptions';
import './styles/Banner.scss';

const Banner = ({ items }) => {
  return (
    <Slider className='company-plants--banner-slider' {...SLIDER_OPTIONS_PLANTS_COMPANY}>
      {items.map(({ title, description, bannerId, img }) => (
        <section key={bannerId} className='h-100'>
          <div
            className='h-100 company-plants--banner-slide'
            style={{
              backgroundImage: `url(${img})`
            }}
          >
            <div className='container d-flex align-items-end h-100 pb-4 text-white'>
              <section className='banner-plants--section'>
                <h1 className='banner-plants--title'>{title}</h1>
                <p className='banner-plants--description'>{description}</p>
              </section>
            </div>
          </div>
        </section>
      ))}
    </Slider>
  );
};

Banner.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Banner;