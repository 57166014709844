import PropTypes from 'prop-types';
import {Query} from "react-apollo";
import ProjectCard from "modules/projects/ProjectsListPage/components/ProjectCard";
import {FormattedMessage} from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import relatedProjectsQuery from "modules/projects/SingleProjectPage/components/RelatedProjects/query";
import {H4_TAG} from "utils/components/TitleUnderlined/constants";
import LoadingMessage from "utils/components/LoadingMessage";

const RelatedProjects = ({projectId}) => {
  if (!projectId)
    return null;

  return (
    <Query
        query={relatedProjectsQuery}
        variables={{
          projectId: projectId,
          first: 3
        }}>
      {({data}) => {

        if (data) {
          const {projects} = data;

          return (
              <>
                {
                  projects.edges.length > 0 &&
                  <TitleUnderlined
                      // TODO: This TitleUnderlined must be a H4 but the styles do not work
                      // hTag={H4_TAG}
                      align="center"
                      color="black"
                      className="text-size-h3 text-size-sm-h2 mt-5">
                    <FormattedMessage
                        id="SingleProjectPage.relatedProjects"
                        description="Related projects title in single project page"
                        defaultMessage="Proyectos relacionados"/>
                  </TitleUnderlined>
                }
                <div className="row">
                  {
                    projects.edges.map((project, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4 mb-4">
                          <ProjectCard
                              titleHTag={H4_TAG}
                              project={project.node}/>
                        </div>
                    ))
                  }
                </div>
              </>
          )
        }

        return <LoadingMessage/>
      }}
    </Query>
  )
}

RelatedProjects.propTypes = {
  projectId: PropTypes.string
};

export default RelatedProjects