import PropTypes from "prop-types";
// import classNames from "classnames/bind";
// import { FLAME_RETARDANCY_NO_VALUE } from "modules/products/ProductDetailsPage/components/ProductSpecs/contants";
// import PdfResultRow from "./components/PdfResultRow";
// import { isMARINE } from "services/instances";

const FlameRetardancyAccordion = ({ data }) =>
  data.map(({ test, result }) => (
    <tr key={`key_${test}`}>
      <td colSpan={!result ? 2 : 0}>{test}</td>
      <td>
        <span className="text-capitalize">{result}</span>
      </td>
    </tr>
    /* TODO: uncommit this to show redirect to pdf if its enable to portal global */
    // <tr
    //   key={`key_${test}`}
    //   className={classNames({
    //     "flame-retardancy--pdf flame-retardancy--pdf-global":
    //       pdf !== FLAME_RETARDANCY_NO_VALUE,
    //     "flame-retardancy--pdf flame-retardancy--pdf-marine":
    //       pdf !== FLAME_RETARDANCY_NO_VALUE && isMARINE(),
    //   })}
    // >
    //   <td colSpan={pdf === FLAME_RETARDANCY_NO_VALUE && !result ? 2 : 0}>
    //     {pdf !== FLAME_RETARDANCY_NO_VALUE ? (
    //       <a href={pdf} target="_blank" rel="noopener noreferrer">
    //         {test}
    //       </a>
    //     ) : (
    //       test
    //     )}
    //   </td>
    //   <PdfResultRow pdf={pdf} result={result} />
    // </tr>
  ));

FlameRetardancyAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      pdf: PropTypes.string,
      result: PropTypes.string,
    })
  ).isRequired,
};

export default FlameRetardancyAccordion;
