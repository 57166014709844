import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles/SectorCard.scss";
import {PRODUCTS_LIBRARY} from "routes";
import {QUERY_PARAM_SECTOR} from "modules/products/ProductListPage/constants";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {isMARINE, getTranslatedLink} from "services/instances";

export default function SectorCard({sector, reversed}) {
  return (
      <div
          className={classNames({
            "sector-card": true,
            "sector-card--reverse": reversed
          })}>
        <div className="w-100">
          <img
              src={sector.node.imageThumbMedium}
              className="img-fluid w-100"
              alt={`sector ${sector.node.name}`}
              title={`sector ${sector.node.name}`}/>
        </div>
        <div className="container pl-0 pr-0">
          <div className={classNames({
            "p-4 h-100 d-flex flex-column justify-content-center": true,
            "text-right": reversed
          })}>
            <div>
              <h2>{sector.node.name}</h2>
            </div>
            <div>
              <p>
                {sector.node.description}
              </p>
            </div>
            <div>
              <p className="text-size-extra-small text-size-lg-small">
                <Link
                    className={classNames({
                      "btn btn-dark": true,
                      "btn-prussian-blue": isMARINE()
                    })}
                    to={{
                      pathname: getTranslatedLink(PRODUCTS_LIBRARY, {}),
                      search: `?${QUERY_PARAM_SECTOR}=${sector.node.id}`
                    }}>
                  <FormattedMessage
                      id="SingleMarketPage.seeMore"
                      description="See more button label in sector section"
                      defaultMessage="ver más"/>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
}

SectorCard.propTypes = {
  sector: PropTypes.object.isRequired,
  reversed: PropTypes.bool.isRequired
};