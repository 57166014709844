import TitleUnderlined from "utils/components/TitleUnderlined";
import { FormattedMessage } from "react-intl";
import ServiceLine from "modules/Home/components/ServicesLines/components/ServiceLine";
import { useQuery } from "react-apollo";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import LoadingMessage from "utils/components/LoadingMessage";
import servicesQuery from "./query";

export default function ServicesLines() {
  const { loading, error, data } = useQuery(servicesQuery, {
    variables: {
      orderBy: "order",
    },
  });

  return (
    <div className="product-lines pt-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center">
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="ServicesLines.title"
                description="Title of segments section"
                defaultMessage="One Stop Shop"
              />
            </TitleUnderlined>
            <p className="text-size-small text-size-lg-base mb-4 mb-lg-5">
              <FormattedMessage
                id="ServicesLines.description"
                description="description of segments section"
                defaultMessage="World-class coated fabric is only the start to a great finished product. Spradling Resources offers the creative solutions to get you one or even two steps closer to your final product. Among the additional value-added service offerings are complete lamination solutions, perforation, custom debossing, technical coated fabrics sourcing and leather cutting. Spradling is your One-Stop Shop."
              />
            </p>
          </div>
        </div>
        <div className="row">
          {error && (
            <div className="w-100">
              <LoadingMessage />
            </div>
          )}
          {!loading && data && data.services
            ? data.services.edges.map(({ node: service }) => (
                <div
                  key={`key_${service.id}`}
                  className="col-12 col-md-6 col-lg-4 mb-4"
                >
                  <ServiceLine serviceLine={service} />
                </div>
              ))
            : [1, 2, 3].map((i) => (
                <div key={`key_${i}`} className="col-12 col-md-6 col-lg-4 mb-4">
                  <ServiceLine />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}
