import PropTypes from "prop-types";
import { Button, Modal } from "reactstrap";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { getValidationSchema } from "./constants/validationSchemas";
import EditProfileInputs from "./components/EditProfileInputs";
import CompanyDetailsInputs from "./components/CompanyDetailsInputs";
import LocationInputs from "./components/LocationInputs";
import AddressBookInputs from "./components/AddressBookInputs";
import { getSectionTitle } from "./constants/getSectionTitle";
import { getInputSchemaToUpdateProfile } from "./constants/getInputSchemaToMutation";
import { useMutation, useQuery } from "react-apollo";
import { UPDATE_USER_PROFILE } from "./mutation";
import meQuery from "utils/queries/MeQuery";
import "./styles/ModalEditInformationProfile.scss";
import RequiredInputs from "./components/RequiredInputs";

export const ModalEditInformationProfile = ({
  showModal,
  callbackToHide,
  section,
}) => {
  const intl = useIntl();
  const { data: userInformation, refetch } = useQuery(meQuery);
  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE);

  const { defaultSchema, initialValues } = getValidationSchema({
    intl,
    section,
    userInfo: userInformation,
  });

  const { sectionTitle } = getSectionTitle(intl);

  const handleOnSubmit = async (values) => {
    const input = getInputSchemaToUpdateProfile(section, sectionTitle, values);
    const dataToDoMutation = {
      userId: userInformation.me.id,
      ...input,
    };

    try {
      const { data } = await updateUserProfile({
        variables: {
          input: dataToDoMutation,
        },
      });
      if (data) {
        callbackToHide();
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={showModal} size="lg" centered={true}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={defaultSchema}
      >
        {({ values: { countryId, regionId } }) => (
          <Form>
            <div className="p-5">
              <h3 className="modal-title">
                <FormattedMessage
                  id="RecordsPage.editYourData"
                  description="modal Title Edit Account Information"
                  defaultMessage="Edit your data"
                />
              </h3>
              {section.title === sectionTitle.profileSectionTitle && (
                <EditProfileInputs />
              )}
              {section.title === sectionTitle.companySectionTitle && (
                <CompanyDetailsInputs />
              )}
              {section.title === sectionTitle.locationSectionTitle && (
                <LocationInputs countryId={countryId} regionId={regionId} />
              )}
              {section.title === sectionTitle.addressBookSectionTitle && (
                <AddressBookInputs />
              )}
              {!userInformation?.me?.profile && (
                <RequiredInputs
                  section={section?.title}
                  countryId={countryId}
                  regionId={regionId}
                />
              )}
              <div className="mt-5 d-block d-lg-flex justify-content-end">
                <Button
                  color="dark"
                  onClick={callbackToHide}
                  className="col-12 col-md-12 col-lg-2 mr-2"
                >
                  <FormattedMessage
                    id="RecordsPage.cancel"
                    description="Text Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  color="dark"
                  type="submit"
                  className="mt-2 mt-md-2 mt-lg-0 col-12 col-md-12 col-lg-4"
                  disabled={loading}
                >
                  {!loading ? (
                    <FormattedMessage
                      id="RecordsPage.updateInformation"
                      description="Text Update Information"
                      defaultMessage="Update Information"
                    />
                  ) : (
                    <FormattedMessage
                      id="RecordsPage.updateInformationLoading"
                      description="Text Update Information"
                      defaultMessage="Updating..."
                    />
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ModalEditInformationProfile.propTypes = {
  showModal: PropTypes.bool,
  callbackToHide: PropTypes.func,
  section: PropTypes.object,
};
