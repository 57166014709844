import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import classNames from "classnames/bind";
import countriesQuery from "components/AuthModal/components/SignUpForm/components/CountriesField/query";
import { useIntl, defineMessages } from "react-intl";
import { Field, useFormikContext } from "formik";
import { COUNTRIES_BY_INSTANCE } from "./consts";
import { getCurrentInstanceId, isEUROPE, isLATAM } from "services/instances";

export default function CountriesField({ className, countryByZipcode }) {
  const intl = useIntl();
  const [countries, setCountries] = useState(null);
  const { setFieldValue } = useFormikContext();
  const { loading, data, fetchMore } = useQuery(countriesQuery, {
    variables: {
      first: 100,
    },
  });
  const memoizedFetchMore = useCallback(fetchMore, [fetchMore]);

  /**
   * Fetch more if there's next page
   */
  useEffect(() => {
    if (data && data.countries) {
      setCountries(data.countries);
      if (data.countries.pageInfo.hasNextPage)
        memoizedFetchMore({
          variables: {
            after: data.countries.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.countries.edges;
            const pageInfo = fetchMoreResult.countries.pageInfo;

            return newEdges.length
              ? {
                  ...previousResult,
                  // Put the new patterns at the end of the list and update `pageInfo`
                  // so we have the new `endCursor` and `hasNextPage` values
                  countries: {
                    ...previousResult.countries,
                    edges: [...previousResult.countries.edges, ...newEdges],
                    pageInfo,
                  },
                }
              : previousResult;
          },
        });
    }

    if (countries && countryByZipcode) {
      const filteredCountry = countries.edges.filter(
        ({ node: { code2 } }) => code2 === countryByZipcode
      )[0]?.node;

      if (filteredCountry) setFieldValue("countryId", filteredCountry.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, memoizedFetchMore, countryByZipcode]);

  return (
    <Field
      component="select"
      id="countryId"
      name="countryId"
      className={classNames({
        "form-control": true,
        [`${className}`]: className !== undefined,
      })}
    >
      <option value="">
        {loading
          ? intl.formatMessage(
              defineMessages({
                countryIdLoading: {
                  id: "SignUpForm.countryIdLoading",
                  description:
                    "Loading Countries options in Country field of the Sign Up form",
                  defaultMessage: "Cargando países...",
                },
              }).countryIdLoading
            )
          : intl.formatMessage(
              defineMessages({
                countryIdDefault: {
                  id: "SignUpForm.countryIdDefault",
                  description: "Default of Country field of the Sign Up form",
                  defaultMessage: "Seleccione un pais",
                },
              }).countryIdDefault
            )}
      </option>
      {!loading && !isLATAM() && !isEUROPE()
        ? COUNTRIES_BY_INSTANCE[getCurrentInstanceId()].map(
            ({ label, value }) => (
              <option key={`key_${label}`} value={value}>
                {label}
              </option>
            )
          )
        : countries &&
          COUNTRIES_BY_INSTANCE[getCurrentInstanceId()](countries).map(
            ({ node: { name: label, id: value } }) => (
              <option key={`key_${label}`} value={value}>
                {label}
              </option>
            )
          )}
      {/* NOTE: removed render all countries cuz now we sent countries render by instance */}
      {/* {!loading &&
          countries &&
          countries.edges.map((country, index) => (
            <option key={index} value={country.node.id}>
              {country.node.name}
            </option>
          ))} */}
    </Field>
  );
}

CountriesField.propTypes = {
  className: PropTypes.string,
  countryByZipcode: PropTypes.string,
};
