import PropTypes from "prop-types";
import classNames from "classnames/bind";
import HeaderCircleBtn from "components/Header/components/HeaderCircleBtn";
import UserProfilePic from "utils/components/UserProfilePic";

export default function HeaderAuthCircleButton({headerReducer, authReducer, openAuthMenu, closeAuthMenu}) {
  return (
      <HeaderCircleBtn
          className={classNames({
            "ignore-react-onclickoutside": headerReducer.authMenuIsOpen
          })}
          onClick={headerReducer.authMenuIsOpen ? closeAuthMenu : openAuthMenu}
          isActive={headerReducer.authMenuIsOpen}>
        {
          authReducer.isAuthenticated
              ?
              <UserProfilePic/>
              :
              <i className="sp-person"/>
        }
      </HeaderCircleBtn>
  )
}

HeaderAuthCircleButton.propTypes = {
  headerReducer: PropTypes.object,
  openAuthMenu: PropTypes.func,
  closeAuthMenu: PropTypes.func,
  authReducer: PropTypes.shape({
    isAuthenticated: PropTypes.bool
  })
};