import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { FILTERS_MARKETS_USA } from "./constants/filtersMarketsUSA";

const SectorFieldToUSA = () => {
  return (
    <Field
      as="select"
      id="marketWebUsa"
      name="marketWebUsa"
      className="form-control"
    >
      <>
        <FormattedMessage
          id="SectorsField.label"
          description="Label for sectors field"
          defaultMessage="Selecciona un sector"
        >
          {(label) => <option value="">{label}</option>}
        </FormattedMessage>
        {FILTERS_MARKETS_USA.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </>
    </Field>
  );
};

export default SectorFieldToUSA;
