import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/StoreFinderBoxLayout.scss";
import MethodIcon from "modules/stores/WhereToBuyPage/components/StoreFinder/components/MethodIcon";

export default function StoreFinderBoxLayout({title, icon, children, noPadding = false, goBack }) {
  return (
      <div className="store-finder-box">
        {
          title &&
          <header className="store-finder-box--container">
            <div className="d-flex flex-row align-items-center">
            {goBack && 
              <button className="btn store-finder-box--btn mr-3" onClick={goBack}>
                <i className="fa fa-chevron-left" />
              </button>
            }
            <span className="ml-auto">
              <MethodIcon icon={icon}/>
              <span className="store-finder-box--title text-size-md--down-small">{title}</span>
            </span>
            </div>
          </header>
        }
        <div className={classNames({
          "store-finder-box__content": true,
          "p-3": !noPadding
        })}>
          {children}
        </div>
      </div>
  )
}

StoreFinderBoxLayout.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  icon: PropTypes.oneOf(["fabric", "store"]),
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  goBack: PropTypes.func
};