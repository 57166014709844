import {FormattedMessage} from "react-intl";

const LoadingMessage = () => (
    <div className="pt-3 pb-3">
      <p className="text-center">
        <i className="fa fa-spinner fa-spin"/>&nbsp;
        <FormattedMessage
            id="LoadingMessage.label"
            description="Label to default loading Message"
            defaultMessage="Loading..."/>
      </p>
    </div>
);

export default LoadingMessage;