import gql from "graphql-tag";

const flatpagesQuery = gql`
  query FlatpagesQuery($instanceIds: [ID]!) {
    flatpages(instances: $instanceIds) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

export default flatpagesQuery;
