import PropTypes from 'prop-types';
import iconClose from "./img/close-icon-glossary.png";
import iconSearch from "../../../../../../img/icon-search.svg"

const GlossaryHeader = ({
  handleHideGlossary,
  handleChangeWordToSearchInGlossary,
  fetchSearchResults,
  wordToSearchInGlossary
}) => {
  return (
    <header className="glossary-section--header d-flex align-items-center justify-content-center">
      <img
        className="icon-close cursor-pointer"
        src={iconClose}
        alt="icon-close"
        onClick={handleHideGlossary}
      />
      <div className="glossary-section--header-content bg-white col-10 d-flex justify-content-between align-items-center p-0 border-0">
        <input
          name="search"
          type="text"
          className="glossary-search-input h-100 border-0 pl-3 col-8"
          placeholder="Search for a term..."
          onChange={(e) => handleChangeWordToSearchInGlossary(e)}
        />
        <div className="glossary-section--header-search m-0 col-4 d-flex align-items-center text-white h-100">
          <img
            className="ml-3 cursor-pointer"
            src={iconSearch}
            onClick={() => fetchSearchResults(wordToSearchInGlossary)}
            alt="icon-search" width={24} height={24} />
        </div>
      </div>
    </header>
  );
};

GlossaryHeader.propTypes = {
  handleHideGlossary: PropTypes.func.isRequired,
  handleChangeWordToSearchInGlossary: PropTypes.func.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  wordToSearchInGlossary: PropTypes.string.isRequired
};

export default GlossaryHeader;