import { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import "modules/stores/WhereToBuyPage/components/StoreFinder/styles/StoreFinder.scss";
import { Step, Steps, Wizard } from "react-albus";
import { SCRIPT_JS_BASE_PROPS } from "services/google-maps";
import LocationsListContainer from "./components/LocationsList/container";
import { getCurrentInstanceId } from "services/instances";
import SearchByProductContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchByProduct/container";
import SearchByLocationContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchByLocation/container";
import SelectSearchMethodContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SelectSearchMethod/container";
import SearchOnlyByLocation from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchOnlyByLocation";
import { useLocation } from "react-router";
import QueryParams from "services/browser-history/QueryParams";
import { isLATAM, isMEXICO } from "services/instances";
// import StoresGoogleMapContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoresGoogleMap/container";

const StoresGoogleMapContainer = lazy(() =>
  import(
    "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoresGoogleMap/container"
  )
);

export function shouldRenderStoreFinder() {
  const currentInstance = getCurrentInstanceId();
  const blacklistedInstances = [
    process.env.REACT_APP_SM_INSTANCE_ID,
    process.env.REACT_APP_RC_INSTANCE_ID,
    process.env.REACT_APP_EU_INSTANCE_ID,
  ];
  return blacklistedInstances.indexOf(currentInstance) === -1;
}

export default function StoreFinder({ setPatternId }) {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);

  if (!shouldRenderStoreFinder()) return null;

  if (queryParams.find("productId"))
    setPatternId(queryParams.find("productId"));

  /**
   * This function is called by the wizard and helps me to redirect in case of id
   **/
  function onNext({ push }) {
    if (queryParams.find("productId")) push("locationsList");
  }

  return (
    <div className="locations-map-section">
      <div className="locations-map-section__finder-box">
        <Wizard onNext={onNext}>
          <Steps>
            <Step id="selectMethod">
              {({ push }) => (
                <SelectSearchMethodContainer
                  onClickProduct={() => push("searchByProduct")}
                  onClickLocation={() => push("searchByLocation")}
                />
              )}
            </Step>
            <Step id="searchByProduct">
              {({ push, previous }) => (
                <SearchByProductContainer
                  onClickNext={() => push("locationsList")}
                  onClickPrevious={() => previous()}
                />
              )}
            </Step>
            <Step id="searchByLocation">
              {({ push, previous }) =>
                isLATAM() || isMEXICO() ? (
                  <SearchOnlyByLocation
                    onClickNext={() => push("locationsList")}
                    onClickPrevious={() => previous()}
                  />
                ) : (
                  <SearchByLocationContainer
                    onClickNext={() => push("locationsList")}
                    onClickPrevious={() => previous()}
                  />
                )
              }
            </Step>
            <Step id="locationsList">
              {({ push, previous }) => (
                <LocationsListContainer
                  onClickBack={() => push("selectMethod")}
                  onClickPrevious={() => previous()}
                />
              )}
            </Step>
          </Steps>
        </Wizard>
      </div>
      {/* The empty callback div is enough because I already have an skeleton background for the loading map */}
      <Suspense fallback={<div />}>
        <StoresGoogleMapContainer
          {...SCRIPT_JS_BASE_PROPS}
          center={{
            lat: 34.555941,
            lng: -41.064619,
          }}
          zoom={3}
          containerElement={<div id="map" className="locations-map" />}
          mapElement={<div style={{ height: `674px`, width: `100%` }} />}
        />
      </Suspense>
    </div>
  );
}

StoreFinder.propTypes = {
  setPatternId: PropTypes.func.isRequired,
};
