import gql from "graphql-tag";

export const citiesQuery = gql`
  query CitiesQuery (
    $regionId: ID,
    $query: String
  ){
    cities(
      region: $regionId,
      name_Icontains: $query
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const citiesByCountryIdQuery = gql`
  query CitiesQuery (
    $regionId: ID,
    $query: String
  ){
    cities(
      country: $regionId,
      name_Icontains: $query
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

// export default citiesQuery;
