import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import "./styles/SignInForm.scss";
import tokenAuth from "components/AuthModal/components/SignInForm/mutation";
import { graphql } from "react-apollo";
import { authLogin } from "services/auth";
import { apolloClient } from "apollo/client";
import { Link } from "react-router-dom";
import { AUTH_FORGOT_PASSWORD } from "routes";
import { isMARINE, getTranslatedLink } from "services/instances";

export function SignInForm({ onAfterSubmit, toggleAuthModal }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  /**
   * Method to call tokenAuth mutation
   * @param email
   * @param password
   * @return {Promise<any>}
   */
  function login(email, password) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: tokenAuth,
          variables: {
            input: {
              email: email,
              password: password,
            },
          },
        })
        .then((response) => {
          const { tokenAuth } = response.data;
          authLogin(tokenAuth.token).then(() => {
            resolve();
          });
        })
        .catch((err) => {
          const { graphQLErrors } = err;
          reject(graphQLErrors);
        });
    });
  }

  /**
   * Handler of the form submit.
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const promise = login(email, password);
    promise.then(() => {
      setLoading(false);
      if (onAfterSubmit) onAfterSubmit();
    });
    promise.catch((errors) => {
      setLoading(false);
      setErrors(errors.map((error) => error.message));
    });
  }

  return (
    <div className="sign-in-form">
      <form id="sign-in-form" onSubmit={handleSubmit} className="form">
        <div className="form-row">
          <div className="form-group col-12">
            <label htmlFor="email">
              <FormattedMessage
                id="SignInForm.emailField"
                description="Email field of the Sign In form"
                defaultMessage="Correo Electronico"
              />
              *
            </label>
            <input
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              type="email"
              className="form-control form-control-lg"
            />
          </div>
          <div className="form-group col-12">
            <label htmlFor="password">
              <FormattedMessage
                id="SignInForm.password"
                description="Password field of the Sign In form"
                defaultMessage="Contraseña"
              />
              *
            </label>
            <input
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
              className="form-control form-control-lg"
            />
            <Link
              to={getTranslatedLink(AUTH_FORGOT_PASSWORD)}
              onClick={toggleAuthModal}
            >
              <small
                id="emailHelp"
                className="form-text text-muted text-underline"
              >
                <FormattedMessage
                  id="SignInForm.forgotPassword"
                  description="Forgot password link text in the Sign In form"
                  defaultMessage="Olvidaste tu contraseña?"
                />
              </small>
            </Link>
          </div>
        </div>
        <div className="text-center">
          {errors.map((error, index) => (
            <div key={index} className="invalid-feedback d-block">
              {error}
            </div>
          ))}
        </div>
        <div className="text-center">
          <button
            disabled={loading}
            type="submit"
            className={classNames({
              "btn btn-dark btn-lg": true,
              "btn-prussian-blue": isMARINE(),
            })}
          >
            {loading ? (
              <FormattedMessage
                id="SignInForm.submitButtonLoading"
                description="Submit button of the Sign In form in loading state"
                defaultMessage="Ingresando"
              />
            ) : (
              <FormattedMessage
                id="SignInForm.submitButton"
                description="Submit button of the Sign In form"
                defaultMessage="Ingresar"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

SignInForm.propTypes = {
  onAfterSubmit: PropTypes.func,
  toggleAuthModal: PropTypes.func,
};

export default graphql(tokenAuth, { name: "tokenAuth" })(SignInForm);
