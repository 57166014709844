import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/styles/RequestedSampleCard.scss";
import { FormattedMessage } from "react-intl";
import { removeFromCartMutation } from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/mutations";
import { useMutation } from "@apollo/react-hooks";
import cartQuery from "components/Header/components/HeaderCartButton/query";
import IconCatalogue from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/IconCatalogue";
import QuantityInput from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/QuantityInput";
import { getCurrentInstanceId } from "services/instances";

export default function RequestedSampleCard({ item, cartId }) {
  const [removeFromCart, { loading }] = useMutation(removeFromCartMutation, {
    update(cache) {
      const current = cache.readQuery({
        query: cartQuery,
        variables: {
          id: cartId,
          instanceId: getCurrentInstanceId(),
        },
      });
      const updated = {
        ...current,
        cart: {
          ...current.cart,
          items: {
            ...current.cart.items,
            edges: current.cart.items.edges.filter(
              ({ node }) => node.id !== item.id
            ),
          },
        },
      };
      cache.writeQuery({
        query: cartQuery,
        data: updated,
      });
    },
    onError() {
      // probably because it was already removed!
      // TODO do something here
    },
  });

  let name, image, referenceName, referenceCode;
  if (item.product) {
    name = item.product.pattern.name;
    image = (
      <img
        src={item.product.imageThumbSmall}
        alt={item.product.color}
        className="img-fluid"
      />
    );
    referenceName = item.product.color;
    referenceCode = item.product.stock;
  } else {
    name = item.pattern.name;
    image = <IconCatalogue />;
    referenceName = (
      <FormattedMessage
        id="RequestedSampleCard.sampleCard"
        defaultMessage="Catálogo"
      />
    );
  }

  /**
   * Handles click on the delete button
   */
  function handleDeleteBtnClick() {
    removeFromCart({
      variables: {
        input: {
          cartId: cartId,
          instanceId: getCurrentInstanceId(),
          itemId: item.id,
        },
      },
    });
  }

  return (
    <div
      className={classNames({
        "requested-sample-card pb-sm-0 pb-md-0 pb-lg-3": true,
        "requested-sample-card--product": item.product !== null,
        "requested-sample-card--pattern": item.pattern !== null,
        "requested-sample-card--deleting": loading,
      })}
    >
      <div className="row align-items-center text-center text-md-left">
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-3 mb-md-0">
          <h3 className="mb-0">{name}</h3>
        </div>
        <div className="col-12 col-sm-2 col-md-3 col-lg-2 mb-3 mb-md-0 text-center">
          {image}
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 mb-3 mb-md-0">
          <p className="mb-0">
            <strong>{referenceName}</strong>
          </p>
          {referenceCode && (
            <p className="mb-0 gray-light">
              <small>{referenceCode}</small>
            </p>
          )}
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mb-3 mb-md-0 text-center">
          <div className="row justify-content-center">
            <div className="col-6 col-sm-12">
              <QuantityInput
                itemId={item.id}
                limit={item.product !== null ? 3 : 1}
                initialValue={item.quantity}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-md-right">
          <button
            className="btn btn-link gray-500 pr-md-0"
            disabled={loading}
            onClick={handleDeleteBtnClick}
          >
            <i className="fa fa-trash" />
            &nbsp;
            <FormattedMessage
              id="RequestedSampleCard.removeBtnLabel"
              defaultMessage="Eliminar"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

RequestedSampleCard.propTypes = {
  cartId: PropTypes.string.isRequired,
  hideRequestCatalog: PropTypes.bool,
  hiderequestSample: PropTypes.bool,
  setCartId: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    pattern: PropTypes.shape({
      name: PropTypes.string,
    }),
    product: PropTypes.shape({
      pattern: PropTypes.shape({
        name: PropTypes.string,
      }),
      imageThumbSmall: PropTypes.string,
      color: PropTypes.string,
      stock: PropTypes.string,
    }),
  }).isRequired,
};
