import gql from "graphql-tag";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

const documentsQuery = gql`
  query DocumentsQuery(
    $first: Int
    $last: Int
    $afterCursor: String
    $beforeCursor: String
    $categorySlug: String
    $searchDocument: String
    $instances: [ID]!
    $orderBy: String
  ) {
    documentCategory(slug: $categorySlug) {
      id
      name
      documents(
        first: $first
        last: $last
        after: $afterCursor
        before: $beforeCursor
        name_Icontains: $searchDocument
        instances: $instances
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          node {
            id
            name
            description
            file
          }
        }
      }
    }
  }
  ${pageInfoFragment}
`;

export default documentsQuery;
