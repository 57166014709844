import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import usePaginator from "utils/hooks/usePaginator"
import './styles/Paginator.scss';

const Paginator = ({
  pageSize,
  elementId,
  pageInfo,
  totalCount,
  history,
  location
}) => {
  const {paginationInfo, handlePageClick} = usePaginator({
    pageSize,
    elementId,
    pageInfo,
    totalCount,
    history,
    location
  })

  if (totalCount === 0)
    return null;

  return (
    <ReactPaginate
      pageCount={paginationInfo?.pages}
      forcePage={paginationInfo?.currentPage - 1}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      previousLabel={
        <FormattedMessage
          id="ProductsListPagination.previousPage"
          description="Label for pagination button to the previous page"
          defaultMessage="Anterior"/>
      }
      nextLabel={
        <FormattedMessage
          id="ProductsListPagination.nextPage"
          description="Label for pagination button to the next page"
          defaultMessage="Siguiente"/>
      }
      breakLabel={"..."}
      breakClassName={"paginator__break"}
      containerClassName={"paginator"}
      subContainerClassName={"pages pagination"}
      activeClassName={"paginator__page-number--active"}
      previousClassName={"paginator__previous-page"}
      nextClassName={"paginator__next-page"}/>
  )
}

Paginator.propTypes = {
  pageSize: PropTypes.number.isRequired,
  elementId: PropTypes.string.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string,
    endCursor: PropTypes.string
  }).isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(Paginator)
