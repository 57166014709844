import { useEffect } from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import StoreFinderBoxLayout from "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoreFinderBoxLayout";
import MethodIcon from "modules/stores/WhereToBuyPage/components/StoreFinder/components/MethodIcon";
import {isMARINE} from "services/instances";

export default function SelectSearchMethod({onClickProduct, onClickLocation, clearCurrentSearch}) {
  // Everytime this component renders, clear everything in the store so we make sure we're starting fresh
  useEffect(() => {
    clearCurrentSearch()
  })

  return (
      <StoreFinderBoxLayout>
        <p className="text-center">
          <strong>
            <FormattedMessage
                id="SelectSearchMethod.title"
                defaultMessage="¿Qué tipo de búsqueda quieres hacer?"/>
          </strong>
        </p>
        <div className="text-center d-flex d-md-block flex-column px-5">
          <button
              className={classNames({
                "btn btn-dark mr-md-3 mb-3 mb-md-0": true,
                "btn-prussian-blue": isMARINE()
              })}
              onClick={onClickLocation}>
            <MethodIcon icon="store"/>
            <br/>
            <FormattedMessage
                id="SelectSearchMethod.byLocationLabel"
                defaultMessage="Buscar por ubicación"/>
          </button>
          <button
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE()
              })}
              onClick={onClickProduct}>
            <MethodIcon icon="fabric"/>
            <br/>
            <FormattedMessage
                id="SelectSearchMethod.byProductLabel"
                defaultMessage="Buscar por producto"/>
          </button>
        </div>
      </StoreFinderBoxLayout>
  )
}

SelectSearchMethod.propTypes = {
  onClickProduct: PropTypes.func,
  onClickLocation: PropTypes.func,
  clearCurrentSearch: PropTypes.func
};