import validationMessages from "utils/forms/messages";
import * as Yup from "yup";
import { getSectionTitle } from "./getSectionTitle";

const OPTION_TYPE_DEFAULT = "";

const REQUIRED_INPUTS_INITIAL_VALUES = ({
  isHidedCompanyType = false,
  isHidedLocation = false,
}) => {
  if (!isHidedCompanyType && !isHidedLocation) {
    return {
      companyType: OPTION_TYPE_DEFAULT,
      countryId: OPTION_TYPE_DEFAULT,
      regionId: OPTION_TYPE_DEFAULT,
      cityId: OPTION_TYPE_DEFAULT,
    };
  }

  if (isHidedCompanyType) {
    return {
      countryId: OPTION_TYPE_DEFAULT,
      regionId: OPTION_TYPE_DEFAULT,
      cityId: OPTION_TYPE_DEFAULT,
    };
  }

  if (isHidedLocation) {
    return { companyType: OPTION_TYPE_DEFAULT };
  }
};

const REQUIRED_INPUTS_DEFAULT_SCHEMA = ({
  intl,
  isHidedCompanyType,
  isHidedLocation,
}) => ({
  companyType: Yup.string().when("companyTypeString", {
    is: () => !isHidedCompanyType,
    then: () =>
      Yup.string().required(intl.formatMessage(validationMessages.required)),
    otherwise: () => Yup.string(),
  }),
  countryId: Yup.string().when("countryIdString", {
    is: () => !isHidedLocation,
    then: () =>
      Yup.string().required(intl.formatMessage(validationMessages.required)),
    otherwise: () => Yup.string(),
  }),
  regionId: Yup.string().when("regionIdString", {
    is: () => !isHidedLocation,
    then: () =>
      Yup.string().required(intl.formatMessage(validationMessages.required)),
    otherwise: () => Yup.string(),
  }),
  cityId: Yup.string().when("cityIdString", {
    is: () => !isHidedLocation,
    then: () =>
      Yup.string().required(intl.formatMessage(validationMessages.required)),
    otherwise: () => Yup.string(),
  }),
});

const handleRequiredInputs = ({
  intl,
  userInfo,
  isHidedCompanyType = false,
  isHidedLocation = false,
}) => {
  if (userInfo?.me?.profile) return {};

  return {
    initialValues: REQUIRED_INPUTS_INITIAL_VALUES({
      isHidedCompanyType,
      isHidedLocation,
    }),
    defaultSchema: REQUIRED_INPUTS_DEFAULT_SCHEMA({
      intl,
      isHidedCompanyType,
      isHidedLocation,
    }),
  };
};

export const getValidationSchema = ({ intl, section, userInfo }) => {
  let defaultSchema;
  let initialValues;

  const { sectionTitle } = getSectionTitle(intl);

  if (section.title === sectionTitle.profileSectionTitle) {
    initialValues = {
      firstName: section.fields[0].value,
      lastName: section.fields[1].value,
      phone: section.fields[2].value,
      ...handleRequiredInputs({ intl, userInfo }).initialValues,
    };
    defaultSchema = Yup.object().shape({
      firstName: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      lastName: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      phone: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      ...handleRequiredInputs({ intl, userInfo }).defaultSchema,
    });
    return { defaultSchema, initialValues };
  } else if (section.title === sectionTitle.companySectionTitle) {
    initialValues = {
      companyName: section.fields[0].value,
      position: section.fields[1].value,
      companyType: {
        label: section.fields[2].value,
        value: section.fields[2].id,
      },
      ...handleRequiredInputs({ intl, userInfo, isHidedCompanyType: true })
        .initialValues,
    };
    defaultSchema = Yup.object().shape({
      companyName: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      position: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      companyType: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      ...handleRequiredInputs({ intl, userInfo, isHidedCompanyType: true })
        .defaultSchema,
    });
    return { defaultSchema, initialValues };
  } else if (section.title === sectionTitle.locationSectionTitle) {
    initialValues = {
      countryId: {
        label: section.fields[0].value,
        value: section.fields[0].id,
      },
      regionId: {
        label: section.fields[1].value,
        value: section.fields[1].id,
      },
      cityId: {
        label: section.fields[2].value,
        value: section.fields[2].id,
      },
      ...handleRequiredInputs({ intl, userInfo, isHidedLocation: true })
        .initialValues,
    };
    defaultSchema = Yup.object().shape({
      countryId: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      regionId: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      cityId: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      ...handleRequiredInputs({ intl, userInfo, isHidedLocation: true })
        .defaultSchema,
    });
    return { defaultSchema, initialValues };
  } else if (section.title === sectionTitle.addressBookSectionTitle) {
    initialValues = {
      zipCode: section.fields[0].value,
      primaryAddress: section.fields[1].value,
      ...handleRequiredInputs({ intl, userInfo }).initialValues,
    };
    defaultSchema = Yup.object().shape({
      zipCode: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      primaryAddress: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      ...handleRequiredInputs({ intl, userInfo }).defaultSchema,
    });

    return { defaultSchema, initialValues };
  }
};
