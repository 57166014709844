import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {getTranslatedLink} from "services/instances"

const TranslatedLink = ({
  name,
  params,
  children,
  ...rest
}) => (
  <Link
    to={getTranslatedLink(name, params)}
    {...rest}>
    {children}
  </Link>
)


TranslatedLink.propTypes = {
  name: PropTypes.string.isRequired,
  params: PropTypes.object,
  children: PropTypes.element
};

export default TranslatedLink
