import { FormattedMessage } from "react-intl";
import { isLangEs } from "services/instances";

export const TextSecction = () => {
  return (
    <div className="container-fluid">
      <div className="row mt-5 text-center d-flex justify-content-center">
        <div className="col-12 col-lg-9 col-md-9 col-sm-9">
          <span className="long-run-title">
            <FormattedMessage
              id="EcosensePage.v2.Banner.Info.title"
              defaultMessage="For the Long Run"
            />
          </span>
          <h1
            style={{
              borderBottom: "2px solid black",
              marginLeft: "44%",
              marginRight: "44%",
            }}
          ></h1>
        </div>
      </div>

      <div className="row d-flex justify-content-center my-4 ">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 px-lg-5">
          <p className="long-run-content px-lg-5">
            <FormattedMessage
              id="EcosensePage.v2.Banner.Info.description"
              defaultMessage="From climate action to social support initiatives, we have a higher
              purpose beyond our products. This commitment drives our green
              philosophy, EcoSense<sup>®</sup> which symbolizes the relentless dedication to
              minimizing ecological footprint through sustainable product
              innovation and responsible production processes."
              values={{
                sup: (...chunks) => <sup>{chunks}</sup>,
              }}
            />
          </p>
        </div>
      </div>

      {!isLangEs && (
        <div className="row mb-5 d-flex">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <p className="long-run-sub text-lg-center text-sm-start text-md-center">
              <FormattedMessage
                id="EcosensePage.v2.Banner.Info.description.goal"
                defaultMessage="Our goal isn't just durability, it's about reducing the impact and
              increasing our role in creating a more sustainable future."
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
