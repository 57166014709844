import { useContext } from "react";
import PropTypes from 'prop-types';
import "./styles/ReferencesCarousel.scss";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import ReferenceCard
  from "modules/products/ProductDetailsPage/components/ProductHeader/components/ReferencesCarousel/components/ReferenceCard";

export default function ReferencesCarousel({hideStockAndPantone}) {
  const pattern = useContext(PatternContext);

  return (
      <div className="references-carousel-container">
        <div className="references-carousel">
          <div className="references-carousel-content">
            {
              (pattern ? pattern.products.edges : new Array(6).fill())
                  .map((reference, index) =>
                      <div
                          key={index}
                          className="references-carousel-content__card mb-md-2">
                        <ReferenceCard
                            patternSlug={pattern ? pattern.slug : undefined}
                            reference={reference ? reference.node : undefined}
                            hideStockAndPantone={hideStockAndPantone}/>
                      </div>
                  )
            }
          </div>
        </div>
      </div>
  )
}

ReferencesCarousel.propTypes = {
  hideStockAndPantone: PropTypes.bool
};