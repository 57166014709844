import {connect} from "react-redux";
import SamplesList from "../index";

const mapStateToProps = (state) => {
  return {
    cartId: state.headerReducer.cartId
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const SamplesListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SamplesList);

export default SamplesListContainer;