import PropTypes from "prop-types";
import sliderArrow from "./img/slider-arrow.png";

const SliderImagesArrow = ({
  classNameContainer,
  classNameArrow
}) => {
  return (
    <div className="d-block">
      <div className={`d-flex justify-content-center rounded-circle ${classNameContainer}`}>
        <img className={classNameArrow} src={sliderArrow} alt="slider-arrow" width={10} height={10} />
      </div>
    </div>
  )
}

SliderImagesArrow.propTypes = {
  classNameContainer: PropTypes.string,
  classNameArrow: PropTypes.string
}

export default SliderImagesArrow;