/**
 * @param { section } { Object } = Is the current section
 * @param { sectionTitle } { Object } = The titles of the section to create the diferents schemas
 * @param { formSchema } { Object } = The values come from the form
 */

export const getInputSchemaToUpdateProfile = (
  section,
  sectionTitle,
  formSchema,
  userInfo
) => {
  const sectionSchemas = {
    [sectionTitle.profileSectionTitle]: {
      firstName: formSchema.firstName,
      lastName: formSchema.lastName,
      phone: formSchema.phone,
      ...(!userInfo?.me?.profile
        ? {
            companyType: formSchema?.companyType,
            cityId: formSchema?.cityId,
          }
        : {}),
    },
    [sectionTitle.companySectionTitle]: {
      company: formSchema.companyName,
      position: formSchema.position,
      companyType:
        formSchema?.companyType?.value !== undefined
          ? formSchema.companyType.value
          : formSchema.companyType,
      ...(!userInfo?.me?.profile ? { cityId: formSchema?.cityId } : {}),
    },
    [sectionTitle.locationSectionTitle]: {
      cityId: formSchema.cityId,
      ...(!userInfo?.me?.profile
        ? { companyType: formSchema?.companyType }
        : {}),
    },
    [sectionTitle.addressBookSectionTitle]: {
      zipCode: formSchema.zipCode,
      address: formSchema.primaryAddress,
      ...(!userInfo?.me?.profile
        ? {
            companyType: formSchema?.companyType,
            cityId: formSchema?.cityId,
          }
        : {}),
    },
  };

  return sectionSchemas[section.title] || null;
};
