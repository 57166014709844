import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import { FormattedMessage } from "react-intl";
import ContactBox from "modules/contact/ContactUsPage/components/ContactBox";
import CallUs from "modules/contact/ContactUsPage/components/CallUs";
import heroBg from "./img/hero-background.jpg";
import "./styles/ContactUsPage.scss";
import SEO from "services/seo/components/SEO";
import { CONTACT_SCENE } from "services/seo/constants/scenes";

const ContactUsPage = () => (
  <div className="contact-us-page">
    <ScrollTopOnMount />
    <div
      className="contact-page-hero"
      style={{
        backgroundImage: `url(${heroBg})`,
      }}
    />
    <div className="contact-us-page__contact-box">
      <div className="container mb-5">
        <ContactBox
          title={
            <FormattedMessage
              id="ContactUsPage.title"
              description="Contact us page contact box title"
              defaultMessage="Contacta a Spradling"
            />
          }
          message={
            <FormattedMessage
              id="ContactUsPage.contactUsPageContactBoxMessageV2"
              description="Contact us page contact box message"
              defaultMessage="¿Quieres que nos pongamos en contacto? Déjanos un mensaje con tus datos completos para saber cómo podemos trabajar juntos o conocer mejor qué necesitas."
            />
          }
        />
      </div>
    </div>
    <CallUs />
    <SEO scene={CONTACT_SCENE} />
  </div>
);

export default ContactUsPage;
