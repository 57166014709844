import gql from "graphql-tag";

const createSampleContactMutation = gql`
  mutation CreateSampleContact($input: CreateSampleContactInput!) {
    createSampleContact(input: $input) {
      sampleContact{
        id
      }
    }
  }
`;

export default createSampleContactMutation;