import {defineMessages} from "react-intl";

export const OUTDOOR_YES = 'YES';
export const OUTDOOR_YES_MESSAGE = defineMessages({
  [OUTDOOR_YES]: {
    id: "Outdoor.yes",
    description: "Outdoor value named yes",
    defaultMessage: "si"
  }
});

export const OUTDOOR_NO = 'NO';
export const OUTDOOR_NO_MESSAGE = defineMessages({
  [OUTDOOR_NO]: {
    id: "Outdoor.no",
    description: "Outdoor value named no",
    defaultMessage: "no"
  }
});

export const OUTDOORS = [
  {...OUTDOOR_YES_MESSAGE[OUTDOOR_YES], value: OUTDOOR_YES},
  {...OUTDOOR_NO_MESSAGE[OUTDOOR_NO], value: OUTDOOR_NO},
];