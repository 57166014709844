/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import { getGlossaryWordsBySearch } from '../queries';

const useSearchInGlossary = (selectedGlossary, handleSelectedGlossary, defaultGlossaryRelatedWords) => {
  const [wordToSearchInGlossary, setWordToSearchInGlossary] = useState("");
  const { loading: loadingSearchInGlossary, refetch } = useQuery(getGlossaryWordsBySearch);
  
  const handleChangeWordToSearchInGlossary = (e) => {
    const { value } = e.target
    
    setWordToSearchInGlossary(value);

    if (!selectedGlossary.isRelatedWords) {
      if (value) debouncedFetchSearch(wordToSearchInGlossary, selectedGlossary);
      else debouncedFetchSearch("", selectedGlossary);
    } else {
      if (value) {
        const searchWordInRelatedGlossary = selectedGlossary?.glossaryWords?.filter(word => 
            word?.node?.name.toLowerCase().includes(value) || word?.node?.definition.toLowerCase().includes(value)) 
  
        handleSelectedGlossary({
          currentGlossary: "",
          glossaryType: "",
          isFirstRender: false,
          glossaryWords: searchWordInRelatedGlossary,
          isRelatedWords: true
        })
      } else {
        handleSelectedGlossary({
          currentGlossary: "",
          glossaryType: "",
          isFirstRender: false,
          glossaryWords: defaultGlossaryRelatedWords.current,
          isRelatedWords: true
        })
      }
    }
  }
  
  const fetchSearchResults = async (wordToSearch, glossary) => {
    const { glossaryType: type } = glossary;

    try {
      const { data } = await refetch({
        keyword: wordToSearch ? wordToSearch : null,
        type: type
      })

      if (data) {
        const foundWords = data.glossarySearch.edges;

        handleSelectedGlossary({
          glossaryType: type,
          glossaryWords: foundWords,
          isRelatedWords: false
        })
      }
      
    } catch (error) {
      throw new Error(`There was an error fetching the search results: ${error}`);
    }
  };

  /**
   * Fetches search results with a debounce delay.
   * 
   * @param {Function} fetchSearchResults - The function to fetch search results.
   * @param {number} delay - The delay in milliseconds for the debounce.
   * @returns {Function} - The debounced function.
   */
  const debouncedFetchSearch = useCallback(_.debounce((wordToSearch, currentGlossary) => fetchSearchResults(wordToSearch, currentGlossary), 500), []);

  return { 
    wordToSearchInGlossary, 
    handleChangeWordToSearchInGlossary,
    fetchSearchResults,
    loadingSearchInGlossary
  };
};

export default useSearchInGlossary;