import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrentLanguageAndInstanceCode } from "services/instances";
import useToggle from "utils/hooks/useToggable";
import { PATH_BY_COUNTRY_CODE } from "../consts";

const useGoToIpLocation = ({ onlyInstance }) => {
  const [locationInstance, setLocationInstance] = useState("");
  const [currentLanguage, currentInstance] = getCurrentLanguageAndInstanceCode();
  const [showMessageLocation, toggleMessageLocation] = useToggle();

  useEffect(() => {
    fetch(
      `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          !PATH_BY_COUNTRY_CODE(data.country_code, onlyInstance).includes(
            currentInstance
          )
        ) {
          toggleMessageLocation();
          const path = PATH_BY_COUNTRY_CODE(data.country_code);
          setLocationInstance(path);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentInstance,
    currentLanguage,
    locationInstance,
    showMessageLocation,
    toggleMessageLocation,
  };
};

useGoToIpLocation.propTypes = {
  onlyInstance: PropTypes.bool,
};

export default useGoToIpLocation;
