import { useEffect } from "react";
import PropTypes from "prop-types";

const useHubSpotTracking = ({ hubId, userId, disableAllTracking = false }) => {
  useEffect(() => {
    if (hubId) {
      // Cargar el script de seguimiento de HubSpot
      const hubspotScript = document.createElement("script");
      hubspotScript.src = `https://js.hs-scripts.com/${hubId}.js`;
      hubspotScript.async = true;
      document.head.appendChild(hubspotScript);

      // Configurar la identificación del usuario en HubSpot si está disponible
      // if (typeof window !== 'undefined' && window && window.hubspot && userId) {
      //   window.hubspot('identify', userId);
      // }

      // Desactivar la recolección de información de seguimiento automático en HubSpot
      window.hubspot &&
        disableAllTracking &&
        window.hubspot("disableAllTracking", true);

      return () => {
        // Eliminar el script de seguimiento de HubSpot al desmontar el componente
        document.head.removeChild(hubspotScript);
      };
    }
  }, [hubId, userId, disableAllTracking]);
};

useHubSpotTracking.propTypes = {
  hubId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  disableAllTracking: PropTypes.bool,
};

export default useHubSpotTracking;
