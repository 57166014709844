import PropTypes from "prop-types";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {FormattedMessage} from "react-intl";
import ForgotPasswordForm from "modules/auth/scenes/ForgotPassword/components/ForgotPasswordForm";
import {Redirect} from "react-router-dom";

export default function ForgotPassword({isAuthenticated}) {
  if (isAuthenticated)
    return <Redirect to="/"/>;

  return (
      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <TitleUnderlined>
              <FormattedMessage
                  id="ForgotPassword.title"
                  defaultMessage="Recupera tu contraseña"/>
            </TitleUnderlined>
            <p>
              <FormattedMessage
                  id="ForgotPassword.subtitle"
                  defaultMessage="Ingresa tu dirección de correo y recibe un enlace para reestablecer la contraseña."/>
            </p>
            <ForgotPasswordForm/>
          </div>
        </div>
      </div>
  );
}

ForgotPassword.propTypes = {
  isAuthenticated: PropTypes.bool
};