import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { collectionsQueryByID } from "../queries";

const useGetCollectionByID = (id) => {
  const { data, loading, error } = useQuery(collectionsQueryByID, {
    variables: { id: id || '' },
  });

  const [collectionItems, setCollectionItems] = useState([]);

  useEffect(() => {
    if (data) {
      setCollectionItems(data.quickshipCollection || []);
    }
  }, [data]);

  return { collectionItems, loading, error };
};

export default useGetCollectionByID;
