import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import classNames from "classnames/bind";
import cartQuery from "components/Header/components/HeaderCartButton/query";
import checkOutCartMutation from "modules/cart/scenes/SamplesCart/components/RequestSamplesBtn/mutation";
import RequestSamplesForAClientBtnContainer from "modules/cart/scenes/SamplesCart/components/RequestSamplesForAClientBtn";
import { useHistory } from "react-router";
import { SAMPLES_CART_CHECKED_OUT } from "routes";
import { AUTH_MODAL_SIGN_UP_CONTEXT } from "components/AuthModal/constants";
import {
  getCurrentInstanceId,
  isUSA,
  isMARINE,
  getTranslatedLink,
  isRESOURCES,
} from "services/instances";

export default function RequestSamplesBtn({
  cartId,
  isAuthenticated,
  toggleAuthModal,
  setAuthModalContext,
}) {
  const history = useHistory();
  const showRequestSamplesForAClient = isUSA() || isRESOURCES();
  const [notes, setNotes] = useState("");
  const [fetchCart, { loading: queryLoading, error: queryError, data }] =
    useLazyQuery(cartQuery, {
      variables: {
        id: cartId,
        instanceId: getCurrentInstanceId(),
      },
    });
  const [checkOut, { loading: mutationLoading }] = useMutation(
    checkOutCartMutation,
    {
      onCompleted() {
        history.push(getTranslatedLink(SAMPLES_CART_CHECKED_OUT));
      },
    }
  );
  const memoizedFetchCart = useCallback(fetchCart, [fetchCart]);

  /**
   * Fetch the cart only if theres a cartId
   */
  useEffect(() => {
    if (cartId) memoizedFetchCart();
  }, [cartId, memoizedFetchCart]);

  /**
   * Handles click on the button to open the sign up modal
   * @param event {Object}
   */
  function handleBtnClick(isForAClient = false, values) {
    if (isAuthenticated) {
      const mutationInputBase = {
        instanceId: getCurrentInstanceId(),
        cartId,
        notes,
      };
      // for process request sample for a client
      if (isForAClient) {
        // eslint-disable-next-line no-unused-vars
        const { countryId, regionId, privacyPolicy, ...clientData } = values;
        const mutationInput = { ...mutationInputBase, ...clientData };
        consumeCheckOut(mutationInput);
      } else {
        consumeCheckOut(mutationInputBase);
      }
    } else {
      toggleAuthModal();
      setAuthModalContext(AUTH_MODAL_SIGN_UP_CONTEXT);
    }
  }

  function consumeCheckOut(input) {
    checkOut({
      variables: {
        input,
      },
      refetchQueries: ["Cart"],
    });
  }

  if (!cartId || queryLoading || queryError) return null;

  if (data && data.cart && data.cart.items.edges.length === 0) return null;

  return (
    <>
      {isAuthenticated && (
        <div className="form-group pt-4">
          <FormattedMessage
            id="RequestSamplesBtn.notesPlaceholder"
            defaultMessage="Notas sobre tu pedido, por ejemplo: notas especiales para la entrega"
          >
            {(placeholder) => (
              <textarea
                name="notes"
                id="notes"
                className="form-control"
                rows="3"
                placeholder={placeholder}
                onChange={(e) => setNotes(e.target.value)}
              />
            )}
          </FormattedMessage>
        </div>
      )}
      <div className="d-flex flex-row justify-content-center request-samples-btn">
        {isAuthenticated && showRequestSamplesForAClient && (
          <RequestSamplesForAClientBtnContainer
            mutationLoading={mutationLoading}
            handleSubmit={handleBtnClick}
          />
        )}
        <div className="text-center pt-4 mx-3">
          <button
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE(),
            })}
            disabled={mutationLoading}
            onClick={() => handleBtnClick()}
          >
            {mutationLoading ? (
              <FormattedMessage
                id="SamplesCart.btnLoadingLabel"
                defaultMessage="Solicitando..."
              />
            ) : (
              <FormattedMessage
                id="SamplesCart.btnLabel"
                defaultMessage="Solicitar muestras"
              />
            )}
          </button>
        </div>
      </div>
    </>
  );
}

RequestSamplesBtn.propTypes = {
  cartId: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  toggleAuthModal: PropTypes.func,
  setAuthModalContext: PropTypes.func,
};
