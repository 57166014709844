export const INSTANCE_TRACKING_CODE_HUBSPOT = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "20640471",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "21624446",
  [process.env.REACT_APP_USA_INSTANCE_ID]: "21624483",
  [process.env.REACT_APP_SM_INSTANCE_ID]: "",
  [process.env.REACT_APP_RC_INSTANCE_ID]: "",
  [process.env.REACT_APP_EU_INSTANCE_ID]: "",
};

export const GOOGLE_TAG_MANAGER_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: { gtmId: "GTM-KPRZKNN" },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    gtmId: "GTM-WNPDR65T",
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]: {
    gtmId: "GTM-NRFS27X8",
  },
  [process.env.REACT_APP_SM_INSTANCE_ID]: {
    gtmId: "GTM-5XZ87WXW",
  },
  [process.env.REACT_APP_RC_INSTANCE_ID]: {
    gtmId: "GTM-58TQVQDC",
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    gtmId: "GTM-MGBP48",
  },
};
