import { FormattedMessage } from "react-intl";
import DisclaimerText from "utils/components/DisclaimerText";
import Tooltip from "utils/components/Tooltip";

export const handleProductAltName = (altName) => {
  if (altName.includes("®") || altName.includes("™")) {
    const splitedName = altName.split(" ");
    if (splitedName.length > 1) {
      const indexNameToSup = splitedName.findIndex(
        (name) => name.includes("®") || name.includes("™")
      );
      splitedName[indexNameToSup] = handleAddSupToNameProduct(
        splitedName[indexNameToSup]
      );

      const nameOnString = splitedName.toString();
      return nameOnString.replaceAll(",", " ");
    }
    return handleAddSupToNameProduct(altName);
  }
  return altName;
};

const handleAddSupToNameProduct = (nameProduct) => {
  const name = nameProduct.slice(0, -1);
  const sup = nameProduct.charAt(nameProduct.length - 1);
  return `${name}<sup>${sup}</sup>`;
};

export const handlePantoneCode = ({ pantoneCode, classForBox }) => {
  if (pantoneCode && pantoneCode.includes("*")) {
    return (
      <div className="d-flex position-absolute">
        <small>{pantoneCode}</small>
        <Tooltip
          content={
            <FormattedMessage
              id="ReferenceCard.pantoneDisclaimer"
              values={{ br: <br /> }}>
              {(disclaimer) => <DisclaimerText text={disclaimer} />}
            </FormattedMessage>
          }
          classForBox={classForBox}
          direction="right"
        >
          <i className="fa fa-info-circle ml-2 cursor-pointer" />
        </Tooltip>
      </div>
    );
  }
  return <p className="mb-0">{pantoneCode}</p>;
};
