import PropTypes from "prop-types";

export default function DisclaimerText({text}) {
  return (
      <div className="disclaimer-text">
        <p className="mb-0 gray-light">
          <small>
            {text}
          </small>
        </p>
      </div>
  );
}

DisclaimerText.propTypes = {
  text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]).isRequired
};