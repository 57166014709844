import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/UserInformationSection.scss";

export const UserInformationSection = ({ title, fields, onEditClick }) => {
  return (
    <>
      <div className="h-100 col-12 col-md-6 col-lg-3">
        <h4>{title}</h4>
        <div className="mt-5">
          {fields.map((field, index) => (
            <h5 key={index} className="truncate">
              {`${field?.name}: ${!field?.value ? "" : field.value}`}
            </h5>
          ))}
        </div>
        <Button
          className="mb-5 mb-md-5 mb-lg-0 col-4 col-md-4 col-lg-5 py-1 mt-3 border border-dark"
          color="Light"
          onClick={onEditClick}
        >
          <FormattedMessage id="RecordsPage.edit" />
        </Button>
      </div>
    </>
  );
};

UserInformationSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  onEditClick: PropTypes.func,
};
