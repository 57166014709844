import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { graphql } from "react-apollo";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { HOME_PAGE } from "routes";
import TranslatedLink from "routes/components/TranslatedLink";
import HTMLContent from "utils/components/HTMLContent";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import HeroTitle from "utils/components/HeroTitle";
import SubHeroContent from "utils/components/SubHeroContent";
import singleServiceQuery from "./query";
import { getTranslatedLink } from "services/instances";
import ContactUsService from "./components/ContactUsService";

const SingleServicePage = ({
  singleServiceQuery: { loading, error, service },
}) => {
  if (error) return <Redirect to={getTranslatedLink(HOME_PAGE, {})} />;

  return (
    <div className="single-brand-page">
      <ScrollTopOnMount />
      <HeroTitle
        title={!loading ? service.name : <Skeleton width={200} />}
        backgroundImage={!loading ? service.imageThumbLarge : ""}
      />
      <SubHeroContent theme="white">
        <div className="container mb-5">
          <div className="row pt-4 pb-3 pt-md-5 pb-md-4">
            <div className="col-auto">
              <ul className="nav justify-content-right">
                <li className="nav-item">
                  <TranslatedLink
                    name={HOME_PAGE}
                    className="nav-link pl-0 pr-0 colored-link gray"
                  >
                    <>
                      <small>
                        <i className="sp-strong-chevron-left" />
                      </small>
                      &nbsp;&nbsp;&nbsp;
                      <FormattedMessage
                        id="Services.Service.seeMoreServices"
                        description="See more services link text in single service page"
                        defaultMessage="Ver más servicios"
                      />
                    </>
                  </TranslatedLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!loading ? (
                <HTMLContent html={service.content} />
              ) : (
                <Skeleton count={5} />
              )}
            </div>
            <div className="col-12">
              <ContactUsService service={service} />
            </div>
          </div>
        </div>
      </SubHeroContent>
    </div>
  );
};

SingleServicePage.propTypes = {
  singleServiceQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    service: PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      imageThumbLarge: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
};

// TODO: use graphql hooks
export default graphql(singleServiceQuery, {
  name: "singleServiceQuery",
  options: (props) => {
    return {
      variables: {
        slug: props.match.params.serviceSlug,
      },
    };
  },
})(SingleServicePage);
