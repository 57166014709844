import { useState } from 'react';

const useToggleAccordionGroup = (groups) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [higherPurposesGroups, setHigherPurposesGroups] = useState(groups);

  // Function to toggle the open state of a group
  const toggleGroupOpenState = (groups, targetTitle, openState) => {
    return groups.map(group =>
      group.groupTitle === targetTitle ? { ...group, isOpen: openState } : group
    );
  }

  // Function to close all other groups except the specified one
  const closeOtherGroup = (groups, excludedTitle) => {
    return groups.map(group =>
      group.groupTitle !== excludedTitle ? { ...group, isOpen: false } : group
    );
  }

  // Function to toggle the open state of a sub-group item
  const toggleSubGroupItemOpenState = (groups, groupTitle, itemTitle) => {
    return groups.map(group =>
      group.groupTitle === groupTitle ? {
        ...group,
        groupItems: group.groupItems.map(item =>
          item.title === itemTitle ? { ...item, isOpen: !item.isOpen } : { ...item, isOpen: false }
        )
      } : { ...group, isOpen: false }
    );
  }

  const handleClosePurposesGroup = (groupTitle, itemTitle, isSubGroup = false) => {
    setHigherPurposesGroups(prevState => {
      if (isSubGroup) {
        setIsFirstRender(false);
        return toggleSubGroupItemOpenState(prevState, groupTitle, itemTitle);
      }

      if (!isSubGroup) {
        if (!isFirstRender) {
          const updatedGroups = toggleGroupOpenState(prevState, groupTitle, !prevState.find(group => group.groupTitle === groupTitle).isOpen);

          return closeOtherGroup(updatedGroups, groupTitle);
        } 

        setIsFirstRender(false);
        return toggleGroupOpenState(prevState, groupTitle, !prevState.find(group => group.groupTitle === groupTitle).isOpen);
      }
      
      // Add a default return statement for the case when isSubGroup is false and isFirstRender is true
      return prevState;
    });
  };

  return { 
    higherPurposesGroups, 
    handleClosePurposesGroup 
  };
};

export default useToggleAccordionGroup;