import gql from "graphql-tag";

export const createUserMutation = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        fullName
      }
    }
  }
`;

export const createProfileMutation = gql`
  mutation CreateProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      profile {
        company
        companyType {
          id
          name
        }
      }
    }
  }
`;
