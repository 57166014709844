import "./styles/DocumentLibraryPage.scss";
import { FormattedMessage } from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { Query } from "react-apollo";
import documentCategoriesQuery from "modules/documents/DocumentLibraryPage/query";
import { H1_TAG } from "utils/components/TitleUnderlined/constants";
import LoadingMessage from "utils/components/LoadingMessage";
import { DOCUMENT_LIST } from "routes";
import { Link } from "react-router-dom";
import { getCurrentInstanceId, getTranslatedLink, isMARINE } from "services/instances";
import SEO from "services/seo/components/SEO";
import { DOCUMENT_LIBRARY_SCENE } from "services/seo/constants/scenes";
import { availablesAndSortedDocuments } from "./constants/availablesAndSortedDocuments";

const DocumentLibraryPage = () => (
  <div className="document-list-page">
    <div className="container text-center pt-4 pb-4 pt-lg-5 pb-lg-5">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <TitleUnderlined
            hTag={H1_TAG}
            className="text-size-h1 text-weight-bold"
          >
            <FormattedMessage
              id="DocumentLibraryPage.title"
              description="Title in Document Library"
              defaultMessage="Información de productos"
            />
          </TitleUnderlined>
          <p>
            <FormattedMessage
              id="DocumentLibraryPage.description"
              description="Description in Document Library"
              defaultMessage="Hemos recopilado información importante para que consultes sobre nuestros productos, su mantenimiento y cuidado, nuestros procesos, entre otros."
            />
          </p>
        </div>
      </div>
      <Query
        query={documentCategoriesQuery}
        variables={{
          instances: [getCurrentInstanceId()],
        }}
      >
        {({ data }) => {
          if (data) {
            const { documentCategories } = data;
            const documents = availablesAndSortedDocuments(documentCategories.edges, isMARINE());

            return (
              <div className="document-list row pl-3 pr-3 pt-5 pb-5">
                {documents.map((category, index) => {
                  return (
                    <div key={index} className="col-12 col-md-4 col-lg-3 mb-4">
                      <div className="document-category text-left">
                        <div className="document-category__photo">
                          <Link
                            to={getTranslatedLink(DOCUMENT_LIST, {
                              categorySlug: category.node.slug,
                            })}
                          >
                            <img
                              src={category.node.listingImageThumbSmall}
                              alt={category.node.name}
                              title={category.node.name}
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="document-category__body">
                          <h3 className="document-category__title">
                            {category.node.name}
                          </h3>
                          <p className="document-category__description">
                            {category.node.description}
                          </p>
                          <Link
                            className="document-category__show-more-link"
                            to={getTranslatedLink(DOCUMENT_LIST, {
                              categorySlug: category.node.slug,
                            })}
                          >
                            <FormattedMessage
                              id="DocumentLibraryPage.documentShowMoreLink"
                              description="'Show more' link in document category card"
                              defaultMessage="Ver mas"
                            />
                            <i className="fa fa-chevron-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }

          return <LoadingMessage />;
        }}
      </Query>
    </div>
    <SEO scene={DOCUMENT_LIBRARY_SCENE} />
  </div>
);

DocumentLibraryPage.propTypes = {};

export default DocumentLibraryPage;
