import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconCatalogue from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/IconCatalogue";
import "./styles/OrderListCard.scss";

const OrderListCard = ({ orderInformation }) => {
  const {
    articlesNumber,
    resultOrderId,
    itHasCatalogs,
    patterns,
    referenceName,
    referenceImage,
    updatedDate,
    handleClickOnShowDetailsOfTheOrder,
  } = orderInformation;

  return (
    <div className="col-12 col-md-6 col-lg-3 order-item p-0">
      <div className="col-11 p-0">
        <h5>Ticket: {resultOrderId}</h5>
        <div
          className="col-12 border border-2 image-order-card"
          style={{ backgroundImage: `url(${referenceImage})` }}
        >
          {itHasCatalogs && (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div>
                <IconCatalogue />
              </div>
            </div>
          )}
        </div>
        <h6 className="mt-3 mb-0">
          {referenceName ? referenceName : "---------"}
        </h6>
        <p className="mt-0 mb-0">{updatedDate}</p>
        <p className="mt-0">
          {articlesNumber + " "} <FormattedMessage id="RecordsPage.articles" />
        </p>
        <Button
          color="dark"
          className="py-1 px-4 mt-3"
          onClick={() =>
            handleClickOnShowDetailsOfTheOrder(
              patterns,
              updatedDate,
              resultOrderId
            )
          }
        >
          <FormattedMessage id="RecordsPage.seeDetails" />
        </Button>
      </div>
    </div>
  );
};

OrderListCard.propTypes = {
  orderInformation: PropTypes.object,
};

export default OrderListCard;
