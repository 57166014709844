import PropTypes from "prop-types";
import useToggle from "utils/hooks/useToggable";
import "./styles/tooltip.scss";

const Tooltip = ({
  children,
  delay = 400,
  direction = "top",
  content,
  classForBox,
}) => {
  let timeout;
  const [active, toggleActive] = useToggle();
  // const active = true;

  const showTip = () => {
    timeout = setTimeout(() => {
      toggleActive();
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    toggleActive();
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`Tooltip-Tip ${direction} ${classForBox}`}>
          {content}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  classForBox: PropTypes.string,
  delay: PropTypes.number,
  direction: PropTypes.string,
  content: PropTypes.string,
};

export default Tooltip;
