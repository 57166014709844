import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import useGetTestimonials from "./hooks/useGetTestimonials";
import "./styles/TransformLifesSection.scss";
import TestimonialsSlider from "./components/TestimonialsSlider";

const TransformLifesSection = ( slug ) => {
  const currentSlug = slug?.slug
  const { testimonialItems } = useGetTestimonials(`proquinal-${currentSlug ? currentSlug : ""}`);

  return (
    <div className='container mt-5 pt-5 mb-5'>
      <h2 className='text-center'>
        <FormattedMessage 
          id="CompanyPlants.transformLifesSection.title"
          defaultMessage="Transforming Lives:"
        />
      </h2>
      <h5 className='text-center mt-4'>
        <FormattedMessage 
          id="CompanyPlants.transformLifesSection.description"
          defaultMessage="Inspired Partners, Empowered Communities"
        />
      </h5>

      <TestimonialsSlider testimonialsItems={testimonialItems} />

      <p className='mt-5 text-center'>
        <FormattedMessage 
          id="CompanyPlants.transformLifesSection.descriptionFooter"
          defaultMessage="We don't just transform textiles; we transform lives. From educational programs to housing relief to donations to vulnerable communities, our commitment extends beyond responsible production."
        />
      </p>
    </div>
  );
};

export default TransformLifesSection;