import {connect} from "react-redux";
import LocationsListHeader
  from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/components/LocationsListHeader/index";

const mapStateToProps = (state) => {
  return {
    locationReducer: state.locationReducer
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const LocationsListHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsListHeader);

export default LocationsListHeaderContainer;
