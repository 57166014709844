// Filters
export const QUERY_PARAM_DESIGN = "d";
export const QUERY_PARAM_COMPOSITION = "c";
export const QUERY_PARAM_PREDOMINANT_COLOR = "pc";
export const QUERY_PARAM_OUTDOOR = "od";
export const QUERY_PARAM_TOPCOAT = "tc";
export const QUERY_PARAM_BACKING_DESCRIPTION = "bd";
export const QUERY_PARAM_SEARCH = "q";
export const QUERY_PARAM_NO_BIOCIDES = "nb";
export const QUERY_PARAM_FORMALDEHYDE_FREE = "ff";
export const QUERY_PARAM_NO_FLAME_RETARDANTS = "nfr";
export const QUERY_PARAM_ANTIFUNGAL = "af";
export const QUERY_PARAM_PROP_65_COMPLIANT = "p65";
export const QUERY_PARAM_POLYCARBONATE_RESIN_SYSTEM = "prs";
export const QUERY_PARAM_ECOSENSE = "eco";
export const QUERY_PARAM_SOFTSIDE = "ss";
export const QUERY_PARAM_PROSTOCK = "pros";
export const QUERY_PARAM_COMPASS_HB = "chb";
export const QUERY_PARAM_INTAGLIODESIGNS = "neo";
export const QUERY_PARAM_IPA_CLEANABLE = "ic";
export const QUERY_PARAM_BLEACH_CLEANABLE = "bc";
export const QUERY_PARAM_AB_1998_COMPLIANT = "ab1998";
export const QUERY_PARAM_DENIM_DYE_RESISTANT = "ddr";
export const QUERY_PARAM_ANTISTATIC = "as";
export const QUERY_PARAM_HEAT_SEALABLE = "hs";
export const QUERY_PARAM_SULFIDE_STAIN_RESISTANT = "ssr";
export const QUERY_PARAM_HYDROLYSIS = "hd";
export const QUERY_PARAM_MILDEW_RESISTANT = "mwr";
export const QUERY_PARAM_ANTIBACTERIAL = "abt";
export const QUERY_PARAM_HAS_NOT_HEAVY_METALS = "nhm";
export const QUERY_PARAM_PET_FRIENDLY = "pf";
export const QUERY_PARAM_SUSTAINAILITY_SEARCH = 'sustainability-search';

