import gql from "graphql-tag";

export const testimonialsQuery = gql`
query testimonials ($domain: String!, $slug: String){
  carousels(domain: $domain, slug: $slug){
    edges {
      node {
        id
        imageUrl
        htmlTag
        title
        description
        descriptionEn
      }
    }
  }
}
`