import PropTypes from 'prop-types';
import { useState } from 'react';
import TitleUnderlined from 'utils/components/TitleUnderlined';
import { H1_TAG } from 'utils/components/TitleUnderlined/constants';
import { Button } from 'reactstrap';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import classNames from 'classnames';
import PurposesAccordionsSection from './components/PurposeAccordionsSection';
import "./styles/HigherPurpose.scss";
import { FormattedMessage } from 'react-intl';
import {Link} from "react-router-dom";


const HigherPurpose = ({ purposesItems }) => {
  const isMobile = useMediaQuery("(max-width: 995px)");
  const initialMasterImage = purposesItems[0].groupItems[0].masterImg;
  const [masterImage, setMasterImage] = useState(initialMasterImage);

  const handleClickToChangeMasterImage = (img) => setMasterImage(img);

  return (
    <div
      className='higher-purpose--container border border-dark mb-5 pb-5'>
      <TitleUnderlined className="mt-5 pt-5" hTag={H1_TAG} color="white">
        <FormattedMessage 
          id='CompanyPlants.higherPurpose.title'
          defaultMessage='Higher Purpose'
        />
      </TitleUnderlined>
      <h5 className='text-center mt-4 font-weight-normal'>
        <FormattedMessage 
          id='CompanyPlants.higherPurpose.description.weHaveAHigherPurpose'
          defaultMessage='We have a <italic>upper purpose</italic> that drives us'
          values={{
            italic: (...chunks) => <i className='font-weight-bold'>{chunks}</i>
          }}
        />
      </h5>
      <section className='container mt-4'>
        <p>
          <FormattedMessage 
            id='CompanyPlants.higherPurpose.text.weWorkDay'
            defaultMessage='We work every day motivated by a commitment to the future, to have a positive impact on the environment and to improve the lives of the people and communities around us.'
          />
        </p>
        <div className='higher-purpose--body-section d-flex mt-0'>
          <div className={classNames({
            'higher-purpose--container-accordions text-white pt-5': true,
            'col-6 pl-0 pr-5': !isMobile,
            'col-12': isMobile,
            })}>
            <PurposesAccordionsSection 
              purposesItems={purposesItems}
              handleClickToChangeMasterImage={handleClickToChangeMasterImage}
            />
          </div>
          <div className={classNames({
            'd-flex align-items-center': !isMobile,
            'col-6 p-0': true,
            'd-none': isMobile
            })}>
            <section className='h-75 w-100 bg-light p-0'>
              <img src={masterImage} alt='master-image' className='w-100 h-100' />
            </section>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
        <Link to="/es-la/acerca-de/ecosense" className="text-center mb-0 mt-5 mt-lg-0"> 
          <Button className="mx-auto px-5 py-2 mb-4" color="light">
            <FormattedMessage 
              id='CompanyPlants.higherPurpose.btnText.learnMore'
              defaultMessage='Learn More About Our Social Sustainability Initiatives'
            />
          </Button>
        </Link>
        </div>
      </section>
    </div>
  );
};

HigherPurpose.propTypes = {
   purposesItems: PropTypes.string.isRequired,
};

export default HigherPurpose;