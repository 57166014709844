import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getTranslatedLink } from "services/instances";
import {
  PRODUCT_DETAILS,
  BLOG_DETAILS,
  PROJECT_DETAILS,
  BRAND_DETAILS,
} from "routes";
import { QUERY_PARAM_REFERENCE } from "modules/products/ProductDetailsPage/constants";

const RESULT_COMPONENTS = {
  pattern: ({ result: { slug, alt_name } }) => (
    <div
      key={`key_${slug}_${alt_name}`}
      className="search-result__result search-result__result--pattern"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypePattern"
          description="Result type name 'Pattern'"
          defaultMessage="Referencia"
        />
      </div>
      <Link
        to={getTranslatedLink(PRODUCT_DETAILS, {
          productSlug: slug,
        })}
      >
        <div className="search-result__title">{alt_name}</div>
      </Link>
    </div>
  ),
  product: ({ result: { slug, color, pattern__slug, pattern__alt_name } }) => (
    <div
      key={`key_${slug}_${pattern__slug}_${color}`}
      className="search-result__result search-result__result--pattern"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypeProduct"
          description="Result type name 'Product'"
          defaultMessage="Producto"
        />
      </div>
      <div className="search-result__summary">{pattern__alt_name}</div>
      <Link
        to={{
          pathname: getTranslatedLink(PRODUCT_DETAILS, {
            productSlug: pattern__slug,
          }),
          search: `?${QUERY_PARAM_REFERENCE}=${color}`,
        }}
      >
        <div className="search-result__title">{color}</div>
      </Link>
    </div>
  ),
  document: ({ result: { category_name, name, url } }) => (
    <div
      key={`key_${category_name}_${name}`}
      className="search-result__result search-result__result--post"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypeDocument"
          description="Result type name 'Document'"
          defaultMessage="Documentos"
        />
      </div>
      <div className="search-result__summary">{category_name}</div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="search-result__title">{name}</div>
      </a>
    </div>
  ),
  post: ({ result: { slug, title, summary } }) => (
    <div
      key={`key_${slug}_${title}`}
      className="search-result__result search-result__result--post"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypePost"
          description="Result type name 'Post'"
          defaultMessage="Noticia"
        />
      </div>
      <Link
        to={getTranslatedLink(BLOG_DETAILS, {
          postSlug: slug,
        })}
      >
        <div className="search-result__title">{title}</div>
      </Link>
      <div className="search-result__summary">{summary}</div>
    </div>
  ),
  project: ({ result: { slug, name, client, description } }) => (
    <div
      key={`key_${slug}_${name}`}
      className="search-result__result search-result__result--project"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypeProject"
          description="Result type name 'Project'"
          defaultMessage="Caso de estudio"
        />
      </div>
      <Link
        to={getTranslatedLink(PROJECT_DETAILS, {
          projectSlug: slug,
        })}
      >
        <div className="search-result__title">{name}</div>
      </Link>
      <div className="search-result__info">
        <FormattedMessage
          id="SearchResultsPage.clientInfo"
          description="Copy of the word 'client' in Client info of a Project"
          defaultMessage="Cliente"
        />
        : {client}
      </div>
      <div className="search-result__summary">{description}</div>
    </div>
  ),
  brand: ({ result: { slug, title, description } }) => (
    <div
      key={`key_${slug}_${title}`}
      className="search-result__result search-result__result--post"
    >
      <div className="search-result__type">
        <FormattedMessage
          id="SearchResultsPage.resultTypeBrand"
          description="Result type name 'Brand'"
          defaultMessage="Marca"
        />
      </div>
      <Link
        to={getTranslatedLink(BRAND_DETAILS, {
          brandSlug: slug,
        })}
      >
        <div className="search-result__title">{title}</div>
      </Link>
      <div className="search-result__summary">{description}</div>
    </div>
  ),
};

export default RESULT_COMPONENTS;
