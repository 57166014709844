import * as types from "../actions/types";
import {AUTH_MODAL_SIGN_IN_CONTEXT} from "components/AuthModal/constants";
import {getCartId} from "services/auth/localStorage"

export const initialState = {
  mobileMenuIsOpen: false,
  authMenuIsOpen: false,
  authModalIsOpen: false,
  authModalContext: AUTH_MODAL_SIGN_IN_CONTEXT,
  searchBarIsOpen: false,
  cartId: getCartId()  // null if empty, string if found
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MOBILE_MENU_OPEN:
      return {
        ...state,
        mobileMenuIsOpen: true
      };
    case types.MOBILE_MENU_CLOSE:
      return {
        ...state,
        mobileMenuIsOpen: false
      };
    case types.AUTH_MENU_OPEN:
      return {
        ...state,
        authMenuIsOpen: true
      };
    case types.AUTH_MENU_CLOSE:
      return {
        ...state,
        authMenuIsOpen: false
      };
    case types.SEARCH_BAR_OPEN:
      return {
        ...state,
        searchBarIsOpen: true
      };
    case types.SEARCH_BAR_CLOSE:
      return {
        ...state,
        searchBarIsOpen: false
      };
    case types.AUTH_MODAL_TOGGLE:
      return {
        ...state,
        authModalIsOpen: !state.authModalIsOpen
      };
    case types.AUTH_MODAL_OPEN:
      return {
        ...state,
        authModalIsOpen: true
      };
    case types.AUTH_MODAL_CLOSE:
      return {
        ...state,
        authModalIsOpen: false
      };
    case types.AUTH_MODAL_CONTEXT_SET:
      return {
        ...state,
        authModalContext: action.context
      };
    case types.CART_ID_SET:
      return {
        ...state,
        cartId: action.cartId
      };
    case types.CART_ID_CLEAR:
      return {
        ...state,
        cartId: null
      };
    default:
      return state;
  }
};
