import classNames from "classnames/bind";
import SubHeroContent from "utils/components/SubHeroContent";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {STORE_LOCATIONS} from "routes";
import {shouldRenderStoreFinder} from "modules/stores/WhereToBuyPage/components/StoreFinder";
import {H2_TAG} from "utils/components/TitleUnderlined/constants";
import {isMARINE, getTranslatedLink} from "services/instances";

export default function HomeWhereToBuy() {
  if (!shouldRenderStoreFinder())
    return null;

  return (
      <SubHeroContent theme="green-dark" hideShape>
        <div className="container text-center pt-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <TitleUnderlined
                  color="white"
                  hTag={H2_TAG}>
                <FormattedMessage
                    id="HomeWhereToBuy.title"
                    description="Homepage where to buy section title"
                    defaultMessage="Dónde comprar"/>
              </TitleUnderlined>
              <p>
                <FormattedMessage
                    id="HomeWhereToBuy.subtitle"
                    description="Homepage where to buy section subtitle"
                    defaultMessage="En Spradling no existen fronteras para que nuestro producto llegue donde se necesita. Contamos con una red especializada de distribuidores en todas las geografías para hacer que esto sea posible."/>
              </p>
              <Link
                  to={getTranslatedLink(STORE_LOCATIONS, {})}
                  className={classNames({
                    "btn btn-dark mb-3": true,
                    "btn-prussian-blue": isMARINE()
                  })}>
                <FormattedMessage
                    id="HomeWhereToBuy.buttonLabel"
                    description="Homepage where to buy section button label"
                    defaultMessage="Descubre más"/>
              </Link>
            </div>
          </div>
        </div>
      </SubHeroContent>
  );
}