import PropTypes from "prop-types";
import "./styles/CompanyDetailsInputs.scss";
import { Field } from "formik";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { getCurrentInstanceId } from "services/instances";
import { useQuery } from "react-apollo";
import companyTypesQuery from "modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField/query";

const CompanyDetailsInputs = () => {
  const { data } = useQuery(companyTypesQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
    },
  });

  const companyTypes =
    data?.companyTypes?.edges.map(({ node: { id, name } }) => {
      return {
        id: id,
        name: name,
      };
    }) || [];

  return (
    <div className="bg-white d-flex flex-column">
      <Field
        name="companyName"
        type="text"
        placeholder="Company name"
        className="mt-3 rounded border border-dark py-2 px-2"
      />
      <div className="mt-1">
        <FormErrorMessage name="companyName" />
      </div>
      <Field
        name="position"
        type="text"
        placeholder="Position"
        className="mt-3 rounded border border-dark py-2 px-2"
      />
      <div className="mt-1">
        <FormErrorMessage name="position" />
      </div>
      <Field
        name="companyType"
        as="select"
        className="mt-3 bg-white rounded border border-dark py-2 px-2"
      >
        {(props) => {
          const { field } = props;
          let defaultOption;

          const option = companyTypes.map((item, index) => {
            defaultOption =
              field.value.label === item.name ? (
                <option key="default" value={item.id}>
                  {item.name}
                </option>
              ) : null;
            if (field.value.label !== item.name) {
              return (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              );
            }
          });

          const selectOptions = [defaultOption, ...option];
          return (
            <div>
              <select
                className="bg-white mt-3 py-2 px-2 rounded border border-dark col-12"
                value={field.value}
                {...field}
              >
                {selectOptions}
              </select>
            </div>
          );
        }}
      </Field>
      <div className="mt-1">
        <FormErrorMessage name="companyType" />
      </div>
    </div>
  );
};

CompanyDetailsInputs.propTypes = {
  field: PropTypes.object,
};

export default CompanyDetailsInputs;
