export const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: "1.25rem",
    color: "#495057",
  }),
  control: (provided) => ({
    ...provided,
    paddingRight: 0,
    fontSize: "1rem",
    lineHeight: 1.5,
    borderRadius: 0,
  }),
  dropdownIndicator: () => ({
    SVG: {
      height: 3,
      with: "15px !important",
      color: "#485056",
    },
  }),
  indicatorSeparator: () => {},
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
    color: "#495057",
  }),
};
