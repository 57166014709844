import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import useToggle from "utils/hooks/useToggable";
import ContactForm from "./components/ContactForm";

const ContactUsService = ({ service }) => {
  const [toggable, toggle] = useToggle();

  return (
    <div className="content">
      <div className="row align-items-center">
        <div className="col text-center">
          <TitleUnderlined hTag={H2_TAG} className="text-weight-bold mt-4">
            <FormattedMessage
              id="Services.ContactUsService.title"
              description="title contact section on single service page"
              defaultMessage="For more information on our {service} service please contact us"
              values={{ service: service?.name }}
            />
          </TitleUnderlined>
          <button className="btn btn-dark" type="submit" onClick={toggle}>
            <FormattedMessage
              id="Services.ContactUsService.seeMore"
              description="Submit button label of the Contact form in service detail"
              defaultMessage="More information"
            />
          </button>
        </div>
        <ContactForm
          isOpen={toggable}
          toggle={toggle}
          serviceSlug={service?.slug}
        />
      </div>
    </div>
  );
};

ContactUsService.propTypes = {
  service: PropTypes.object,
};

export default ContactUsService;
