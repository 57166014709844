import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {isMARINE} from "services/instances";
import "./styles/HeaderCircleBtn.scss";

const HeaderCircleBtn = ({className, isActive, children, onClick}) => (
    <button
        className={classNames({
          "header-circle-button": true,
          "header-circle-button--marine": isMARINE(),
          "header-circle-button--active": isActive,
          "header-circle-button--marine--active": isMARINE(),
          [className]: className !== undefined
        })}
        onClick={onClick}>
      {children}
    </button>
);

HeaderCircleBtn.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string
};

HeaderCircleBtn.defaultProps = {
  isActive: false
};

export default HeaderCircleBtn;
