import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
import allPatternsQuery from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchByProduct/components/PatternSelect/query";
import { getCurrentInstanceId } from "services/instances";

const i18nMessages = defineMessages({
  placeholderLoading: {
    id: "PatternSelect.placeholderLoading",
    defaultMessage: "Cargando productos...",
  },
  placeholderLoaded: {
    id: "PatternSelect.placeholderLoaded",
    defaultMessage: "Escribe un producto",
  },
});

export default function PatternSelect({ onChange }) {
  const intl = useIntl();
  const [patterns, setPatterns] = useState([]);
  const [selected, setSelected] = useState(null);
  const { loading } = useQuery(allPatternsQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      saleable: true,
    },
    onCompleted(data) {
      const { patterns } = data;
      setPatterns(
        patterns.edges.map(({ node }) => ({
          label: node.altName ? node.altName : node.name,
          value: node.id,
        }))
      );
    },
  });
  const memoizedOnChange = useCallback(onChange, [onChange]);

  /**
   * Call the provided callback on every change of the value
   */
  useEffect(() => {
    if (selected) memoizedOnChange(selected);
  }, [selected, memoizedOnChange]);

  /**
   * Handles change on the <Select/> component
   * @param option {Object}
   */
  function handleSelectChange(option) {
    setSelected(option.value);
  }

  return (
    <Select
      isDisabled={loading}
      isLoading={loading}
      isClearable={true}
      isSearchable={true}
      name="pattern"
      options={patterns}
      onChange={handleSelectChange}
      placeholder={
        loading
          ? intl.formatMessage(i18nMessages.placeholderLoading)
          : intl.formatMessage(i18nMessages.placeholderLoaded)
      }
    />
  );
}

PatternSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};
