import {connect} from "react-redux";
import ForgotPassword from "../index";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const ForgotPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordContainer;