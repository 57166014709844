import gql from "graphql-tag";

const SingleServiceQuery = gql`
  query SingleServiceQuery($slug: String) {
    service(slug: $slug) {
      id
      name
      slug
      imageThumbLarge
      imageAlt
      content
    }
  }
`;

export default SingleServiceQuery;
