import * as types from 'modules/stores/WhereToBuyPage/actions/types';

export const initialState = {
  patternId: null,
  cityId: null,
  nearbyStores: null,  // null means untouched, empty array will mean no results
  highlightedStoreId: null
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEARBY_STORES_SET:
      return {
        ...state,
        nearbyStores: action.nearbyStores
      };
    case types.HIGHLIGHTED_STORE_ID_SET:
      return {
        ...state,
        highlightedStoreId: action.highlightedStoreId
      };
    case types.PATTERN_ID_SET:
      return {
        ...state,
        patternId: action.patternId
      };
    case types.CITY_ID_SET:
      return {
        ...state,
        cityId: action.cityId
      };
    case types.CURRENT_SEARCH_CLEAR:
      return {
        ...state,
        patternId: null,
        cityId: null,
        nearbyStores: null,
        highlightedStoreId: null
      };
    default:
      return state;
  }
};
