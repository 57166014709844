import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/react-hooks";
import cartQuery from "components/Header/components/HeaderCartButton/query";
import LoadingMessage from "utils/components/LoadingMessage";
import RequestedSampleCard from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard";
import SeverErrorMessage from "utils/components/ServerErrorMessage";
import EmptyCartMessage from "modules/cart/scenes/SamplesCart/components/SamplesList/components/EmptyCartMessage";
import { getCurrentInstanceId } from "services/instances";

export default function SamplesList({ cartId }) {
  const [fetchCart, { called, loading, error, data }] = useLazyQuery(
    cartQuery,
    {
      variables: {
        id: cartId,
        instanceId: getCurrentInstanceId(),
      },
    }
  );
  const memoizedFetchCart = useCallback(fetchCart, [fetchCart]);

  /**
   * Fetch the cart when there's a cartId in the headerReducer redux store
   */
  useEffect(() => {
    if (cartId) memoizedFetchCart();
  }, [cartId, memoizedFetchCart]);

  // TODO have skeleton screens here
  if (loading) return <LoadingMessage />;

  if (error) return <SeverErrorMessage />;

  if (!called || (data && data.cart && data.cart.items.edges.length === 0))
    return (
      <div className="mb-4">
        <EmptyCartMessage />
      </div>
    );

  return data.cart.items.edges.map(({ node }, index) => (
    <div key={index} className="mb-4">
      <RequestedSampleCard item={node} cartId={cartId} />
    </div>
  ));
}

SamplesList.propTypes = {
  cartId: PropTypes.string,
};
