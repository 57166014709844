import gql from "graphql-tag";

const forgotPasswordMutation = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      emailSent
    }
  }
`;

export default forgotPasswordMutation;