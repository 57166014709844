import PropTypes from "prop-types";
import RenderSlides from './components/RenderSlides';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { useSlide } from './hooks/useSlide';
import nextArrow from "./img/right-arrow.png";
import prevArrow from "./img/left-chevron.png";
import TestimonialsModal from "./components/TestimonialsModal";
import { useState } from "react";
import classNames from "classnames";
import "./styles/TestimonialsSlider.scss";
import Flicking from "@egjs/react-flicking";


const TestimonialsSlider = ({ testimonialsItems }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState({});

  const { 
    currentSlide,
    numSlidersperPage,
    hoveredItem,
    handleNext,
    handlePrev,
    handleHoveredItem,
    handleDotClick,
    flickingRef,
    setCurrentSlide,
  } = useSlide(testimonialsItems, isDesktop);

  const handleToggleTestimonialsModal = () => {
    setIsOpen(!isOpen);
  }

  const handleClickCurrentTestimonial = (currentTestimonialSelected) => {
    setIsOpen(!isOpen);
    setCurrentTestimonial(currentTestimonialSelected);
  }
  
  return (
    <div className="w-100 d-flex justify-content-center position-relative z-index-40 mb-4 mt-5">
      <div className={classNames({
        "w-100 max-w-7xl d-flex justify-content-center align-items-center gap-2": true,
        "overflow-hidden": isDesktop
      })}
      >
        {
          isDesktop &&
          <img 
            src={prevArrow}
            alt="prev-icon-arrow-img"
            className="testimonial-slider--prev-arrow mr-4 text-white rounded z-index-10" 
            style={{height: `${testimonialsItems.length <= numSlidersperPage
              ? "0px"
              : "auto"}`}}
            width={40}
            height={40}
            onClick={handlePrev} 
          />
        }
        <div className={"testimonial-slider--container position-relative max-w-6xl w-100"}>
        <Flicking
              ref={flickingRef}
              className={classNames({
                "flicking-viewport slider-container custom_class_slides_transition_testimonials": true,
                "slider-container d-flex transition-all": true,
                "h-100": isDesktop
              })}
              bound
              preventDefaultOnDrag
              moveType="snap"
              renderOnlyVisible
              onChanged={({ index }) => {
                setCurrentSlide(index);
              }}
            >
              {RenderSlides({
                numSlidersperPage,
                testimonialsItems,
                isDesktop,
                hoveredItem,
                handleHoveredItem,
                handleClickCurrentTestimonial 
              })}
          </Flicking>
          <div className="d-flex justify-content-center mt-4">
            {Array.from({ length: Math.ceil(testimonialsItems?.length / numSlidersperPage) }).map((_, index) => (
              <button 
                key={index} 
                onClick={() => handleDotClick(index)} 
                className={classNames({
                  "mx-2 px-3 testimonial-slider--dots": true,
                  "bg-gray-400": index === currentSlide,
                  "bg-gray-300": index !== currentSlide
                })} />
            ))}
          </div>
        </div>

        {
          isDesktop &&
          <img 
            src={nextArrow}
            alt="next-icon-arrow-img"
            className={`testimonial-slider--next-arrow mr-4 text-white rounded z-index-10`}
            style={{height: `${testimonialsItems.length <= numSlidersperPage
                ? "0px"
                : "auto"}`}}
            width={40}
            height={40}
            onClick={handleNext}
          />
        }

      </div>
      
      <TestimonialsModal 
        isOpen={isOpen}
        testimonial={currentTestimonial}
        handleToggleModal={handleToggleTestimonialsModal}
      />
    </div>
  );
}

TestimonialsSlider.propTypes = {
  testimonialsItems: PropTypes.arrayOf({
    node: PropTypes.object
  })
}

export default TestimonialsSlider;