import _ from "lodash";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { FormattedMessage } from "react-intl";
import ProductLine from "modules/Home/components/ProductLines/components/ProductLine";
import { Query } from "react-apollo";
import marketsSimpleQuery from "components/Header/components/HeaderMainMenu/query";
import { getCurrentInstanceId } from "services/instances";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import LoadingMessage from "utils/components/LoadingMessage";

export default function ProductLines() {
  return (
    <div className="product-lines">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center">
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="ProductLines.title"
                description="Title of segments section"
                defaultMessage="Nuestro expertise"
              />
            </TitleUnderlined>
            <p className="text-size-small text-size-lg-base mb-4 mb-lg-5">
              <FormattedMessage
                id="ProductLines.description"
                description="description of segments section"
                defaultMessage="En Spradling tenemos más de 500 productos diseñados específicamente para satisfacer las necesidades de cada segmento en diferentes aplicaciones. "
              />
            </p>
          </div>
        </div>
        <div className="row">
          <Query
            query={marketsSimpleQuery}
            fetchPolicy={FETCH_POLICY_CACHE_AND_NETWORK}
            variables={{
              instanceIds: [getCurrentInstanceId()],
              saleable: true,
            }}
          >
            {({ loading, data }) => {
              if (loading)
                return (
                  <>
                    {[1, 2, 3].map((item) => (
                      <div key={item} className="col-12 col-md-6 col-lg-4 mb-4">
                        <ProductLine />
                      </div>
                    ))}
                  </>
                );

              if (data) {
                let { markets } = data;
                // Filter the markets so ones without related products won't show up
                markets = _.filter(markets.edges, (market) => {
                  let count = 0;
                  _.forEach(
                    market.node.sectors.edges,
                    (sector) => (count += sector.node.patternSet.totalCount)
                  );
                  return count > 0;
                });

                return (
                  <>
                    {markets.map((item, index) => (
                      <div
                        key={index}
                        className="col-12 col-md-6 col-lg-4 mb-4"
                      >
                        <ProductLine productLine={item.node} />
                      </div>
                    ))}
                  </>
                );
              }

              return <LoadingMessage />;
            }}
          </Query>
        </div>
      </div>
    </div>
  );
}
