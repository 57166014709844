import { useState } from "react";
import { useQuery } from "react-apollo";
import { GET_ORDERS_HISTORY } from "../query";
import { useEffect } from "react";

const useGetOrdersHistory = (userInformation) => {
  const [hasMore, setHasMore] = useState(false);
  const { data, loading, error, fetchMore } = useQuery(GET_ORDERS_HISTORY, {
    variables: {
      user: userInformation?.me?.id,
      first: 8,
    },
  });

  useEffect(() => {
    if (data) {
      setHasMore(data.orders.pageInfo.hasNextPage);
    }
  }, [data]);

  const getMoreData = () => {
    fetchMore({
      variables: {
        afterCursor: data ? data?.orders.pageInfo.endCursor : null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.orders.edges;
        const pageInfo = fetchMoreResult.orders.pageInfo;

        return newEdges.length
          ? {
              orders: {
                __typename: "",
                edges: [...previousResult.orders.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  return {
    data,
    error,
    hasMore,
    getMoreData,
    loading,
  };
};

export default useGetOrdersHistory;
