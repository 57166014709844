import gql from "graphql-tag";

const storesByInstanceQuery = gql`
  query StoresByInstanceQuery($instanceId: ID, $active: Boolean) {
    stores(instance: $instanceId, active: $active) {
      edges {
        node {
          id
          name
          address
          phoneNumbers
          email
          latitude
          longitude
          website
          active
          city {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default storesByInstanceQuery;
