import {FormattedMessage} from "react-intl"
import classNames from "classnames/bind";
import "./styles/EmptyCartMessage.scss"
import {Link} from "react-router-dom"
import {PRODUCTS_LIBRARY} from "routes"
import {isMARINE, getTranslatedLink} from "services/instances";

export default function EmptyCartMessage() {
  return (
      <div className="empty-cart-message p-4">
        <h2>
          <FormattedMessage
              id="EmptyCartMessage.title"
              defaultMessage="Todavía no has agregado items al carrito"/>
        </h2>
        <p>
          <FormattedMessage
              id="EmptyCartMessage.subtitle"
              defaultMessage="Puedes pedir muestras y catálogos en las páginas de detalle de cada uno de nuestros productos."/>
        </p>
        <Link
            to={getTranslatedLink(PRODUCTS_LIBRARY)}
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE()
            })}>
          <FormattedMessage
              id="EmptyCartMessage.btnLabel"
              defaultMessage="Ver productos"/>
        </Link>
      </div>
  )
}