import PropTypes from 'prop-types';
import ProseatImageHotspotSection from "./components/ProseatImageHotspotSection";
import ProseatCharacteristics from "./components/ProseatCharacteristics";
import ProseatGlossary from "../ProseatGlossary";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "../../constants/breakpoints";

const ProseatImageHotspot = ({
  characteristicDetails,
  handleChangeAndShowCharacteristicInfo,
  handleHideCharacteristicInfo
}) => {
  const isDekstop = useMediaQuery(LG);

  return (
    <div className="d-xl-flex mt-lg-5 mb-0 h-100">
      <ProseatImageHotspotSection 
        handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
      />
      {!isDekstop && <ProseatGlossary currentCharacteristic={characteristicDetails} />}
      <ProseatCharacteristics
        characteristicDetail={characteristicDetails}
        handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
        handleHideCharacteristicInfo={handleHideCharacteristicInfo}
      />
    </div>
  )
}

ProseatImageHotspot.propTypes = {
  characteristicDetails: PropTypes.arrayOf(PropTypes.object),
  handleChangeAndShowCharacteristicInfo: PropTypes.func,
  handleHideCharacteristicInfo: PropTypes.func
};

export default ProseatImageHotspot;