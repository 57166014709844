import PropTypes from "prop-types";
import meQuery from "utils/queries/MeQuery";
import queryString from "query-string";
import {Query} from "@apollo/react-components";
import {AVAILABLE_SIZES} from "utils/components/UserProfilePic/constants"
import { isMARINE } from "services/instances";

const UserProfilePic = ({size = "small", isMarineReverse = false}) => (
  <Query query={meQuery}>
    {({data}) => {

      if (data) {
        const {me} = data
        // Check all params here https://ui-avatars.com/
        const uiAvatarsParams = {
          name: me.fullName,
          size: AVAILABLE_SIZES[size],
          background: !isMARINE() ? "#046E70" : isMarineReverse ? "#042E60" : "#FFF",
          color: !isMARINE() || isMarineReverse ? "#FFF" : "#042E60",
          bold: true,
          uppercase: true
        };

        return (
            <img
                src={`https://ui-avatars.com/api/?${queryString.stringify(uiAvatarsParams)}`}
                alt={me.fullName}
            className="img-fluid rounded-circle"/>
        );
      }
      
      return <i className="sp-person"/>;
    }}
  </Query>
)

UserProfilePic.propTypes = {
  size: PropTypes.oneOf([
      "small",
      "medium",
      "large"
  ]),
  isMarineReverse: PropTypes.bool
};

export default UserProfilePic
