import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames/bind";
import "components/AuthModal/components/SignUpForm/styles/SignUpForm.scss";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import CountriesField from "components/AuthModal/components/SignUpForm/components/CountriesField";
import CityField from "components/AuthModal/components/SignUpForm/components/CityField";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage";
import RegionField from "components/AuthModal/components/SignUpForm/components/RegionField";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import { isMARINE } from "services/instances";

export default function RequestSamplesForAClientForm({
  disableSubmitButton = false,
  handleSubmit: propHandleSubmit,
}) {
  const intl = useIntl();

  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    countryId: "",
    regionId: "",
    cityId: "",
    address: "",
    zipCode: "",
    privacyPolicy: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    company: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    countryId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    regionId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    cityId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    address: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    zipCode: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    privacyPolicy: Yup.bool().oneOf(
      [true],
      intl.formatMessage(validationMessages.required)
    ),
  });

  function handleSubmit(values) {
    propHandleSubmit(true, values); // true = checkOut for a client
  }

  return (
    <div className="sign-up-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form id="request-samples-for-a-client-form" className="form">
            <div className="form-row">
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="firstName">
                  <FormattedMessage
                    id="SignUpForm.firstNameField"
                    description="First name field of the Request samples for a client form"
                    defaultMessage="Nombre"
                  />
                  *
                </label>
                <Field
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="form-control"
                />
                <FormErrorMessage name="firstName" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="lastName">
                  <FormattedMessage
                    id="SignUpForm.lastNameField"
                    description="Last name field of the Request samples for a client form"
                    defaultMessage="Apellidos"
                  />
                  *
                </label>
                <Field
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="form-control"
                />
                <FormErrorMessage name="lastName" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="company">
                  <FormattedMessage
                    id="SignUpForm.company"
                    description="Business field of the Request samples for a client form"
                    defaultMessage="Empresa"
                  />
                  *
                </label>
                <Field
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                />
                <FormErrorMessage name="company" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="countryId">
                  <FormattedMessage
                    id="SignUpForm.countryId"
                    description="Country field of the Request samples for a client form"
                    defaultMessage="País"
                  />
                  *
                </label>
                <CountriesField />
                <FormErrorMessage name="countryId" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="regionId">
                  <FormattedMessage
                    id="SignUpForm.regionId"
                    description="Region field of the Request samples for a client form"
                    defaultMessage="Región"
                  />
                  *
                </label>
                <RegionField countryId={values.countryId} />
                <FormErrorMessage name="regionId" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="cityId">
                  <FormattedMessage
                    id="SignUpForm.city"
                    description="City field of the Request samples for a client form"
                    defaultMessage="Ciudad"
                  />
                  *
                </label>
                <CityField regionId={values.regionId} />
                <FormErrorMessage name="cityId" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="address">
                  <FormattedMessage
                    id="SignUpForm.address"
                    description="Address field of the Request samples for a client form"
                    defaultMessage="Dirección"
                  />
                  *
                </label>
                <Field
                  type="text"
                  name="address"
                  id="address"
                  className="form-control"
                />
                <FormErrorMessage name="address" />
              </div>
              <div className="form-group col-12 col-lg-4">
                <label htmlFor="zipCode">
                  <FormattedMessage
                    id="SignUpForm.zipCode"
                    description="Zip code field of the Request samples for a client form"
                    defaultMessage="Código postal"
                  />
                  *
                </label>
                <Field name="zipCode" id="zipCode" className="form-control" />
                <FormErrorMessage name="zipCode" />
              </div>
            </div>
            <div className="text-center sign-up-form__privacy-policy">
              <PrivacyPolicyCheckbox
                onChange={(checked) => setFieldValue("privacyPolicy", checked)}
              >
                <FormErrorMessage name="privacyPolicy" />
              </PrivacyPolicyCheckbox>
            </div>
            <div className="text-center">
              <button
                disabled={isSubmitting || disableSubmitButton}
                type="submit"
                className={classNames({
                  "btn btn-dark": true,
                  "btn-prussian-blue": isMARINE(),
                })}
              >
                {isSubmitting ? (
                  <FormattedMessage
                    id="RequestSampleModal.sampleButtonLoading"
                    description="Submit button of the Request samples for a client form"
                    defaultMessage="Solicitando ..."
                  />
                ) : (
                  <FormattedMessage
                    id="RequestSampleForAClientForm.submitButton"
                    description="Submit button of the Request samples for a client form in loading state"
                    defaultMessage="Realizar pedido"
                  />
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

RequestSamplesForAClientForm.propTypes = {
  disableSubmitButton: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};
