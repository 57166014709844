import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import classNames from "classnames/bind";
import "modules/contact/ContactUsPage/components/ContactBox/styles/ContactBox.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getCurrentInstanceId,
  isMARINE,
  isMEXICO,
  isRESOURCES,
} from "services/instances";
import { useMutation } from "react-apollo";
import createSampleContactMutation from "modules/contact/ContactForSamplesPage/components/ContactForSamplesForm/mutation";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CountriesField from "components/AuthModal/components/SignUpForm/components/CountriesField";
import RegionField from "components/AuthModal/components/SignUpForm/components/RegionField";
import CityField from "components/AuthModal/components/SignUpForm/components/CityField";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import TitleUnderlined from "utils/components/TitleUnderlined";
import FormErrorMessage from "utils/components/FormErrorMessage";
import DetailPattern from "modules/contact/ContactForSamplesPage/components/DetailPattern";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import CompanyTypeField from "modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField";
import "modules/contact/ContactForSamplesPage/components/ContactForSamplesForm/styles/ContactForSamplesForm.scss";
import { createResourceContactMutation } from "modules/contact/ContactUsPage/components/ContactBox/mutation";
import { CONTACT_FOR_SAMPLES_TITLES_MSGS, FORM_SCHEMA } from "./constants";
import { handleCountryIdForMX } from "modules/contact/ContactUsPage/components/ContactBox/constants/handleCountryIdForMX";

export default function ContactForSamplesForm() {
  const intl = useIntl();
  const {
    params: { patternSlug },
  } = useRouteMatch();
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [success, setSuccess] = useState(false);
  const mutation = !isRESOURCES()
    ? createSampleContactMutation
    : createResourceContactMutation;
  const [createContact] = useMutation(mutation, {
    onCompleted() {
      setSuccess(true);
    },
  });
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const initialValues = FORM_SCHEMA[getCurrentInstanceId()].initialValue;
  const validationSchema = Yup.object(
    FORM_SCHEMA[getCurrentInstanceId()].validationSchema(intl)
  );

  /**
   * Handles change on the privacy policy checkbox
   * @param checked {Boolean}
   */
  function handlePrivacyPolicyChange(checked) {
    setPrivacyPolicyAccepted(checked);
  }

  /**
   * Handler of the form submit.
   * Calls createContactMutation with the form values
   * @param values {Object}
   * @param setSubmitting {Function}
   */
  function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-unused-vars
    const { countryId, regionId, ...payload } = values; // No send country and region field to backend
    setSubmitting(true);
    createContact({
      variables: {
        input: {
          instanceId: getCurrentInstanceId(),
          patternSlug,
          source: !isRESOURCES() ? "sample-request" : "form-product",
          ...payload,
        },
      },
    })
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  return (
    <div
      className={classNames({
        "contact-box": true,
        "contact-box--marine": isMARINE(),
      })}
    >
      <div className="row justify-content-center">
        {success ? (
          <div className="pt-5 pb-5 white text-center">
            <TitleUnderlined color="white" align="center">
              <FormattedMessage
                id="ContactBox.successMessageTitle"
                defaultMessage="¡Gracias por contactarnos!"
              />
            </TitleUnderlined>
            <p>
              <FormattedMessage
                id="ContactBox.successMessageSubtitle"
                defaultMessage="Pronto nos pondremos en contacto contigo."
              />
            </p>
          </div>
        ) : (
          <>
            <div className="col-12 col-lg-5 align-self-center">
              <TitleUnderlined
                className="text-weight-bold mb-2"
                hTag={H2_TAG}
                color="white"
                align={isDesktop ? "right" : "center"}
              >
                <FormattedMessage
                  {...CONTACT_FOR_SAMPLES_TITLES_MSGS[getCurrentInstanceId()]}
                />
              </TitleUnderlined>
              <p className="contact-box-text sample-contact-box-text">
                {!isRESOURCES() ? (
                  <FormattedMessage
                    id="ContactForSamplesPage.subtitle"
                    defaultMessage="Déjanos tu información y nuestro equipo comercial se pondrá en contacto contigo para revisar tu solicitud de la siguiente referencia."
                    values={{ br: <br /> }}
                  />
                ) : (
                  <FormattedMessage
                    id="ContactForSamplesPage.resources.subtitle"
                    defaultMessage="Déjanos tu información y nuestro equipo comercial se pondrá en contacto contigo para revisar tu solicitud de la siguiente referencia."
                    values={{ br: <br /> }}
                  />
                )}
              </p>
              <div className="detail-pattern-container">
                <DetailPattern patternSlug={patternSlug} />
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values: { countryId, regionId }, isSubmitting }) => (
                  <Form
                    id="contact-for-samples-form"
                    noValidate
                    className="form contact-box-form mt-5 mt-lg-0"
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName">
                            <FormattedMessage
                              id="CreateContactForm.firstNameLabel"
                              description="First name field of the Contact form"
                              defaultMessage="Nombre"
                            />
                            *
                          </label>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="firstName" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lastName">
                            <FormattedMessage
                              id="CreateContactForm.lastNameLabel"
                              description="Last name field of the Contact form"
                              defaultMessage="Apellido"
                            />
                            *
                          </label>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="lastName" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <FormattedMessage
                              id="ContactBoxForm.emailField"
                              description="Email field of the Contact form"
                              defaultMessage="Email"
                            />
                            *
                          </label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="email" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone">
                            <FormattedMessage
                              id="ContactBoxForm.phoneField"
                              description="Phone field of the Contact form"
                              defaultMessage="Teléfono"
                            />
                            *
                          </label>
                          <Field
                            id="phone"
                            name="phone"
                            type="number"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="phone" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={classNames({
                          "col-md-6": true,
                          "col-md-12": isRESOURCES(),
                        })}
                      >
                        <div className="form-group">
                          <label htmlFor="companyName">
                            <FormattedMessage
                              id="ContactBoxForm.companyField"
                              description="Company field of the Contact form"
                              defaultMessage="Empresa"
                            />
                            *
                          </label>
                          <Field
                            id="companyName"
                            name="companyName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="companyName" />
                        </div>
                      </div>
                      {!isRESOURCES() && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="position">
                              <FormattedMessage
                                id="SignUpForm.position"
                                description="Position field of the Sign Up form"
                                defaultMessage="Cargo"
                              />
                              *
                            </label>
                            <Field
                              type="text"
                              name="position"
                              id="position"
                              className="form-control form-control-lg"
                            />
                            <FormErrorMessage color="white" name="position" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <div className={classNames({
                        "col-md-6": !isMEXICO(),
                        "col-12": isMEXICO()
                        })}>
                        <div className="form-group">
                          <label htmlFor="companyType">
                            <FormattedMessage
                              id="ContactBoxForm.companyTypeField"
                              description="Company type field of the Contact form"
                              defaultMessage="Tipo de empresa"
                            />
                          </label>
                          <CompanyTypeField className="form-control-lg" />
                          <FormErrorMessage color="white" name="companyType" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          {!isMEXICO() &&
                            <>
                              <label htmlFor="countryId">
                              <FormattedMessage
                                id="ContactBoxForm.countryField"
                                description="Country field of the Contact form"
                                defaultMessage="País"
                              />
                              *
                              </label>
                            <CountriesField className="form-control-lg" />
                            <FormErrorMessage color="white" name="countryId" />
                            </>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="regionId">
                            {!isMEXICO() 
                            ? <FormattedMessage
                              id="SignUpForm.regionId"
                              description="Region field of the Contact form"
                              defaultMessage="Región"
                            />
                            : <FormattedMessage
                              id="SignUpForm.stateId"
                              description="State field of the Contact form"
                              defaultMessage="Estado"
                            />}
                            *
                          </label>
                          <RegionField
                            className="form-control-lg"
                            countryId={handleCountryIdForMX(countryId)}
                            isCustomStyles
                          />
                          <FormErrorMessage color="white" name="regionId" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="cityId">
                            {!isMEXICO() 
                            ? <FormattedMessage
                              id="ContactBoxForm.cityField"
                              description="City field of the Contact form"
                              defaultMessage="Ciudad"
                            />
                            : <FormattedMessage
                            id="ContactBoxForm.cityFieldMx"
                            description="City field of the Contact form"
                            defaultMessage="Delegación"
                          />}
                            *
                          </label>
                          <CityField
                            className="form-control-lg"
                            regionId={regionId}
                            isCustomStyles
                          />
                          <FormErrorMessage color="white" name="cityId" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      {!isRESOURCES() && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="address">
                              <FormattedMessage
                                id="SignUpForm.address"
                                description="Address field of the Contact form"
                                defaultMessage="Dirección"
                              />
                              *
                            </label>
                            <Field
                              id="address"
                              name="address"
                              type="text"
                              className="form-control form-control-lg"
                            />
                            <FormErrorMessage color="white" name="address" />
                          </div>
                        </div>
                      )}
                      <div
                        className={classNames({
                          "col-md-6": true,
                          "col-md-12": isRESOURCES(),
                        })}
                      >
                        <div className="form-group">
                          <label htmlFor="zipCode">
                            <FormattedMessage
                              id="SignUpForm.zipCode"
                              description="Zip code field of the Contact form"
                              defaultMessage="Código postal"
                            />
                            *
                          </label>
                          <Field
                            id="zipCode"
                            name="zipCode"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="zipCode" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">
                        {!isRESOURCES() ? (
                          <FormattedMessage
                            id="ContactBoxForm.messageField"
                            description="Message field of the Contact form"
                            defaultMessage="Message"
                          />
                        ) : (
                          <FormattedMessage
                            id="ContactBoxForm.messageResourcesField"
                            description="Message field for ps resources of the Contact form"
                            defaultMessage="How can we help you?"
                          />
                        )}
                        *
                      </label>
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        className="form-control form-control-lg"
                        rows={5}
                      />
                      <FormErrorMessage color="white" name="message" />
                    </div>
                    <PrivacyPolicyCheckbox
                      onChange={handlePrivacyPolicyChange}
                    />
                    <div>
                      <button
                        disabled={isSubmitting || !privacyPolicyAccepted}
                        type="submit"
                        className="btn btn-dark"
                      >
                        {isSubmitting ? (
                          <FormattedMessage
                            id="ContactBox.submitButtonLoading"
                            description="Submit button label of the Contact form in loading state"
                            defaultMessage="Enviando"
                          />
                        ) : (
                          <FormattedMessage
                            id="ContactBox.submitButtonLabel"
                            description="Submit button label for contact box form"
                            defaultMessage="Enviar"
                          />
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
