import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import "./styles/ProjectCard.scss";
import Skeleton from "react-loading-skeleton";
import TranslatedLink from "routes/components/TranslatedLink";
import {PROJECT_DETAILS} from "routes";
import {H2_TAG, H4_TAG} from "utils/components/TitleUnderlined/constants";

const ProjectCard = ({project, titleHTag}) => (
    <div className="project-card">
      <div className="project-card__image">
        {
          project &&
          <TranslatedLink
              params={{
                projectSlug: project.slug
              }}
              name={PROJECT_DETAILS}>
            <img
                src={project.listingImageThumbSmall}
                alt={`${project.name} ${project.client}`}
                title={`${project.name} ${project.client}`}
                className="img-fluid"/>
          </TranslatedLink>
        }
      </div>
      <div className="project-card__details p-3">
        {
          titleHTag === H2_TAG ?
              <h2 className="h4 text-family-base text-weight-bold">
                {
                  project ?
                      `${project.name}`
                      :
                      <Skeleton width={180}/>
                }
              </h2>
              :
              <h4 className="h3 text-family-base text-weight-bold">
                {
                  project ?
                      `${project.name}`
                      :
                      <Skeleton width={180}/>
                }
              </h4>

        }
        <p className="mb-0">
          <span className="text-weight-bold">
            <FormattedMessage
                id="ProjectCard.client"
                description="Client at project card"
                defaultMessage="Cliente"/>:&nbsp;
          </span>
          <span className="text-weight-light gray">
            {
              project ?
                  `${project.client}`
                  :
                  <Skeleton width={80}/>
            }
          </span>
        </p>
        <p className="mb-0">
          <span className="text-weight-bold">
            <FormattedMessage
                id="ProjectCard.sector"
                description="Sector at project card"
                defaultMessage="Sector"/>:&nbsp;
          </span>
          <span className="text-weight-light gray">
            {
              project ?
                  `${project.sector.name}, ${project.sector.market.name}`
                  :
                  <Skeleton width={80}/>
            }
          </span>
        </p>
      </div>
    </div>
);

ProjectCard.defaultProps = {
  project: null,
  titleHTag: H2_TAG
};

ProjectCard.propTypes = {
  titleHTag: PropTypes.oneOf([H2_TAG, H4_TAG]),
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    sector: PropTypes.shape({
      name: PropTypes.string.isRequired,
      market: PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    }).isRequired,
    listingImageThumbSmall: PropTypes.string.isRequired,
  })
};

export default ProjectCard;