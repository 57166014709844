export const formatedDate = (edge, lang) => {
  const dateToFormat = new Date(edge.node.updated);
  const options = { month: 'long', day: 'numeric' };
  let resultDate;

  if (lang === 'en') {
    resultDate = dateToFormat.toLocaleDateString('en-US', options) + "th";
  } else {
    resultDate = dateToFormat.toLocaleDateString('es-ES', options);
  }

  return resultDate;
}