const BASC_CERTIFICATION = "BASC"
const BEST_WORKPLACES = "Best WorkPlaces"
const BEST_WORKPLACES_COLOMBIA = "Best WorkPlaces Colombia"
const CARBONO_NEUTRAL = "CARBONO NEUTRAL"
const ISO_14001 = "ISO 14001"
const ISO_1649_SGS = "ISO 1649 SGS"
const ISO_45001 = "ISO 45001"
const ISO_50001 = "ISO 50001"
const ISO_90001_SGS = "ISO 90001 SGS"
const MED = "MED"
const OEA = "OEA"
const UCA = "UCA"

export const CLASSNAMES_LOGO_CERTIFICATIONS = {
  [BASC_CERTIFICATION]: "basc-certificate",
  [BEST_WORKPLACES]: "best-workplaces-certificate",
  [BEST_WORKPLACES_COLOMBIA]: "best-workplaces-colombia-certificate",
  [CARBONO_NEUTRAL]: "carbono-neutral-certificate",
  [ISO_14001]: "iso-14001-certificate",
  [ISO_1649_SGS]: "iso-1649-sgs-certificate",
  [ISO_45001]: "iso-45001-certificate",
  [ISO_50001]: "iso-50001-certificate",
  [ISO_90001_SGS]: "iso-90001-sgs-certificate",
  [MED]: "med-certificate",
  [OEA]: "oea-certificate",
  [UCA]: "uca-certificate"
}