import gql from 'graphql-tag'

const updateItemQuantity = gql`
  mutation UpdateItemQuantity($input: UpdateItemQuantityInput!) {
    updateItemQuantity(input: $input){
      item {
        id
        quantity
      }
    }
  }
`

export default updateItemQuantity