const ATTRIBUTES = "attributes"
const BACKING_PROFILE = "backingProfile"
const COMPLIANCES = "compliances"
const SEALS_WE_CONTRIBUTE_TO = "seals"
const PACKAGING = "packaging"

export const MANUFACTURING = "manufacturing"
const CERTIFICATIONS = "certifications"

export const SUSTAINABILITY_ATTRIBUTES = {
  [ATTRIBUTES]: {
    biobasedPvcBioAttributed: "biobasedPvcBioAttributed",
    phthalateFreeProduct: "phthalateFreeProduct",
    phthalateFreeProductCertificateFile: "phthalateFreeProductCertificateFile",
    frFree: "frFree",
    frFreeCertificateFile: "frFreeCertificateFile",
    mpFree: "mpFree",
    mpFreeCertificateFile: "mpFreeCertificateFile",
    cytotoxicity: "cytotoxicity",
    cytotoxicityCertificateFile: "cytotgenericCertificateFile",
    skinSafeSensitization: "skinSafeSensitization",
    skinSafeSensitizationCertificateFile: "skinSafeSensitizationCertificateFile",
    epd: "epd",
    epdCertificateFile: "epdCertificateFile",
    antimicrobial: "antimicrobial",
    backingProfile: "backingProfile",
    backingProfileLogo: "backingProfileLogo"
  },
  [COMPLIANCES]: {
    prop65: "prop65",
    prop65CertificateFile: "prop65CertificateFile",
    prop65Logo: "prop65Logo",
    rohs: "rohs",
    rohsLogo: "rohsLogo",
    reach: "reach",
    rohsCertificateFile: "rohsCertificateFile",
    reachLogo: "reachLogo",
    reachCertificateFile: "reachCertificateFile"
  }, 
  [CERTIFICATIONS]: {
    greenguard: "greenguard",
    greenguardLogo: "greenguardLogo",
    greenguardCertificateFile: "greenguardCertificateFile",
    indoorAirQuality: "indoorAirQuality",
    indoorAirQualityCertificateFile: "indoorAirQualityCertificateFile",
    indoorAirQualityLogo: "indoorAirQualityLogo",
    globalRecycledStandard: "globalRecycledStandard",
    globalRecycledStandardLogo: "globalRecycledStandardLogo",
    globalRecycledStandardCertificateFile: "globalRecycledStandardCertificateFile",
    aPlusEmissions: "aPlusEmissions",
    aPlusEmissionsLogo: "aPlusEmissionsLogo",
    aPlusEmissionsCertificateFile: "aPlusEmissionsCertificateFile"
  },
  // This manufacturing object is necessary to add this group
  [MANUFACTURING]: {},
  [SEALS_WE_CONTRIBUTE_TO]: {
    leed: "leed",
    leedLogo: "leedLogo",
    well: "well",
    wellLogo: "wellLogo",
  },
  [PACKAGING]: {
    packagingFscLogo: "packagingFscLogo"
  }
}

