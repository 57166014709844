import { useState } from 'react';
import PropTypes from 'prop-types';
import {Collapse} from "reactstrap";
import "./styles/SpecsTableAccordion.scss";

export default function SpecsTableAccordion({children, title, disclaimer, openByDefault = false}) {
  const [isOpen, setIsOpen] = useState(openByDefault);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (
      <div className="product-specs-accordion">
        <h3
          className="product-specs-accordion__title"
          onClick={handleClick}>
          {title}
          {
            isOpen
                ?
                <i className="sp-minus"/>
                :
                <i className="sp-plus"/>
          }
        </h3>
        <Collapse
            className="product-specs-accordion__collapse"
            isOpen={isOpen}>
          <table className="table table-striped product-specs-table">
            <tbody>
            {children}
            {
              disclaimer &&
              <tr>
                <td colSpan={2}>
                  {disclaimer}
                </td>
              </tr>
            }
            </tbody>
          </table>
        </Collapse>
      </div>
  )
}

SpecsTableAccordion.propTypes = {
  title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]).isRequired,
  disclaimer: PropTypes.node,
  openByDefault: PropTypes.bool,
  children: PropTypes.element
};