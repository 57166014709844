import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import { Modal } from "reactstrap";
import singleFlatpageQuery from "./query";
import { FormattedMessage } from "react-intl";
import "./styles/FlatPageinfo.scss";

const FlatPageInfo = ({ slug, isOpen = false, handleAcceptTNC }) => {
  const { data } = useQuery(singleFlatpageQuery, {
    variables: {
      slug: slug,
    },
  });

  return (
    <Modal isOpen={isOpen} centered size="lg">
      <div className="container-data-policy-and-terms overflow-auto p-5">
        <h1 className="text-center mb-5 title-underlined title-underlined--align-center">
          {data?.flatpage?.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: data?.flatpage?.content }}
          className="overflow-auto"
        />
        <div className="text-center">
          <button
            className="btn btn-dark text-uppercase"
            onClick={() => handleAcceptTNC(true)}
          >
            <FormattedMessage
              id="PrivacyPolicyCheckbox.buttonPrivacyPolicyAccept"
              description="terms and conditions button"
              defaultMessage="Accept"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};

FlatPageInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  slug: PropTypes.string,
  handleAcceptTNC: PropTypes.func,
};

export default FlatPageInfo;
