/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import SimpleImage from "./components/SimpleImage";
import CharacteristicSliderImages from "./components/CharacteristicSliderImages";
import "./styles/RenderCharacteristicImage.scss";

const CharacteristicImage = ({ details }) => {
  const { img, images } = details.characteristicDetail;
  const imageClassName = img?.imgClassName ?? "";

  return (
    img
      ? <SimpleImage characteristicImage={img} imgClassName={imageClassName} />
      : <CharacteristicSliderImages characteristicImages={images} />
  )
}

CharacteristicImage.propTypes = {
  details: PropTypes.shape({
    showCharacteristicInfo: PropTypes.bool,
    characteristicDetail: PropTypes.shape({
      id: PropTypes.string,
      characteristicNumber: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.array,
      img: PropTypes.shape({
        imgWidth: PropTypes.string,
        imgHeight: PropTypes.string
      }),
    })
  })
}

export default CharacteristicImage;