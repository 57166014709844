import {connect} from "react-redux";
import SelectSearchMethod from "../index";
import {clearCurrentSearch} from "modules/stores/WhereToBuyPage/actions";

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCurrentSearch: () => dispatch(clearCurrentSearch())
  };
};

const SelectSearchMethodContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectSearchMethod);

export default SelectSearchMethodContainer;