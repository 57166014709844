import gql from "graphql-tag";

const resetPasswordMutation = gql`
  mutation ResetPassword($input: ResetPasswordInput!){
    resetPassword(input:$input){
      passwordChanged
      validationErrors
    }
  }
`;

export default resetPasswordMutation;