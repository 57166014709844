import {FormattedMessage} from "react-intl";

export default function SeverErrorMessage() {
  return (
      <div className="pt-3 pb-3">
        <p className="text-center">
          <FormattedMessage
              id="SeverErrorMessage.label"
              description="Label when server error message"
              defaultMessage="Ocurrió un error en el servidor, estamos trabajando para solucionarlo cuanto antes."/>
        </p>
      </div>
  )
}