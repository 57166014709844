import gql from "graphql-tag";

const singleBrandQuery = gql`
  query SingleBrandQuery($slug: String) {
    brand(slug: $slug) {
      id
      title
      slug
      imageThumbLarge
      description
      content
    }
  }
`;

export default singleBrandQuery;
