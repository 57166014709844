import PropTypes from "prop-types";
import CharacteristicDetailsSectionWrapper from "./components/CharacteristicDetailsSectionWrapper";
import "./styles/ProseatCharacteristicDetails.scss";

const CharacteristicDetails = ({ details, hideCharacteristicInfo }) => {
  return (
    <CharacteristicDetailsSectionWrapper 
      details={details}
      hideCharacteristicInfo={hideCharacteristicInfo}
    />
  )
}

CharacteristicDetails.propTypes = {
  details: PropTypes.shape({
    showCharacteristicInfo: PropTypes.bool,
    characteristicDetail: PropTypes.shape({
      id: PropTypes.string,
      characteristicNumber: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.array,
      img: PropTypes.string,
      imgWidth: PropTypes.string,
      imgHeight: PropTypes.string
    })
  }),
  hideCharacteristicInfo: PropTypes.func
}

export default CharacteristicDetails;