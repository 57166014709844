import stadisticsImg from "../img/image-stadistics.png";
import optimizationOfResources from "../img/optimization-resources.png";
import energeticEficient from "../img/energy-eficient.png";
import carefulWater from "../img/careful-water.png";
import reductionEmissionsCarbon from "../img//reduction-emisions-carbon.png";

// MASTER IMAGES INNOVATION AND GROWTH
import innovation from "../img/innovation.png";
import innovationLaboratories from "../img/innovation-laboratories.png";
import resourcesOptimization from "../img/resources-optimization.png";
import energeticEficientMaster from "../img/energetic-eficient-master.png";
import carefulWaterMaster from "../img/careful-water-master.png";
import reductionEmissionsCarbonMaster from "../img/reduction-emissions-master.png";

// MASTER IMAGES AMBIENTAL COMMIT
import costaRicaPlant from "../img/costa-rica-plant.png";
import cedi from "../img/CEDI.png";
import optimizationOfResourcesCostaRica from "../img/resources-optimization-costa-rica.png";
import energeticEficientCostaRica from "../img/energetic-eficient-costa-rica.png";
import reductionEmissionsCarbonCostaRica from "../img/reduction-emissions-master.png";
import { FormattedMessage } from "react-intl";

export const HIGHER_PURPOSE_ITEMS_COLOMBIA = [
  {
    id: 1,
    groupTitle: (
      <FormattedMessage 
        id="HigherPurpose.plantColombia.title.innovation"
        defaultMessage="Innovation and Growth"
      />
    ),
    isOpen: true,
    groupItems: [
      {
        id: 1,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.innovation.projectZoom.title"
            defaultMessage="ZOOM Project"
          />
        ),
        imgIcon: stadisticsImg,
        masterImg: innovation,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.innovation.projectZoom.description"
            defaultMessage="The incorporation of this new calendering machine increases production capacity, diversifies the portfolio and reflects the environmental commitment to cleaner technologies and conscientious waste management."
          />
        ),
      },
      {
        id: 2,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.innovation.laboratoires.title"
            defaultMessage="Innovation Labs"
          />
        ),
        imgIcon: stadisticsImg,
        masterImg: innovationLaboratories,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.innovation.laboratoires.description"
            defaultMessage="In our two innovation labs, the best ideas come to life and each experiment represents a step forward in building a better world. "
          />
        )
      } 
    ]
  },
  {
    id: 2,
    groupTitle: (
      <FormattedMessage 
        id="HigherPurpose.plantColombia.environmental.title"
        defaultMessage="Environmental Commitment"
      />
    ),
    isOpen: true,
    groupItems: [
      {
        id: 1,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.wasteOptimization.title"
            defaultMessage="Waste Optimization"
          />
        ),
        imgIcon: optimizationOfResources,
        masterImg: resourcesOptimization,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.wasteOptimization.description"
            defaultMessage="Committed to sustainability, we transform 85% of the industrial waste we generate (more than 7,300 tons) into the production of flooring for various industries."
          />
        ),
      },
      {
        id: 2,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.energyEfficiency.title"
            defaultMessage="Energy Efficiency"
          />
        ),
        imgIcon: energeticEficient,
        masterImg: energeticEficientMaster,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.energyEfficiency.description"
            defaultMessage="We are a benchmark in energy efficiency and environmental management, backed by 72 solar panels and certified to ISO 14001 and ISO 50001. We strive every year to reduce our environmental impact, thus consolidating our commitment to sustainable and responsible practices. "
          />
        ),
      }, 
      {
        id: 3,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.waterCare.title"
            defaultMessage="Water Care"
          />
        ),
        imgIcon: carefulWater,
        masterImg: carefulWaterMaster,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.waterCare.description"
            defaultMessage="We implemented the electrocoagulation system that allows us to improve the elimination of solids, improving the quality of the water that goes to the treatment systems. "
          />
        ),
      }, 
      {
        id: 4,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.carbonEmissions.title"
            defaultMessage="Carbon Emissions Reduction"
          />
        ),
        imgIcon: reductionEmissionsCarbon,
        masterImg: reductionEmissionsCarbonMaster,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.carbonEmissions.description"
            defaultMessage="We measure the carbon footprint of our productive activity and compensate them by purchasing bonds and participating in projects that seek to protect the fauna and flora of the forests."
          />
        ),
      } 
    ] 
  },
]

export const HIGHER_PURPOSE_ITEMS_COSTA_RICA = [
  {
    id: 1,
    groupTitle: (
      <FormattedMessage 
        id="HigherPurpose.plantColombia.title.innovation"
        defaultMessage="Innovation and Growth"
      />
    ),
    isOpen: true,
    groupItems: [
      {
        id: 1,
        // title: "Calandra",
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.calandra.title"
            defaultMessage="Calandra"
          />
        ),
        imgIcon: stadisticsImg,
        masterImg: costaRicaPlant,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.calandra.description"
            defaultMessage="The incorporation of this new calendering machine increases production capacity, diversifies the portfolio and reflects the environmental commitment to cleaner technologies and conscientious waste management."
          />
        ),
      },
      {
        id: 2,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.cedi.title"
            defaultMessage="CEDI"
          />
        ),
        imgIcon: stadisticsImg,
        masterImg: cedi,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.cedi.description"
            defaultMessage="In the Distribution Center (CEDI), we use German technology with an automated transport system. Through an innovative bridge that connects to an automated guided vehicle, we are able to move finished products autonomously. This pioneering approach in the region facilitates the efficient connection between the CEDI and the production area. "
          />
        ),
      } 
    ]
  },
  {
    id: 2,
    groupTitle: (
      <FormattedMessage 
        id="HigherPurpose.plantCostaRica.environmental.title"
        defaultMessage="Environmental Commitment"
      />
    ),
    isOpen: true,
    groupItems: [
      {
        id: 1,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.wasteOptimization.title"
            defaultMessage="Waste Optimization"
          />
        ),
        imgIcon: optimizationOfResources,
        masterImg: optimizationOfResourcesCostaRica,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.wasteOptimization.description"
            defaultMessage="We transform more than 3 tons of organic waste from the canteen into compost for use in the plant's gardens and reforestation areas."
          />
        ),
      },
      {
        id: 2,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.energyEfficiency.title"
            defaultMessage="Energy Efficiency"
          />
        ),
        imgIcon: energeticEficient,
        masterImg: energeticEficientCostaRica,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.energyEfficiency.description"
            defaultMessage="We have 690 solar panels, with certifications such as ISO 14001 and ISO 50001 that support our daily commitment to reduce our environmental impact."
          />
        ),
      }, 
      {
        id: 3,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.waterCare.title"
            defaultMessage="Water Care"
          />
        ),
        imgIcon: carefulWater,
        masterImg: carefulWaterMaster,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.waterCare.description"
            defaultMessage="We have treatment systems to ensure wastewater quality and comply with legal standards. We also reuse treated water to irrigate our green areas. "
          />
        ),
      }, 
      {
        id: 4,
        title: (
          <FormattedMessage 
            id="HigherPurpose.plantColombia.carbonEmissions.title"
            defaultMessage="Carbon Emissions Reduction"
          />
        ),
        imgIcon: reductionEmissionsCarbon,
        masterImg: reductionEmissionsCarbonCostaRica,
        description: (
          <FormattedMessage 
            id="HigherPurpose.plantCostaRica.carbonEmissions.description"
            defaultMessage="We calculate our carbon footprint to understand our direct greenhouse gas emissions and offset them by purchasing bonds and participating in forest conservation projects to mitigate climate change. "
          />
        ),
      } 
    ] 
  },
]