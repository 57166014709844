import OfficesList from "modules/about/SpradlingInTheWorldPage/components/OfficesList";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import OfficesMap from "modules/about/SpradlingInTheWorldPage/components/OfficesMap";
import SEO from "services/seo/components/SEO";
import { SPRADLING_IN_THE_WORLD_SCENE } from "services/seo/constants/scenes";

const SpradlingInTheWorldPage = () => (
  <div className="spradling-in-the-world">
    <ScrollTopOnMount />
    <OfficesMap />
    <OfficesList />
    <SEO scene={SPRADLING_IN_THE_WORLD_SCENE} />
  </div>
);

export default SpradlingInTheWorldPage;
