import ContactBox from "modules/contact/ContactUsPage/components/ContactBox";
import PropTypes from "prop-types";
import { Modal, ModalHeader } from "reactstrap";
import "./styles/contactForm.scss";

const ContactForm = ({ isOpen, toggle, serviceSlug }) => (
  <Modal
    className="modal-service-contact"
    size="lg"
    isOpen={isOpen}
    toggle={toggle}
    centered
  >
    <ModalHeader className="modal-service-header py-4" toggle={toggle} />
    <ContactBox injectedValues={{ serviceSlug }} />
  </Modal>
);

ContactForm.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  serviceSlug: PropTypes.string,
};

export default ContactForm;
