import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { HOME_PAGE } from "routes";
import { getTranslatedLink } from "services/instances";
import "./styles/RecordsPage.scss";
import { useQuery } from "react-apollo";
import meQuery from "utils/queries/MeQuery";
import classNames from "classnames";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { ProfileInformation } from "./components/ProfileInformation";
import UserOrdersHistory from "./components/UserOrdersHistory";

const RecordsPage = ({ authReducer }) => {
  const [theOrderHistoryTabIsActive, setTheOrderHistoryTabIsActive] =
    useState(true);
  const [theAccountTabIsActive, setTheAccountTabIsActive] = useState(false);

  const { isAuthenticated } = authReducer;
  const { data } = useQuery(meQuery);
  const isMobile = useMediaQuery("(max-width: 995px)");

  if (!isAuthenticated) {
    return <Redirect to={getTranslatedLink(HOME_PAGE, {})} />;
  }

  const handleTabClick = (isOrderHistoryTabActive, isAccountTabActive) => {
    setTheOrderHistoryTabIsActive(isOrderHistoryTabActive);
    setTheAccountTabIsActive(isAccountTabActive);
  };

  return (
    <div className="records-page">
      <div className="container-fluid main-banner-profile">
        <div className="container h-100 p-0">
          <div className="d-flex flex-column flex-md-row align-items-center col-12 h-100">
            <div className="col-12 d-flex flex-column flex-md-row d-md-block d-lg-flex my-auto my-md-0 align-items-start justify-content-center justify-content-md-start align-items-md-end p-0">
              <h1 className="p-0 fw-bold col-md-12 col-lg-5 col-xl-4">
                <FormattedMessage
                  id="RecordsPage.titleGreeting"
                  description="Greet the user"
                  defaultMessage="Hello"
                />
                <span
                  className={classNames({
                    "d-block col-12 col-md-12 col-lg-12 p-0 fw-bold mt-0": true,
                    "divider": !isMobile
                  })}
                >
                  {data?.me?.fullName}
                </span>
              </h1>
              <li
                className={classNames({
                  "text-dark pointer text-decoration-none underline-hover list-style ": true,
                  "active-history-orders-option": theOrderHistoryTabIsActive,
                  "divider-orders": !isMobile
                })
              }
                onClick={() => handleTabClick(true, false)}
              >
                <h5 className="banner-text pt-0 pl-lg-5 pr-lg-5 text-center">
                  <FormattedMessage
                    id="RecordsPage.ordersHistory"
                    description="Orders history title"
                    defaultMessage="Orders history"
                  />
                </h5>
              </li>
              <li
                className={`text-dark pointer text-decoration-none underline-hover list-style ml-md-4 ${
                  theAccountTabIsActive ? "active-account-option" : ""
                }`}
                onClick={() => handleTabClick(false, true)}
              >
                <h5 className="pt-0 pt-md-4 pt-lg-3 banner-text">
                  <FormattedMessage
                    id="RecordsPage.account"
                    description="Account title"
                    defaultMessage="Account"
                  />
                </h5>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pb-4 pt-lg-5 pb-lg-5">
        {theAccountTabIsActive && <ProfileInformation userInformation={data} />}
        {theOrderHistoryTabIsActive && (
          <UserOrdersHistory userInformation={data} />
        )}
      </div>
    </div>
  );
};

RecordsPage.propTypes = {
  authReducer: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
};

export default RecordsPage;
