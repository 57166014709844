import gql from 'graphql-tag';

export const getGlossaryTypes = gql`
  query {
    glossaryTypes {
      edges {
        node {
          id
          name
          glossarywordSet {
            edges {
              node {
                id
                name
                definition
              }
            }
          }
        }
      }
    }
  }
`;

export const getGlossaryWordsBySearch = gql`
  query getGlossaryWordsSearch($keyword: String, $type: ID) {
    glossarySearch(keyword: $keyword, type: $type) {
      edges {
        node {
          id
          name
          definition
        }
      }
    }
  }
`;

export const lookUpGlossaryWord = gql`
  query lookUpGlossaryWords($text: String!) {
    lookupWords(text: $text) {
      edges {  
        node {
          id
          name
          definition
        }
      }
    }
  }
`;