import { Suspense } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import "./styles/ServiceLine.scss";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { SERVICES_DETAILS } from "routes";
import { getTranslatedLink } from "services/instances";

export default function ServiceLine({ serviceLine = null }) {
  const to = serviceLine
    ? getTranslatedLink(SERVICES_DETAILS, { serviceSlug: serviceLine.slug })
    : "";

  return (
    <div className="product-line">
      <div className="product-line__photo mb-4">
        {serviceLine && (
          <Link to={to}>
            <Suspense fallback={<Skeleton width={100} />}>
              <img
                src={serviceLine.imageThumbMedium}
                alt={serviceLine.imageAlt}
                title={serviceLine.name}
                className="img-fluid"
              />
            </Suspense>
          </Link>
        )}
      </div>
      <h3 className="product-line__title text-weight-bold text-size-h4">
        <Link to={to}>
          {serviceLine ? `${serviceLine.name}` : <Skeleton width={120} />}
        </Link>
      </h3>
      <p className="product-line__description text-left text-size-small gray-light pl-4 pr-4">
        {serviceLine ? `${serviceLine.description}` : <Skeleton count={3} />}
      </p>
      <div className="product-line__button-container text-center">
        {serviceLine ? (
          <Link
            to={to}
            className={classNames({
              "btn btn-outline-dark": true,
            })}
          >
            <FormattedMessage
              id="ProductLine.seeMoreButton"
              description="See more button label at product line component"
              defaultMessage="Quiero saber más"
            />
          </Link>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
    </div>
  );
}

ServiceLine.propTypes = {
  serviceLine: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageThumbMedium: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
  }),
};
