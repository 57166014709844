import PropTypes from "prop-types";
import "./styles/ProductCardName.scss";
import { Link } from "react-router-dom";
import { PRODUCT_DETAILS } from "routes";
import Skeleton from "react-loading-skeleton";
import { getTranslatedLink } from "services/instances";
import { handleProductAltName } from "utils/commons/products";

export default function ProductCardName({
  name,
  altName,
  slug,
  loading = false,
}) {
  if (loading)
    return (
      <div className="p-3">
        <Skeleton height={30} />
      </div>
    );

  return (
    <div className="product-card-name product-card-name--in-listing">
      <h2>
        <Link to={getTranslatedLink(PRODUCT_DETAILS, { productSlug: slug })}>
          {!altName ? (
            name
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: handleProductAltName(altName),
              }}
            />
          )}
        </Link>
      </h2>
    </div>
  );
}

ProductCardName.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  altName: PropTypes.string,
  slug: PropTypes.string,
};
