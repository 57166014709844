import gql from "graphql-tag";

const subscribeToNewsletterMutation = gql`
  mutation SubscribeToNewsletter($input: SubscribeToNewsletterInput!) {
    subscribeToNewsletter(input: $input) {
      subscribed
    }
  }
`;

export default subscribeToNewsletterMutation;
