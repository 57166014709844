import gql from "graphql-tag";

const singlePatternFragment = gql`
  fragment SinglePatternFragment on PatternNode {
    id
    name
    altName
    slug
    isNew
  }
`;

export default singlePatternFragment;
