import gql from "graphql-tag";

export const collectionsQuery = gql`
query quickship{
  quickshipCollections{
    edges{
      node{
        id
        name
        active
        order
        image
        imageThumb
      }
    }
  }
}
`;


export const collectionsQueryByID = gql`
query quickship2 ($id: ID!) {
  quickshipCollection(id: $id){
    id
    name
    active
    order
    productonquickshipSet{
      edges{
        node{
          id
        	product{
            id
            color
            code
            stock
            imageThumbMedium
            imageThumbLarge
            imageForProduction
            pattern {
              name             
            }
          }
        }
      }
    }
  }
}
`;

export const filtersByCollectionID = gql`
  query GetFiltersByCollection ($quickshipCollection: ID!, $language: String!) {
    filtersByCollection(
    quickshipCollection: $quickshipCollection,
    language: $language
    )
  }
`

export const filteredProductsQuickshipPage = gql`
query filteredProductsQuickshipPage($quickshipCollection: ID!, $sectorIn: [ID], $designIn: [String] ) {
  quickshipProducts(
    quickshipCollection: $quickshipCollection,
    sectorIn: $sectorIn,
    designIn: $designIn
  ) {
    edges {
      node {
        quickshipCollection {
          id
          name
          active
        }
        product {
          id
          code
          color
          stock
          imageThumbMedium
          imageThumbLarge
          imageForProduction
          pattern {
            name
          }
        }
      }
    }
  }
}
`