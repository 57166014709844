import { useQuery } from "react-apollo";
import { useState, useEffect } from "react";
import { certificatesPlantsQuery } from "../query";

const useGetCertificatesPlants = ( slug ) => {
  const [certificatesPlants, setCertificatesPlants] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  
  const { data } = useQuery(certificatesPlantsQuery, {
    variables: {
      domainsUrl: currentLocation || '',
      slug: slug
    }
  })

  useEffect(() => {
    if (data) setCertificatesPlants(data.certificates.edges); 
  }, [data])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentLocation(`${window?.location?.origin}`);
    }
  }, []);

  return { certificatesPlants }
}

export default useGetCertificatesPlants;