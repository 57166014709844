import gql from 'graphql-tag';

const singleUserFragment = gql`
    fragment UserFragment on UserNode {
        id
        email
        password
        fullName
        firstName
        lastName
        profile {
            phone
            company
            city {
                id
                name
                region {
                    id
                    name
                }
                country {
                    id
                    name
                }
            }
            address
            zipCode
            companyType {
                id
                name
            }
            position
        }
    }
`;

export default singleUserFragment;