import gql from "graphql-tag";

const cartQuery = gql`
  query Cart($id: ID, $instanceId: ID!) {
    cart(id: $id, instanceId: $instanceId) {
      id
      instance {
        id
        name
      }
      items {
        edges {
          node {
            id
            quantity
            product {
              id
              color
              stock
              imageThumbSmall
              pattern {
                id
                name
              }
            }
            pattern {
              id
              name
              catalogStockNumber
            }
          }
        }
      }
    }
  }
`;

export default cartQuery;
