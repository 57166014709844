import classNames from "classnames/bind";
import meQuery from "utils/queries/MeQuery";
import {Query} from "react-apollo";
import UserProfilePic from "utils/components/UserProfilePic";
import LoadingMessage from "utils/components/LoadingMessage";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {RECORDS} from "routes";
import {QUERY_PARAM_RECORD_LIST, SAMPLES_LIST} from "modules/records/RecordsPage/constants";
import { isMARINE, getTranslatedLink } from "services/instances";
import "./styles/AuthBoxUserCard.scss";

export default function AuthBoxUserCard() {
  return (
      <Query query={meQuery}>
        {({data}) => {

          if (data) {
            const {me} = data;
            return (
                <div className="auth-box-user-card clearfix">
                  <div className="auth-box-user-card__profile-pic">
                    <UserProfilePic size="medium" isMarineReverse={isMARINE()}/>
                  </div>
                  <div className="auth-box-user-card__user-info">
                    <h3 className="mb-0">{me.fullName}</h3>
                    <p className="mb-1">{me.email}</p>
                    <Link
                        to={`${getTranslatedLink(RECORDS, {})}?${QUERY_PARAM_RECORD_LIST}=${SAMPLES_LIST}`}
                        className={classNames({
                          "btn btn-secondary btn-sm": true,
                          "btn-prussian-blue": isMARINE()
                        })}>
                      <FormattedMessage
                          id="AuthBoxUserCard.seeProfile"
                          description="See profile link text in auth box user card"
                          defaultMessage="Ver perfil"/>
                    </Link>
                  </div>
                </div>
            )
          }

          return <LoadingMessage/>
        }}
      </Query>
  );
}