import {FormattedMessage} from "react-intl"
import coinIcon from "./img/coin.svg"
import clockIcon from "./img/clock.svg"
import "./styles/RequestSamplesDisclaimer.scss"
import {isMARINE, isUSA} from "services/instances"

export default function RequestSamplesDisclaimer() {
  return (
      <div className="request-samples-disclaimer p-4">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="d-flex justify-content-around">
              <div>
                <div className="d-flex flex-row align-items-center">
                  <img src={coinIcon} alt=""/>
                  <p className="mb-0 pl-3 gray text-center">
                    <FormattedMessage
                        id="RequestSampleModal.free"
                        description="Free in PatternActionsModal"
                        defaultMessage="Gratis"/>
                  </p>
                </div>
              </div>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <img src={clockIcon} alt=""/>
                  <p className="mb-0 pl-3 gray text-center">
                    <FormattedMessage
                        id="RequestSampleModal.deliveryInfoV2"
                        description="Delivery info in PatternActionsModal"
                        defaultMessage="Envío entre<br></br>7-10 días"
                        values={{
                          br: () => <br/>
                        }}/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center mt-3 mb-0">
          {
            isUSA() || isMARINE()
                ?
                <FormattedMessage
                    id="RequestSampleModal.sampleRequestDisclaimerUSA"
                    description="Sample request disclaimer text before colors selector in PatternActionsModal component"
                    defaultMessage="Nuestras muestras se envían a través de USPS. Para solicitar un método de envío diferente, comuníquese con samples@spradling.group"/>
                :
                <FormattedMessage
                    id="RequestSampleModal.sampleRequestDisclaimer"
                    description="Sample request disclaimer text before colors selector in PatternActionsModal component"
                    defaultMessage="Los tamaños de muestra pueden variar entre DinA4 y DinA5. Si necesita un tamaño específico, no dude en contactarse con nosotros."/>
          }
        </p>
      </div>
  )
}