import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { CIndex1 } from "./components/CIndex1";
import { CIndex2 } from "./components/CIndex2";
import { CIndex3 } from "./components/CIndex3";
import { CIndex4 } from "./components/Cindex4";
import "./styles/DotsMobileStepper.scss";

const DotsMobileStepper = () => {
  const [index, setIndex] = useState(0);

  const componentes = [
    <CIndex1 key="c_i1" />,
    <CIndex2 key="c_i3" />,
    <CIndex4 key="c_i2" />,
    <CIndex3 key="c_i4" />,
  ];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div
      className="container-fluid evolving-carousel"
      style={{ margin: 0, padding: 0 }}
    >
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        touch
        dragging
        nextLabel=""
        prevLabel=""
        className="custom-indicators"
        interval={4000}
        fade
      >
        {componentes.map((componente, idx) => (
          <Carousel.Item key={idx} indicatorLabels={"........"} control={false}>
            <div
              style={{
                position: "relative",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {componente}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default DotsMobileStepper;
