import gql from "graphql-tag";

const marketsSimpleQuery = gql`
  query Markets($instanceIds: [ID]!, $saleable: Boolean) {
    markets(instances: $instanceIds) {
      edges {
        node {
          id
          slug
          name
          description
          imageThumbMedium
          sectors {
            edges {
              node {
                id
                name
                slug
                patternSet(
                  products_Instances: $instanceIds
                  saleable: $saleable
                ) {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default marketsSimpleQuery;
