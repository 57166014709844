import PropTypes from "prop-types";
import "./styles/SocialShareNav.scss";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share";

export default function SocialShareNav({title, hideTitle = false}) {
  const {location} = window;
  const shareUrl = `${location.protocol}//${location.hostname}${location.pathname}`;

  return (
      <ul className="social-share-nav nav justify-content-end">
        {
          !hideTitle &&
          <li className="nav-item social-share-nav__title">
            <span className="nav-link">
              Compartir
            </span>
          </li>
        }
        <li className="nav-item">
          <FacebookShareButton
              quote={title}
              url={shareUrl}>
            <span className="nav-link">
              <i className="fa fa-facebook"/>
            </span>
          </FacebookShareButton>
        </li>
        <li className="nav-item">
          <TwitterShareButton
              title={title}
              url={shareUrl}>
            <span className="nav-link">
              <i className="fa fa-twitter"/>
            </span>
          </TwitterShareButton>
        </li>
        <li className="nav-item">
          <LinkedinShareButton url={shareUrl}>
            <span className="nav-link">
              <i className="fa fa-linkedin"/>
            </span>
          </LinkedinShareButton>
        </li>
      </ul>
  )
}

SocialShareNav.propTypes = {
  title: PropTypes.string.isRequired,
  hideTitle: PropTypes.bool
};