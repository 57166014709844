import {connect} from "react-redux";
import HeaderBurger from "../index";
import {openMobileMenu, closeMobileMenu} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMobileMenu: () => dispatch(openMobileMenu()),
    closeMobileMenu: () => dispatch(closeMobileMenu())
  };
};

const HeaderBurgerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBurger);

export default HeaderBurgerContainer;