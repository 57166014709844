import HeaderQuickShip from './components/Header'
import QuickshipBanner from './components/QuickshipBanner'
import HomeProductsSection from './components/HomeProductsSection'
import ScrollTopOnMount from 'utils/components/ScrollTopOnMount'
// import { FooterQuickShip } from './components/Footer/FooterQuickShip'


const QuickshipPage = () => {
  return (
    <div className='position-relative'>
      <ScrollTopOnMount />
      <HeaderQuickShip />
      <QuickshipBanner />
      <HomeProductsSection />
      {/* <FooterQuickShip /> */}
    </div>
  )
}

export default QuickshipPage;

