import classNames from "classnames";
import flatpagesQuery from "components/Footer/queries";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { getCurrentInstanceId } from "services/instances";
import useToggle from "utils/hooks/useToggable";
import FlatPageInfo from "./components/FlatPageinfo";
import "./styles/PrivacyPolicyCheckbox.scss";

export default function PrivacyPolicyCheckbox({ onChange, children }) {
  const [privatePolicy, setPrivatePolicy] = useState()
  const [acceptPolicy, setAcceptPolicy] = useState(false)

  const [open, setOpen] = useToggle();

  const handleOnchange = (acceptTerms = false) => {
    if (acceptTerms) {
      setAcceptPolicy(true)
    }
    
    onChange && onChange(true);
    setOpen()
  };

  const { data } = useQuery(flatpagesQuery, {
    variables: {
      instanceIds: getCurrentInstanceId()
    }
  });

  useEffect(() => {
    if (data) {
      const { flatpages } = data;

      // TODO look for a better way to get privacy policy link here, because that's going to work only for Spanish and English
      const privacyPolicyEdge = _.find(
        flatpages.edges,
        (edge) => edge.node.slug.indexOf("privac") > -1
      );

      setPrivatePolicy(privacyPolicyEdge);
    }
  }, [data, privatePolicy])
  

  return (
    <div className="privacy-policy-checkbox form-group form-check">
      <input
        className={classNames({
          "step-form--field-check--controlled": true,
          "step-form--field-check--controlled-checked": acceptPolicy
        })}
        id="privacy-policy-checkbox"
        onClick={setOpen}
        type="checkbox"
      />
      
      <label className="form-check-label ml-1" htmlFor="privacy-policy-checkbox">
        <FormattedMessage
          id="PrivacyPolicyCheckbox.privacyPolicyDisclaimer"
          description="Privacy policy disclaimer in PrivacyPolicyCheckbox"
          defaultMessage="He leído y acepto la"
        />
        <strong className="underlined-private-policy ml-1">
          {privatePolicy?.node.title}
        </strong>
      </label>

      <FlatPageInfo
        isOpen={open}
        slug={privatePolicy?.node.slug}
        handleAcceptTNC={handleOnchange}
      />

      {children}
    </div>
  );
}

PrivacyPolicyCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element,
};
