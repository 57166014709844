import { defineMessages } from "react-intl";

export const cityFieldPlaceholders = defineMessages({
  withoutCountry: {
    id: "CityField.withoutCountryV2",
    description:
      "Placeholder for autocomplete select when there's no country selected for CityField component",
    defaultMessage: "Primero selecciona una región",
  },
  withoutCountryV2: {
    id: "CityField.withoutCountryV3",
    description: "Default of Country field of the Contact form",
    defaultMessage: "Selecciona un pais",
  },
  withoutCountryMX: {
    id: "CityField.withoutCountryMX",
    description:
      "Placeholder for autocomplete select when there's no country selected for CityField component",
    defaultMessage: "Primero selecciona un estado",
  },
  withCountry: {
    id: "CityField.withCountry",
    description:
      "Placeholder for autocomplete select when there's a country selected for CityField component",
    defaultMessage: "Escribe tu ciudad",
  },
  withCountryMX: {
    id: "CityField.withCountryMX",
    description:
      "Placeholder for autocomplete select when there's a country selected for CityField component",
    defaultMessage: "Escribe tu delegación",
  },
});