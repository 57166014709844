import gql from "graphql-tag";

const storesByPatternQuery = gql`
  query StoresByPatternQuery(
    $patternIds: [ID]
    $instanceId: ID
    $active: Boolean
  ) {
    stores(patterns: $patternIds, instance: $instanceId, active: $active) {
      edges {
        node {
          id
          name
          address
          phoneNumbers
          email
          latitude
          longitude
          website
          active
          city {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default storesByPatternQuery;
