import _ from "lodash";

/**
 * This functions transforms the given value into an array.
 * If the value is undefined an empty array will be returned.
 * @param value
 * @returns {*[]|*}
 */
export default function arrayify(value) {
  if (!value)
    return [];

  if (_.isArray(value))
    return value;

  return [value];
}