import gql from "graphql-tag";

const singlePatternPDFQuery = gql`
    query SinglePatternPDFQuery($id: ID!, $unitSystem: Int) {
        pattern (id: $id) {
            id
            pdfUrl (fullVersion: false, unitSystem: $unitSystem)
        }
    }
`;

export default singlePatternPDFQuery;