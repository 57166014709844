import {connect} from "react-redux";
import StoreFinder from "../index";
import {setPatternId} from "modules/stores/WhereToBuyPage/actions"

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPatternId: patternId => dispatch(setPatternId(patternId))
  };
};

const StoreFinderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreFinder);

export default StoreFinderContainer;