import { lazy, Suspense } from 'react';
import {Query} from "react-apollo";
import StoreInfoWindowContent
  from "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoresGoogleMap/components/StoreInfoWindowContent";
import officesQuery from "modules/about/SpradlingInTheWorldPage/components/OfficesList/query";
import LoadingMessage from "utils/components/LoadingMessage"

const AsyncGoogleMaps = lazy(() => import("utils/components/AsyncGoogleMaps"))

// This map styles has been created in https://mapstyle.withgoogle.com/
const mapStyles = [
  {
    "featureType": "landscape",
    "stylers": [
      {
        "color": "#f5f5f2"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#c8eced"
      }
    ]
  }
];

// TODO this query is being executed 2 times (map and list), let's fix that

const OfficesMap = () => (
  <div className="offices">
    <div className="offices-map">
      <Query
          query={officesQuery}>
        {({data}) => {

          if (data) {
            const {offices} = data;

            return (
                <Suspense fallback={<div/>}>
                  <AsyncGoogleMaps
                      center={{
                        lat: 34.555941,
                        lng: -41.064619
                      }}
                      zoom={2}
                      options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        styles: mapStyles
                      }}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
                      loadingElement={
                        <span>loading</span>
                      }
                      containerElement={
                        <div id="map"/>
                      }
                      mapElement={
                        <div style={{height: `500px`, width: `100%`}}/>
                      }
                      locations={
                        offices.edges.map(office => ({
                          id: office.node.id,
                          position: {
                            lat: office.node.latitude,
                            lng: office.node.longitude
                          },
                          infoWindow:
                            <StoreInfoWindowContent
                                title={office.node.name}
                                address={office.node.address}
                                phone={office.node.phone}
                                email={office.node.email}/>
                        }))
                      }
                      fitCenter={false}/>
                </Suspense>
            )
          }

          return <LoadingMessage/>;
        }}
      </Query>
    </div>
  </div>
);

export default OfficesMap;
