import { FormattedMessage } from "react-intl";
// direct impacts
// FOR EN
import a from "../utils/sdg/direct/en/a.png";
import b from "../utils/sdg/direct/en/b.png";
import c from "../utils/sdg/direct/en/c.png";
import d from "../utils/sdg/direct/en/d.png";
import e from "../utils/sdg/direct/en/e.png";
import f from "../utils/sdg/direct/en/f.png";
import g from "../utils/sdg/direct/en/g.png";
import h from "../utils/sdg/direct/en/h.png";
// FOR ES
import goal03 from "../utils/sdg/direct/es/S-WEB-Goal-03.png";
import goal04 from "../utils/sdg/direct/es/S-WEB-Goal-04.png";
import goal06 from "../utils/sdg/direct/es/S-WEB-Goal-06.png";
import goal07 from "../utils/sdg/direct/es/S-WEB-Goal-07.png";
import goal08 from "../utils/sdg/direct/es/S-WEB-Goal-08.png";
import goal09 from "../utils/sdg/direct/es/S-WEB-Goal-09.png";
import goal12 from "../utils/sdg/direct/es/S-WEB-Goal-12.png";
import goal13 from "../utils/sdg/direct/es/S-WEB-Goal-13.png";
// indirect impacts
// FOR EN
import i from "../utils/sdg/indirect/en/i.png";
import j from "../utils/sdg/indirect/en/j.png";
import k from "../utils/sdg/indirect/en/k.png";
// FOR ES
import goal01 from "../utils/sdg/indirect/es/S-WEB-Goal-01.png";
import goal02 from "../utils/sdg/indirect/es/S-WEB-Goal-02.png";
import goal15 from "../utils/sdg/indirect/es/S-WEB-Goal-15.png";

import { isLangEs } from "services/instances";

const DIRECT_SDG_EN = [a, b, c, d, e, f, g, h];
const DIRECT_SDG_ES = [
  goal03,
  goal04,
  goal06,
  goal07,
  goal08,
  goal09,
  goal12,
  goal13,
];

const INDIRECT_SDG_EN = [i, j, k];
const INDIRECT_SDG_ES = [goal01, goal02, goal15];

const DIRECT_SDG = (isEs) => (!isEs ? DIRECT_SDG_EN : DIRECT_SDG_ES);
const INDIRECT_SDG = (isEs) => (!isEs ? INDIRECT_SDG_EN : INDIRECT_SDG_ES);

export const Cards = () => {
  return (
    <div className="container-fluid">
      <div
        className="row d-flex justify-content-center g-5"
        style={{ marginBottom: "2vh" }}
      >
        <div className="col-lg-9 col-md-10 col-sm-10">
          <div className="row d-flex justify-content-between px-3">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12 text-center mt-2 mb-3">
                  <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                    <FormattedMessage
                      id="EcosensePage.v2.Embracing.SDG.direct"
                      defaultMessage="SDG with direct impacts"
                    />
                  </p>
                </div>
              </div>
              <div className="row">
                {DIRECT_SDG(isLangEs).map((image, idx) => (
                  <div
                    key={idx}
                    className="col-lg-3 col-md-4 col-sm-4 col-4 mb-4"
                  >
                    <img
                      src={image}
                      alt={`Imagen ${idx + 1}`}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-5">
              <div className="row">
                <div className="col-12 text-center mt-2 mb-3">
                  <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                    <FormattedMessage
                      id="EcosensePage.v2.Embracing.SDG.indirect"
                      defaultMessage="SDG with indirect impacts"
                    />
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                {INDIRECT_SDG(isLangEs).map((image, idx) => (
                  <div
                    key={idx}
                    className="col-lg-3 col-md-4 col-sm-4 col-4 mb-4"
                  >
                    <img
                      src={image}
                      alt={`Imagen ${idx + 1}`}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
