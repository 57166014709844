import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import "./styles/TagInstanceContent.scss";

const TagInstanceContent = ({children, className}) => {
  return (
    <span
      className={classNames({
        "tag-instance-content": true,
        [className]: className !== undefined
      })}>
      {children}
    </span>
  );
};

TagInstanceContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string
};

export default TagInstanceContent;