import PostsList from "modules/blog/PostsList";
import {FormattedMessage} from "react-intl";

const PostsListPage = () => (
  <div className="posts-list-page container mt-5">
    <div className="row p-3">
      <h1>
        <FormattedMessage
          id="PostsListPage.title"
          description="Title of the blog list page"
          defaultMessage="Noticias"/>
      </h1>
    </div>
    <PostsList/>
  </div>
)

export default PostsListPage