import PropTypes from "prop-types";
import downloadIcon from "./img/download-file-icon.svg";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import { handleDownloadFile } from "./utils/downloadPDF";
import { AVAILABLES_PDF } from "./constants/availablesPDF";

const DownloadCharacteristicPdfBtn = ({ name, className }) => {
  const isDesktop = useMediaQuery(LG);

  function handleDownloadPDF(e) {
    if (!isDesktop) {
      e.prevenDefault();
      handleDownloadFile(name);
    }
  }

  return (
    <a
      href={!isDesktop ? "/#" : AVAILABLES_PDF[name].pdfUrl}
      rel="noopener noreferrer"
      download={!isDesktop}
      target={isDesktop ? "_blank" : "_self"}
      className={`characteristic-detail--button btn col-12 col-xl-9 text-white cursor-pointer ${className}`}
      onClick={handleDownloadPDF}
    >
      {!isDesktop && <img src={downloadIcon} alt="download-pdf-icon" className="mr-3" width={18} height={24} />}
      {name}
    </a>
  )
}

DownloadCharacteristicPdfBtn.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
}

export default DownloadCharacteristicPdfBtn;