import { COLOMBIA_PLANT, COSTA_RICA_PLANT } from "../../Banner/constants/bannerItems"

/**
 * 
 * @param {string} currentPlant - Is the currentPlant section page thah is open
 * @param {Boolean} isMobile - Variable that allows us to know the resolution of the screen 
 * @param {Number} currentIndex - Is the current Index from map
 * @returns {string} Return the number of width with percentage
 */
export const dynamicWidthAccordingCurrentPlant = (currentPlant, isMobile, currentIndex) => {
  const dynamicWidthByPlant = {
    [COLOMBIA_PLANT]: {
      widthRow: currentIndex > 3 ? "15%" : "25%"
    },
    [COSTA_RICA_PLANT]: {
      widthRow: currentIndex > 4 ? "15%" : "20%"
    }
  }

  const resultWidth = !isMobile ? dynamicWidthByPlant[currentPlant].widthRow : "33.3%"

  return resultWidth
}