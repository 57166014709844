import {connect} from "react-redux";
import HeaderDropdown from "../index";
import {closeMobileMenu} from "components/Header/actions";

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMobileMenu: () => dispatch(closeMobileMenu())
  };
};

const HeaderDropdownContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderDropdown);

export default HeaderDropdownContainer;