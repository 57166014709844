import PropTypes from "prop-types";
import { useState } from "react";
import { CHARACTERISTIC_GENERAL_INFO } from "../../../ProseatCharacteristics/constants/characteristicsInfo";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import classNames from "classnames";

const HotspotPoint = ({ 
  pointNumber,
  handleChangeAndShowCharacteristicInfo
}) => {
  const isDesktop = useMediaQuery(LG);
  const [showCurrentCharacteristcText, setShowCurrentCharacteristicText] = useState(false);
  const currentCharacteristic = CHARACTERISTIC_GENERAL_INFO[`characteristic_${pointNumber}`]

  const handleShowCurrentCharacteristicText = (show) => setShowCurrentCharacteristicText(show);

  return (
    <>
      <span
        className={classNames({
          "hotspot-point d-flex align-items-center justify-content-center rounded-circle": true,
          "hotspot-point-overlap": pointNumber === 7
        })}
        onClick={() => handleChangeAndShowCharacteristicInfo(`characteristic_${pointNumber}`)}
        onMouseOver={() => handleShowCurrentCharacteristicText(true)}
        onMouseLeave={() => handleShowCurrentCharacteristicText(false)}
      >
        {pointNumber}
      </span>
      {isDesktop && showCurrentCharacteristcText && 
        <p className={classNames({
          "hotspot-point-text": true,
          "hotspot-point-text-overlap": pointNumber === 7
          })}>
          {currentCharacteristic?.title ?? ""}
        </p>
      }
    </>
  )
}

HotspotPoint.propTypes = {
  pointNumber: PropTypes.number,
  handleChangeAndShowCharacteristicInfo: PropTypes.func
}

export default HotspotPoint;