import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { filteredProductsQuickshipPage } from "../queries";

const useGetFilteredProductQuickshipPage = (idCollection, sectorIn, designIn) => {
  const { data, loading, error } = useQuery(filteredProductsQuickshipPage, {
    variables: { 
      quickshipCollection: idCollection || '',
      sectorIn: sectorIn || [],
      designIn: designIn || []
    },
  });

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (data && data.quickshipProducts) {
      setFilteredProducts(data.quickshipProducts.edges || []);
    }
  }, [data]);

  return { filteredProducts, loading, error };
};

export default useGetFilteredProductQuickshipPage;




