import {connect} from "react-redux";
import DownloadPatternPDFButton from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const DownloadPatternPDFButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadPatternPDFButton);

export default DownloadPatternPDFButtonContainer;