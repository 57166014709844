import PropTypes from 'prop-types';
import {Collapse} from "reactstrap";
import arrowDownIcon from "../PurposeGroupAccordion/img/Vector.svg";
import classNames from 'classnames';
import "./styles/HigherPurposeAccordion.scss";

export default function CategoryPurposeAccordion({
  children, 
  title, 
  imgIcon,
  isOpen,
  parentAccordionTitle,
  handleClosePurposesGroup
}) {

  function handleClick() {
    handleClosePurposesGroup(parentAccordionTitle, title, true);
  }

  return (
      <div className="product-specs-accordion">
        <div className={classNames({
          'd-flex align-items-center higher-purpose--container-specs': true,
          'higher-purpose--container-specs-item': true
          })}>
          <img className='higher-purpose--icon-item' src={imgIcon}  alt='icon-accordion' />
          <h4
            className={classNames({
              'px-0 text-white w-100': true,
              'product-specs-accordion__title-higher-purpose-item pl-3 d-flex justify-content-between': true,
            })}
            onClick={handleClick}
          >
            {title}
            {isOpen 
              ? <i className="sp-minus fs-sp-minus" />  
              : <img src={arrowDownIcon} alt='arrow-down-icon-img' />
            }
          </h4>
        </div>
        <Collapse
            className="product-specs-accordion__collapse-higher-purpose"
            isOpen={isOpen}>
          <table className="table table-striped product-specs-table">
            <tbody>
              {children}
            </tbody>
          </table>
        </Collapse>
      </div>
  )
}

CategoryPurposeAccordion.propTypes = {
  title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]).isRequired,
  children: PropTypes.element,
  isOpen: PropTypes.bool,
  imgIcon: PropTypes.string,
  parentAccordionTitle: PropTypes.string,
  handleClosePurposesGroup: PropTypes.func
};