import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import DisclaimerAccordion from "modules/products/ProductDetailsPage/components/ProductSpecs/components/DisclaimerAccordion";
import DisclaimerText from "utils/components/DisclaimerText";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import RelatedBrandCell from "./components/RelatedBrandCell";
import SpecsTableAccordion from "utils/components/SpecsTableAccordion";
import { handleRenderValueOtherAttributes } from "./utils";
import "./styles/ProductSpecs.scss";
import classNames from "classnames/bind";
import FlameRetardancyAccordion from "./components/FlameRetardancyAccordion";
import SustainabilityAttributesAccordion from "./components/SustainabilityAttributesAccordion";
import { isMARINE, isRESOURCES, isUSA } from "services/instances";

export default function ProductSpecs() {
  const pattern = useContext(PatternContext);
  const isSustainabilityDataToAccordion = pattern.sustainability
  const isInstanceOfUsa = isUSA() || isRESOURCES() || isMARINE()

  if (isSustainabilityDataToAccordion === null) {
    pattern.sustainability = null
  }

  if (!pattern)
    return (
      <div className="product-specs">
        <div className="product-specs__tables">
          {new Array(5).fill().map((item, index) => (
            <div key={index} className="mb-3">
              <SpecsTableAccordion
                openByDefault
                title={<Skeleton width={100} />}
              ></SpecsTableAccordion>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="product-specs">
      <div className="product-specs__tables">
        {pattern.specsSheetData.general.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              openByDefault
              title={
                <FormattedMessage
                  id="ProductSpecs.generalTableTitle"
                  description="General table title at product specs section"
                  defaultMessage="General"
                />
              }
            >
              {pattern.specsSheetData.general.map((obj, index) => (
                <>
                  {!obj.value?.hideOnWebsite && (
                    <tr key={index}>
                      <td
                        className={classNames({
                          "align-middle": obj.value?.slug,
                        })}
                      >
                        {obj.message}
                      </td>
                      <td>
                        {obj.value?.slug ? (
                          !obj.value?.hideOnWebsite && (
                            <RelatedBrandCell brand={obj.value} />
                          )
                        ) : obj.value.indexOf(".svg") > -1 ? (
                          <img src={obj.value} alt={obj.message} height={32} />
                        ) : (
                          obj.value
                        )}
                      </td>
                    </tr>
                  )}
                </>
              ))}
              {pattern.productcategorization &&
                pattern.productcategorization.design && (
                  <tr>
                    <td>
                      <FormattedMessage
                        id="ProductHeader.design"
                        description="Design title in product header component"
                        defaultMessage="Design"
                      />
                    </td>
                    <td>{pattern.productcategorization.design.join(", ")}</td>
                  </tr>
                )}
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.sustainability && isInstanceOfUsa && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.productSustainabilityTitle"
                  description="Product Sustainability table title at product specs section"
                  defaultMessage="Sustainability Profile"
                />
              }
            >
              <SustainabilityAttributesAccordion
                patternData={pattern}
                patternId={pattern.id}
              />
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.specsSheetData.backing.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.backingTableTitle"
                  description="Backing table title at product specs section"
                  defaultMessage="Backing"
                />
              }
            >
              {pattern.specsSheetData.backing.map((obj, index) => (
                <tr key={index}>
                  <td
                    className={classNames({
                      "align-middle": obj.value?.slug,
                    })}
                  >
                    {obj.message}
                  </td>
                  <td>
                    {obj.value?.slug ? (
                      <RelatedBrandCell brand={obj.value} />
                    ) : (
                      obj.value
                    )}
                  </td>
                </tr>
              ))}
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.specsSheetData.flame_retardancies.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.flameRetardancyTableTitle"
                  description="Flame Retardancy table title at product specs section"
                  defaultMessage="Flame Retardancy"
                />
              }
              disclaimer={
                <FormattedMessage
                  id="ProductSpecs.flameRetardancyDisclaimer"
                  description="Disclaimer below flame retardancy tests table"
                  defaultMessage="This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
                >
                  {(disclaimer) => <DisclaimerText text={disclaimer} />}
                </FormattedMessage>
              }
            >
              <FlameRetardancyAccordion
                data={pattern.specsSheetData.flame_retardancies}
              />
              {/* {pattern.specsSheetData.flame_retardancies.map((obj, index) => (
              ))} */}
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.specsSheetData.other_attributes.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.otherAttributesTableTitle"
                  description="Other Attributes table title at product specs section"
                  defaultMessage="Other Attributes"
                />
              }
            >
              {pattern.specsSheetData.other_attributes.map((obj, index) => (
                <tr key={index}>{handleRenderValueOtherAttributes(obj)}</tr>
              ))}
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.specsSheetData.additional_tests.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.additionalTests"
                  description="Additional Tests table title at product specs section"
                  defaultMessage="Additional Tests"
                />
              }
            >
              {/* TODO fix this problem: table cannot appear as descendant of tbody */}
              <>
                {pattern.specsSheetData.additional_tests.map(
                  ({ data: test }, testIndex) => (
                    <table
                      key={testIndex}
                      className="table table-striped mt-0 mb-5"
                    >
                      <tbody>
                        {test.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((col, colIndex) => {
                              if (rowIndex === 0)
                                return (
                                  <th
                                    key={colIndex}
                                    colSpan={test[1] ? test[1].length : 1}
                                  >
                                    {col}
                                  </th>
                                );
                              else if (colIndex === 0)
                                if (
                                  test[1] !== undefined &&
                                  test[2] !== undefined &&
                                  test[1][0] === test[2][0]
                                )
                                  if (rowIndex + 1 !== test.length)
                                    return (
                                      <td
                                        key={colIndex}
                                        width="20%"
                                        rowSpan="2"
                                      >
                                        {col}
                                      </td>
                                    );
                                  else return null;
                                else
                                  return (
                                    <td key={colIndex} width="20%">
                                      {col}
                                    </td>
                                  );
                              else
                                return (
                                  <td
                                    key={colIndex}
                                    width={row.length === 2 ? "80%" : "40%"}
                                  >
                                    {col}
                                  </td>
                                );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                )}
              </>
            </SpecsTableAccordion>
          </div>
        )}
        {pattern.specsSheetData.approved_cleaners.length > 0 && (
          <div className="mb-3">
            <SpecsTableAccordion
              title={
                <FormattedMessage
                  id="ProductSpecs.approvedCleaners"
                  description="Approved Cleanesr table title at product specs section"
                  defaultMessage="Approved Cleaners"
                />
              }
            >
              {pattern.specsSheetData.approved_cleaners.map(
                ({ value: approvedCleaners }) => (
                  <>
                    {approvedCleaners.map(
                      ({ id, cleaner, cleaners_manufacturer }) => (
                        <tr key={`key_${id}`}>
                          <td colSpan={2}>{cleaner}</td>
                          <td colSpan={2}>{cleaners_manufacturer}</td>
                        </tr>
                      )
                    )}
                  </>
                )
              )}
            </SpecsTableAccordion>
          </div>
        )}
        <DisclaimerAccordion />
      </div>
    </div>
  );
}
