import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { getGlossaryTypes } from '../queries';

const useGenericGlossary = () => {
  const [genericGlossary, setGenericGlossary] = useState([]);
  const { data, loading: loadingGenericGlossary } = useQuery(getGlossaryTypes);

  useEffect(() => {
    if (data) {
      const genericGlossaryType = data.glossaryTypes.edges[0].node

      setGenericGlossary(genericGlossaryType);
    }
  }, [data]);

  return { genericGlossary, loadingGenericGlossary };
};

export default useGenericGlossary;