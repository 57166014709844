import ProjectCard from "modules/projects/ProjectsListPage/components/ProjectCard";
import { Query } from "react-apollo";
import { useLocation } from "react-router-dom";
import projectsQuery from "modules/projects/ProjectsListPage/query";
import { getCurrentInstanceId, isMARINE } from "services/instances";
import HeroTitle from "utils/components/HeroTitle";
import { FormattedMessage } from "react-intl";
import SubHeroContent from "utils/components/SubHeroContent";
import heroBackground from "./img/hero-background-v2.jpg";
import { Element } from "react-scroll";
import Paginator from "utils/components/Paginator";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import QueryParams from "services/browser-history/QueryParams";
import LoadingMessage from "utils/components/LoadingMessage";
import SEO from "services/seo/components/SEO";
import { PROJECTS_SCENE } from "services/seo/constants/scenes";

const ProjectsListPage = () => {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const pageSize = 12;

  const queryVariables = {
    ...queryParams.paginationGraphQLVariables(pageSize),
    instanceIds: [getCurrentInstanceId()],
  };

  return (
    <div className="projects-list-page">
      <ScrollTopOnMount />
      <HeroTitle
        title={
          <FormattedMessage
            id="ProjectsListPage.title"
            description="Title of the projects list page"
            defaultMessage="Conoce nuestras creaciones"
          />
        }
        backgroundImage={heroBackground}
      />
      <SubHeroContent
        theme={!isMARINE() ? "green" : "prussian-blue"}
        className="mb-4"
      >
        <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <p className="text-center mb-0">
                <FormattedMessage
                  id="ProjectsListPage.subHeroContent"
                  description="Content of the projects list page sub hero"
                  defaultMessage="Nuestros diseños han sido la inspiración de grandes creaciones. Hoy en día estamos presentes con nuestros productos en más de 75 países alrededor del mundo en espacios como estadios, hoteles, auditorios; entre otros."
                />
              </p>
              <p className="text-center mb-0">
                <strong>
                  <FormattedMessage
                    id="ProjectsListPage.letUsInspireYou"
                    description="Let us inspire you text in the sub hero"
                    defaultMessage="Déjanos inspirarte..."
                  />
                </strong>
              </p>
            </div>
          </div>
        </div>
      </SubHeroContent>
      <div className="container">
        <div className="row justify-content-end mb-2">
          <div className="col-auto">
            {/*<ul className="nav">
                <li className="nav-item">
                  <a className="nav-link colored-link gray pl-0 pr-2" href="/#">
                    <FormattedMessage
                        id="ProjectsListPage.seeAllFilter"
                        description="See all filter label in projects list page"
                        defaultMessage="Ver todos"/>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link colored-link gray pl-2 pr-2" href="/#">
                    <FormattedMessage
                        id="ProjectsListPage.instanceFilter"
                        description="Instance filter label in projects list page"
                        defaultMessage="Instancia"/>
                    &nbsp;
                    <i className="sp-strong-chevron-down"/>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link colored-link gray pl-2 pr-0" href="/#">
                    <FormattedMessage
                        id="ProjectsListPage.segmentFilter"
                        description="Segment filter label in projects list page"
                        defaultMessage="Segmento"/>
                    &nbsp;
                    <i className="sp-strong-chevron-down"/>
                  </a>
                </li>
              </ul>*/}
          </div>
        </div>
        <Element name="projectListElement">
          <Query
            query={projectsQuery}
            variables={{
              ...queryVariables,
            }}
          >
            {({ loading, data }) => {
              if (loading)
                return (
                  <div className="row">
                    {[1, 2, 3].map((number) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 mb-4"
                        key={number}
                      >
                        <ProjectCard />
                      </div>
                    ))}
                  </div>
                );

              if (data) {
                const { projects } = data;

                return (
                  <>
                    <div className="row">
                      {projects.edges.map((item, index) => (
                        <div
                          className="col-12 col-sm-6 col-md-4 mb-4"
                          key={index}
                        >
                          <ProjectCard project={item.node} />
                        </div>
                      ))}
                    </div>
                    <Paginator
                      elementId={"projectListElement"}
                      pageSize={pageSize}
                      pageInfo={projects.pageInfo}
                      totalCount={projects.totalCount}
                    />
                  </>
                );
              }

              return <LoadingMessage />;
            }}
          </Query>
        </Element>
      </div>
      <SEO scene={PROJECTS_SCENE} />
    </div>
  );
};

export default ProjectsListPage;
