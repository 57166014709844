import gql from "graphql-tag";

const documentCategoriesQuery = gql`
  query DocumentCategoriesQuery($instances: [ID]!) {
    documentCategories {
      edges {
        node {
          id
          name
          slug
          listingImageThumbSmall
          description
          documents(instances: $instances) {
            totalCount
          }
        }
      }
    }
  }
`;

export default documentCategoriesQuery;