import { FormattedMessage } from "react-intl";
import panel from "../../../utils/panel.jpeg";

export const CIndex2 = () => {
  return (
    <div className="container-fluid upper-slider-container position-relative">
      <div className="slider-2-bg-container">
        <div className="slider2-bg-svg" />
      </div>
      <div className="row d-flex justify-content-center position-relative h-100">
        <div className="col-lg-9 col-md-10 col-sm-10">
          <div className="row d-flex justify-content-between px-3 slide-height align-items-center h-100">
            <div className="col-lg-6 col-sm-12">
              <div
                className="row d-flex justify-content-center mt-4"
                style={{ zIndex: 999 }}
              >
                <div className="col-12 col-lg-12 col-xxl-10 col-md-10 mt-2 g-2 text-center px-5 px-lg-0 px-xl-5">
                  <span className="slider1-title1 mr-2">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.two.title.1"
                      defaultMessage="RENEWABLE "
                    />
                  </span>
                  <span className="slider1-title2">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.two.title.2"
                      defaultMessage="ENERGY MANAGEMENT"
                    />
                  </span>
                </div>
                <div className="col-sm-12 pb-0 mt-3">
                  <div className="row">
                    <div className="col-sm-12 slider2-desc">
                      <span>
                        <FormattedMessage
                          id="EcosensePage.v2.Evolving.Carousel.two.description.1"
                          defaultMessage="At our manufacturing facilities and select global
                      offices worldwide, we’ve implemented a sustainable
                      solution with over <strong>800</strong> solar panels. Paired with efficient battery storage,
                      this not only harnesses surplus energy but also reduces
                      our reliance on external power grids."
                          values={{
                            strong: (...chunks) => <strong>{chunks}</strong>,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-lg-block">
              <div className="row justify-content-center align-items-center h-100 slider1-image-container">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={panel}
                    className="img-fluid"
                    alt="plastic"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "100%", // Asegura que la imagen no supere la altura máxima del contenedor
                      objectFit: "cover", // Para asegurarte de que la imagen se ajuste al contenedor cuadrado
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
