export const MOBILE_MENU_OPEN = 'MOBILE_MENU_OPEN';
export const MOBILE_MENU_CLOSE = 'MOBILE_MENU_CLOSE';
export const AUTH_MENU_OPEN = 'AUTH_MENU_OPEN';
export const AUTH_MENU_CLOSE = 'AUTH_MENU_CLOSE';
export const AUTH_MODAL_TOGGLE = 'AUTH_MODAL_TOGGLE';
export const AUTH_MODAL_OPEN = 'AUTH_MODAL_OPEN';
export const AUTH_MODAL_CLOSE = 'AUTH_MODAL_CLOSE';
export const AUTH_MODAL_CONTEXT_SET = 'AUTH_MODAL_CONTEXT_SET';
export const SEARCH_BAR_OPEN = 'SEARCH_BAR_OPEN';
export const SEARCH_BAR_CLOSE = 'SEARCH_BAR_CLOSE';
export const CART_ID_SET = 'CART_ID_SET';
export const CART_ID_CLEAR = 'CART_ID_CLEAR';
