import ProseatHeader from "./components/ProseatHeader";
import ProseatGlossary from "./components/ProseatGlossary";
import ProseatImageHotspot from "./components/ProseatImageHotspot";
import { Redirect } from "react-router-dom";
import { isMARINE } from "services/instances";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "./constants/breakpoints";
import useCharacteristicDetails from "./hooks/useCharacteristicDetails";
import "./styles/ProseatPage.scss";

const ProseatPage = () => {
  const isDesktop = useMediaQuery(LG);

  const {
    characteristicDetails,
    handleChangeAndShowCharacteristicInfo,
    handleHideCharacteristicInfo
  } = useCharacteristicDetails();

  return (
    isMARINE()
    ? <div className="proseat-page--main-container px-4 px-lg-5 pt-4 pb-3 pb-xl-0">
        <div className="container p-0">
          <ProseatHeader />
          <ProseatImageHotspot
            characteristicDetails={characteristicDetails}
            handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
            handleHideCharacteristicInfo={handleHideCharacteristicInfo}
          />
          {isDesktop && <ProseatGlossary currentCharacteristic={characteristicDetails} />}
        </div>
      </div>
    : <Redirect to="/page-404" />
  )
}

export default ProseatPage;