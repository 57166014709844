import { useState } from 'react';
import PropTypes from 'prop-types';
import CollectionContext from './CollectionContext';

const CollectionContextProvider = ({ children }) => {
  const [currentCollection, setCurrentCollection] = useState(null);
  const [selectedItemCollection, setSelectedItemCollection] = useState(null);

  return (
    <CollectionContext.Provider value={{ currentCollection, setCurrentCollection, selectedItemCollection, setSelectedItemCollection }}>
      {children}
    </CollectionContext.Provider>
  );
};

CollectionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollectionContextProvider;
