import PropTypes from 'prop-types';
import "./styles/HTMLContent.scss";

export default function HTMLContent({html}) {
  return (
      <div className="html-content">
        <div
            className="html-content__content"
            dangerouslySetInnerHTML={{__html: html}}/>
      </div>
  );
}

HTMLContent.propTypes = {
  html: PropTypes.string.isRequired,
};