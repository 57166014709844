import PropTypes from "prop-types";
import Slider from "react-slick";
import HomeSlide from "modules/Home/components/HomeSlider/components/HomeSlide";
import "./styles/HomeSlider.scss";
import { Query } from "react-apollo";
import homeSlidersQuery from "modules/Home/components/HomeSlider/query";
import { getCurrentInstanceId } from "services/instances";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import LoadingMessage from "utils/components/LoadingMessage";
import { HOME_SLIDER_TAG } from "./constants";

const options = {
  dots: true,
  infinite: true,
  lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
};

const HomeSlider = ({ tag = HOME_SLIDER_TAG.DEFAULT, onlyFirst = false }) => (
  <div className="home-slider">
    <Query
      query={homeSlidersQuery}
      fetchPolicy={FETCH_POLICY_CACHE_AND_NETWORK}
      variables={{
        instanceIds: [getCurrentInstanceId()],
        /**
         *  Warning: doing for no return slider for specifics landings
         * E.g. slider for landing Sustainability Reports with tag SUSTAINABILITY_REPORTS
         */
        tag,
      }}
    >
      {({ loading, data }) => {
        if (loading)
          return (
            <Slider {...options}>
              <HomeSlide />
            </Slider>
          );

        if (data) {
          const {
            homeSliders: { edges: sliders },
          } = data;

          if (sliders && sliders.length) {
            return (
              <Slider {...options}>
                {!onlyFirst ? (
                  sliders.map(({ node }) => (
                    <HomeSlide key={`homeSlide_${node.id}`} slide={node} />
                  ))
                ) : (
                  <HomeSlide
                    key={`homeSlide_${sliders[0].node.id}`}
                    slide={sliders[0].node}
                  />
                )}
              </Slider>
            );
          }
        }

        return <LoadingMessage />;
      }}
    </Query>
  </div>
);

HomeSlider.propTypes = {
  tag: PropTypes.string,
  onlyFirst: PropTypes.bool,
};

export default HomeSlider;
