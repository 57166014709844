import PostsList from "modules/blog/PostsList";
import {FormattedMessage} from "react-intl";
import SeeMoreButton from "utils/components/SeeMoreButton";
import {BLOG_LIST} from "routes"
import {Link} from "react-router-dom"
import {getTranslatedLink} from "services/instances"

const HomePostsList = () => {
  return (
      <div className="container pt-4 pb-4 pt-lg-5 pb-lg-5 home-posts-list">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 text-left">
            <h3 className="text-family-alt h2">
              <Link to={getTranslatedLink(BLOG_LIST)} className="a-inherit">
                <FormattedMessage
                    id="HomePostsList.title"
                    description="Title of blog posts lists in home"
                    defaultMessage="Lo último"/>
              </Link>
            </h3>
            <SeeMoreButton
                href={''}>
              <FormattedMessage
                  id="HomePostsList.seeMore"
                  description="See all posts button in posts list"
                  defaultMessage="Ver todo"/>
            </SeeMoreButton>
          </div>
          <div className="col-12 col-md-12 home-posts-list__container">
            <PostsList
                showPaginator={false}
                isChildren={true}/>
          </div>
        </div>

      </div>

  );
};

export default HomePostsList;
