import _ from "lodash";
import { Field } from "formik";
import { getCurrentInstanceId } from "services/instances";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-apollo";
import marketsSimpleQuery from "components/Header/components/HeaderMainMenu/query";

export default function SectorsField() {
  const { loading, error, data } = useQuery(marketsSimpleQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      saleable: true,
    },
  });

  if (error) return null;

  return (
    <Field
      as="select"
      id="sector"
      name="sector"
      className="form-control"
    >
      {loading ? (
        <FormattedMessage
          id="SectorsField.loading"
          description="Loading label for sectors field"
          defaultMessage="Cargando sectores..."
        >
          {(label) => (
            <option value="" disabled>
              {label}
            </option>
          )}
        </FormattedMessage>
      ) : (
        <>
          <FormattedMessage
            id="SectorsField.label"
            description="Label for sectors field"
            defaultMessage="Selecciona un sector"
          >
            {(label) => <option value="">{label}</option>}
          </FormattedMessage>
          {{
            ...data,
            markets: {
              ...data.markets,
              edges: _.filter(
                data.markets.edges,
                ({ node }) =>
                  _.sumBy(
                    node.sectors.edges,
                    ({ node }) => node.patternSet.totalCount
                  ) > 0
              ),
            },
          }.markets.edges
            .map((market) => ({
              label: market.node.name,
              options: _.filter(
                market.node.sectors.edges,
                ({ node }) => node.patternSet.totalCount > 0
              ).map(({ node }) => ({
                label: node.name,
                value: node.id,
              })),
            }))
            .map((optionGroup, index) => (
              <optgroup key={index} label={optionGroup.label}>
                {optionGroup.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            ))}
        </>
      )}
    </Field>
  );
}