import {connect} from "react-redux";
import HeaderAuthCircleButton from "../index";
import {closeAuthMenu, openAuthMenu} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer,
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openAuthMenu: () => dispatch(openAuthMenu()),
    closeAuthMenu: () => dispatch(closeAuthMenu())
  };
};

const HeaderAuthCircleButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderAuthCircleButton);

export default HeaderAuthCircleButtonContainer;
