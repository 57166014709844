import Skeleton from 'react-loading-skeleton';
import "./styles/skeleton.scss"

const SkeletonComponent = () => (
  <div className="py-4 py-lg-5">
    <div className="row">
      <div className="col-12 col-md-6 align-self-center">
        <Skeleton className="float-right mb-3" height={32} width={"75%"}/>
        <Skeleton className="float-right mb-3" height={24} width={"75%"}/>
        <Skeleton className="float-right my-1" count={5} width={"75%"}/>
        <Skeleton className="float-right d-block mt-3 skeleton-btn" width={64} height={32}/>
      </div>
      <div className="col-12 col-md-6">
        <Skeleton height={350}/>
      </div>
    </div>
  </div>
);

SkeletonComponent.propTypes = {};

export default SkeletonComponent;