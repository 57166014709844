import { Query } from "react-apollo";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import homeSlidersQuery from "modules/Home/components/HomeSlider/query";
import Carousel from "react-bootstrap/Carousel";
import { HOME_SLIDER_TAG } from "modules/Home/components/HomeSlider/constants";
import { getCurrentInstanceId } from "services/instances";
import { useState } from "react";
import logo from "../../utils/firstSection/logo.svg";
import nextArrow from "./img/right-arrow.png";
import prevArrow from "./img/left-chevron.png";
import "./styles/EcoSustainableReports.scss";
import useMediaQuery from "utils/hooks/useMediaQuery";

const EcoSustainableReports = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
      <Query
        query={homeSlidersQuery}
        fetchPolicy={FETCH_POLICY_CACHE_AND_NETWORK}
        variables={{
          instanceIds: [getCurrentInstanceId()],
          /**
           *  Warning: doing for no return slider for specifics landings
           * E.g. slider for landing Sustainability Reports with tag SUSTAINABILITY_REPORTS
           */
          tag: HOME_SLIDER_TAG.SUSTAINABILITY_REPORTS,
        }}
      >
        {({ loading, data }) => {
          if (loading) return <p>CARGANDO...</p>;

          if (data) {
            const {
              homeSliders: { edges: sliders },
            } = data;

            if (sliders && sliders.length) {
              return (
                <Carousel
                  className="custom-indicators-sustainable-slider sustainable-reports--slider"
                  activeIndex={index}
                  onSelect={handleSelect}
                  touch
                  dragging
                  nextLabel=""
                  prevLabel=""
                  nextIcon={
                    <img
                      className="sustainable--slider-next-arrow"
                      src={nextArrow}
                      alt="next-slider-arrow"
                      width={28}
                    />
                  }
                  prevIcon={
                    <img
                      className="sustainable--slider-prev-arrow"
                      src={prevArrow}
                      alt="prev-slider-arrow"
                      width={28}
                    />
                  }
                >
                  {sliders.map(({ node: slide }) => (
                    <Carousel.Item key={`key_${slide.id}`} control={false}>
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="bottom-caroussel-bckg"
                          src={slide.imageThumb}
                          alt={`${slide.title}`}
                          style={{
                            maxHeight: "450px",
                            width: "auto",
                          }}
                        />

                        <div className="bottom-caroussel-text">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <img src={logo} alt="Logo" />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 px-5">
                            <span
                              className="bottom-caroussel-title carousel-title-sustainbility-reports"
                              dangerouslySetInnerHTML={{ __html: slide.title }}
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 bottom-caroussel-desc">
                            <span
                              className="carousel-desc-sustainability-reports"
                              dangerouslySetInnerHTML={{
                                __html: slide.description,
                              }}
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <a
                              className="btn btn-outline-white rounded-pill btn-lg"
                              style={{
                                bottom: "50px",
                                backgroundColor: "transparent",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "2px solid white",
                                marginTop: "20px",
                              }}
                              href={
                                slide?.linkMobile && isMobile
                                  ? slide?.linkMobile
                                  : slide.link
                              }
                              target={slide.linkTarget}
                            >
                              {slide.buttonText}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              );
            }
          }

          return <p>cargando ...</p>;
        }}
      </Query>
    </div>
  );
};

export default EcoSustainableReports;
