import { formatRoute } from "react-router-named-routes";
import { SPGlobalRoutes } from "routes";
import {
  INSTANCE_EUROPE,
  INSTANCE_LATAM,
  INSTANCE_MARINE,
  INSTANCE_MEXICO,
  INSTANCE_RESOURCES,
  INSTANCE_USA,
} from "services/instances/constants";

/**
 * Returns the current instance.
 * @return {string}
 */
export const getCurrentInstanceId = () => {
  const { location } = window;
  const parts = location.pathname.split("/");
  const instanceCode = parts[1].split("-")[1];

  switch (instanceCode) {
    case "la":
      return process.env.REACT_APP_LATAM_INSTANCE_ID;

    case "mx":
      return process.env.REACT_APP_MX_INSTANCE_ID;

    case "us":
      return process.env.REACT_APP_USA_INSTANCE_ID;

    case "eu":
      return process.env.REACT_APP_EU_INSTANCE_ID;

    case "sm":
      return process.env.REACT_APP_SM_INSTANCE_ID;

    case "rc":
      return process.env.REACT_APP_RC_INSTANCE_ID;

    default:
      return process.env.REACT_APP_LATAM_INSTANCE_ID;
  }
};

export const isUSA = () =>
  getCurrentInstanceId() === process.env.REACT_APP_USA_INSTANCE_ID;
export const isLATAM = () =>
  getCurrentInstanceId() === process.env.REACT_APP_LATAM_INSTANCE_ID;
export const isMEXICO = () =>
  getCurrentInstanceId() === process.env.REACT_APP_MX_INSTANCE_ID;
export const isEUROPE = () =>
  getCurrentInstanceId() === process.env.REACT_APP_EU_INSTANCE_ID;
export const isMARINE = () =>
  getCurrentInstanceId() === process.env.REACT_APP_SM_INSTANCE_ID;
export const isRESOURCES = () =>
  getCurrentInstanceId() === process.env.REACT_APP_RC_INSTANCE_ID;

export const isLangEs = isLATAM() || isMEXICO();

/**
 * Returns the language from the browser
 * @returns {string}
 */
const getBrowserLanguage = () => {
  return navigator.language.split(/[-_]/)[0];
};

/**
 * This method should return the instance based on the location of the user
 * This method DOESN'T RETURN THE CURRENT INSTANCE CODE.
 * TODO get the location of the user and return the right instance based on that
 * @return {string}
 */
const getInstanceCode = () => {
  return "la";
};

/**
 * Returns the current language and instance code from the current url
 * @returns {string[]}
 */
export const getCurrentLanguageAndInstanceCode = () => {
  let path = window.location.pathname.split("/");
  let lang = getBrowserLanguage(),
    instance = getInstanceCode();
  const regexp = /^([a-z]{2})-([a-z]{2})$/;
  if (path.length >= 2) {
    path.shift(); // removes the first value which is an empty string
    const firstPart = path.shift(); // gets the first part of the path, which is supposed to be "es-la", for example
    if (regexp.test(firstPart)) {
      const matches = firstPart.match(regexp);
      lang = matches[1];
      instance = matches[2];
    }
  }
  return [lang, instance];
};

/**
 * Retrieves a link to an instance
 * @param name
 * @returns {string}
 */
export const getInstanceLink = (name) => {
  const instances = {
    [INSTANCE_USA.toLowerCase()]: {
      lang: "en",
      code: "us",
      name: "USA",
    },
    [INSTANCE_MARINE.toLowerCase()]: {
      lang: "en",
      code: "sm",
      name: "MARINE",
    },
    [INSTANCE_EUROPE.toLowerCase()]: {
      lang:
        ["en", "es", "it", "fr", "de", "pl"].indexOf(navigator.language) > -1
          ? navigator.language
          : "en",
      code: "eu",
      name: "EUROPE",
    },
    [INSTANCE_MEXICO.toLowerCase()]: {
      lang: "es",
      code: "mx",
      name: "México",
    },
    [INSTANCE_LATAM.toLowerCase()]: {
      lang: "es",
      code: "la",
      name: "Latam",
    },
    [INSTANCE_RESOURCES.toLowerCase()]: {
      lang: "en",
      code: "rc",
      name: "RESOURCES",
    },
  };
  const instance = instances[name.toLowerCase()];
  return `/${instance.lang}-${instance.code}`;
};

export const getTranslatedLink = (name, params) => {
  const [lang, instanceCode] = getCurrentLanguageAndInstanceCode();
  const link = SPGlobalRoutes[name][lang];

  // Verifica si el link es una URL absoluta
  if (/^https?:\/\//.test(link)) {
    return link;
  }

  // Si no es una URL absoluta, formatea como antes
  return `/${lang}-${instanceCode}${formatRoute(link, params)}`;
};

