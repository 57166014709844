import PropTypes from 'prop-types';
import {Collapse} from "reactstrap";
import classNames from 'classnames';
import arrowDownIcon from "./img/Vector.svg";
import "./styles/HigherPurposeAccordion.scss";

export default function PurposesGroupAccordion ({
  children, 
  groupTitle,
  isOpen,
  handleClosePurposesGroup
}) {
  function handleClick() {
    handleClosePurposesGroup(groupTitle);
  }

  return (
      <div className="product-specs-accordion">
        <h2
          className={classNames({
            'px-0 text-white w-100': true,
            'product-specs-accordion__title-higher-purpose d-flex justify-content-between': true,
            'higher-purpose--title-border': !isOpen,
          })}
          onClick={handleClick}
        >
          {groupTitle}
          { isOpen 
            ? <i className="sp-minus" /> 
            : <img src={arrowDownIcon} alt='arrow-down-icon-img' /> 
          }
        </h2>
        <Collapse
            className="product-specs-accordion__collapse-higher-purpose"
            isOpen={isOpen}>
          <table className="table table-striped product-specs-table">
            <tbody>
              {children}
            </tbody>
          </table>
        </Collapse>
      </div>
  )
}

PurposesGroupAccordion.propTypes = {
  groupTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  higherPurposesGroups: PropTypes.array,
  handleClosePurposesGroup: PropTypes.func,
  children: PropTypes.element,
};