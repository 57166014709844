const TEMPORAL_COOKIE_TO_STAY_IN_SPRADLING = {
  temporalCookie: "stay_in_spradling",
  maxAge: 7200,
  path: "/"
}

export const CREATE_COOKIE = () => {
  const { temporalCookie, maxAge, path } = TEMPORAL_COOKIE_TO_STAY_IN_SPRADLING
  document.cookie = `temporal-cookie=${temporalCookie}; max-age=${maxAge}; path=${path}`
}

const GET_COOKIE = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  const foundCookie = cookieArray.find(cookie => cookie.trim().startsWith(name))
  if (foundCookie) {
    const [, cookieValue] = foundCookie.split('=');
    return cookieValue;
  }

  return null;
}

export const existCookie = GET_COOKIE("temporal-cookie");
  if (existCookie) console.log(`El valor de la cookie 'temporal-cookie' es: ${existCookie}`);
  else console.log('La cookie no fue encontrada');