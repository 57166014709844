import { createRoot } from "react-dom/client";
import SPGlobalApp from "app/index";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie9";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "assets/fonts/dalamoa/stylesheet.css";
import "assets/fonts/gramatika/stylesheet.css";
import "assets/fonts/eternate/stylesheet.css";
import "assets/fonts/gill-sans/stylesheet.css";
import "assets/fonts/spglobal/style.css";
import "assets/styles/SPGlobalApp.scss";

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<SPGlobalApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
