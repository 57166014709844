import PropTypes from "prop-types";
import Slider from "react-slick";
import SimpleImage from "../SimpleImage";
import { SLIDER_IMAGES_OPTIONS } from "./constants/sliderOptions";

const CharacteristicSliderImages = ({ characteristicImages }) => {
  return (
    <Slider className="characteristic-image--slider" {...SLIDER_IMAGES_OPTIONS}>
      {characteristicImages?.map((image) => {
        return <SimpleImage
          key={image.key}
          characteristicImage={image}
        />
      })}
    </Slider>
  )
}

CharacteristicSliderImages.propTypes = {
  characteristicImages: PropTypes.array
}

export default CharacteristicSliderImages;