import { useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player";
import play from "../utils/firstVideo/play.svg";
import { getCurrentLanguageAndInstanceCode } from "services/instances";

const LONG_RUN_VIDEO_BY_LANG = {
  es: "https://www.youtube.com/watch?v=DRXZiN34SSg",
  en: "https://www.youtube.com/watch?v=8UoFqVr5AR8",
};

export const VideoSection = () => {
  const [lang] = getCurrentLanguageAndInstanceCode();
  const [playVideo, setPlayVideo] = useState(false);

  const handleVideoClick = () => {
    setPlayVideo(true);
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className="position-relative p-0 mb-5 mt-5">
        <div
          className="container-fluid p-0 m-0"
          onClick={handleVideoClick}
          style={{ cursor: "pointer", display: playVideo ? "none" : "block" }}
        >
          <div className="row p-0 m-0">
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 mb-3 mt-2"
              style={{ position: "relative" }}
            >
              <div className="our-img-bg" />
              <div className="play-btn-container text-center">
                <div className="play-btn">
                  <img src={play} alt="Play" />
                </div>
              </div>
              <div className="text-responsive-our row d-flex justify-content-center">
                <div className="col-lg-9 col-md-9 col-sm-9 col-11">
                  <div className="row d-flex justify-content-center">
                    <div className="col-8 col-lg-11 col-md-11 col-sm-11">
                      <div className="row d-flex justify-content-start mb-3">
                        <div className="col-12 col-lg-10 col-md-10 col-sm-10 p-0 m-0">
                          <span className="wk-title">
                            <FormattedMessage
                              id="EcosensePage.v2.Banner.Video.title"
                              defaultMessage="Shaping tomorrow together"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-start">
                        <div className="col-12 col-lg-6 col-md-6 col-sm-6 p-0 m-0">
                          <p className="wk-content">
                            <FormattedMessage
                              id="EcosensePage.v2.Banner.Video.description"
                              defaultMessage="In our commitment to sustainability, we go beyond
                              trends. Our dedication to creating a better future
                              for generations to come is at the core of everything
                              we do."
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactPlayer
          style={{
            display: playVideo ? "block" : "none",
          }}
          url={LONG_RUN_VIDEO_BY_LANG[lang]}
          width="100%"
          height="98vh"
          controls={true}
          loop
          playing={playVideo}
        />
      </div>
    </div>
  );
};
