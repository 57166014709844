import {connect} from "react-redux";
import HeaderSearchBar from "../index";
import {closeSearchBar} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer,
    localesReducer: state.localesReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSearchBar: () => dispatch(closeSearchBar())
  };
};

const HeaderSearchBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearchBar);

export default HeaderSearchBarContainer;
