import { useContext } from "react";
import PatternMarkets from "modules/products/ProductDetailsPage/components/ProductSidebar/components/PatternMarkets";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import { FormattedMessage } from "react-intl";
import {
  getCurrentInstanceId,
  getTranslatedLink,
  isLATAM,
  isMARINE,
  isMEXICO,
  isUSA,
} from "services/instances";
import { Link } from "react-router-dom";
import { STORE_LOCATIONS } from "routes";
import queryString from "query-string";
import DownloadZippedImagesButtonContainer from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadZippedImagesButton/container";
import DownloadPatternPDFButtonContainer from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadPatternPDFButton/container";
import DownloadApprovedCleanersButton from "./components/DownloadApprovedCleanersButton";

export default function ProductSidebar() {
  const pattern = useContext(PatternContext);
  const hideWhereToBuy = isLATAM() || isMEXICO();
  const showDownloadApprovedCleaners = isUSA() || isMARINE();

  return (
    <div className="product-details-sidebar">
      <div className="product-details-sectors">
        <PatternMarkets />
      </div>
      {pattern && (
        <div className="product-details-actions">
          <DownloadZippedImagesButtonContainer />
          <DownloadPatternPDFButtonContainer />
          {!!pattern.specsSheetData.approved_cleaners.length &&
            pattern.specsSheetData.approved_cleaners[0].value.length &&
            showDownloadApprovedCleaners && <DownloadApprovedCleanersButton />}
          {pattern.maintenanceGuide && (
            <a
              href={pattern.maintenanceGuide}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-dark btn-lg btn-block"
            >
              <FormattedMessage
                id="MaintenanceGuideButton.label"
                description="Product details action button label"
                defaultMessage="Guía de mantenimiento"
              />
            </a>
          )}
          {pattern.transformationGuide && (
            <a
              href={pattern.transformationGuide}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-dark btn-lg btn-block"
            >
              <FormattedMessage
                id="TransformationGuideButton.label"
                description="Product details action button label"
                defaultMessage="Guía de transformación"
              />
            </a>
          )}
          {[
            process.env.REACT_APP_LATAM_INSTANCE_ID,
            process.env.REACT_APP_USA_INSTANCE_ID,
          ].indexOf(getCurrentInstanceId()) > -1 &&
            pattern.id &&
            !hideWhereToBuy && (
              <Link
                to={{
                  pathname: getTranslatedLink(STORE_LOCATIONS, {}),
                  search: `?${queryString.stringify({
                    productId: pattern.id,
                  })}`,
                }}
                className="btn btn-outline-dark btn-lg btn-block"
              >
                <FormattedMessage
                  id="DealerSearchEngine.label"
                  description="Product details action button label"
                  defaultMessage="Buscador de distribuidores"
                />
              </Link>
            )}
        </div>
      )}
    </div>
  );
}
