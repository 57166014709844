import { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import _ from "lodash";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { isMARINE, isRESOURCES } from "services/instances";
import "./styles/HeaderMenuButton.scss";
export default function HeaderMenuButton({
  to,
  text,
  className,
  haveActiveClass = true,
  showDropdownOnMouseEnter,
  renderDropdown,
  closeMobileMenu,
}) {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [showDropdown, setShowDropdown] = useState(false);

  /**
   * Handles click on <button/> elements rendered by this component
   * When this component renders a button is because it contains a dropdown
   * @param {Object} event
   */
  function handleButtonClick(event) {
    if (_.isFunction(renderDropdown)) {
      // this means the menu button shouldn't navigate to de provided link, but show a dropdown
      if (!showDropdownOnMouseEnter) event.preventDefault(); // prevent navigation only if dropdown appears on mouse enter
      setShowDropdown(!showDropdown);
    } else {
      closeMobileMenu();
    }
  }

  /**
   * Handles clicks on the dropdown links to close this dropdown
   */
  function handleDropdownLinkClick() {
    setShowDropdown(false);
  }

  /**
   * Handles onMouseEnter event for the NavLink
   */
  function handleOnMouseEnter() {
    if (showDropdownOnMouseEnter) setShowDropdown(true);
  }

  /**
   * Handles onMouseLeave event for the span wrapping NavLink
   */
  function handleOnMouseLeave() {
    if (showDropdownOnMouseEnter) setShowDropdown(false);
  }

  return (
    <div onMouseLeave={handleOnMouseLeave}>
      <div className="d-flex align-items-center">
        <NavLink
          to={to}
          className={classNames({
            "header-menu-button": true,
            "header-menu-button--marine": isMARINE(),
            [className]: className !== undefined,
            // Only when the dropdown is unfolded and a dropdown was passed as a prop
            // This way dropdown won't close and reopen immediately
            "ignore-react-onclickoutside": showDropdown && renderDropdown,
          })}
          activeClassName={classNames({
            "header-menu-button--active": haveActiveClass,
            "header-menu-button--active--marine":
              haveActiveClass && (isMARINE() || isRESOURCES()),
          })}
          onMouseEnter={isDesktop ? handleOnMouseEnter : undefined}
        >
          {text}
        </NavLink>
        {renderDropdown && !isDesktop && (
          <i
            className={classNames({
              icon: true,
              "icon--marine": isMARINE(),
              "sp-minus": showDropdown,
              "sp-plus": !showDropdown,
            })}
            onClick={handleButtonClick}
          />
        )}
      </div>
      {renderDropdown && renderDropdown(showDropdown, handleDropdownLinkClick)}
    </div>
  );
}

HeaderMenuButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  showDropdownOnMouseEnter: PropTypes.bool,
  className: PropTypes.string,
  haveActiveClass: PropTypes.bool,
  text: PropTypes.node.isRequired, // Node of FormattedMessage
  renderDropdown: PropTypes.func,
  closeMobileMenu: PropTypes.func,
};
