import { useState, useEffect } from "react";
import { testimonialsQuery } from "../query";
import { useQuery } from "react-apollo";
 
const useGetTestimonials = ( slug ) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [testimonialItems, setTestimonialItems] = useState([]);
  const { data } = useQuery(testimonialsQuery, {
    variables: {domain: currentLocation || '', slug: slug}
  });
 
  useEffect(() => {
    if (data) {
      setTestimonialItems(data?.carousels?.edges);
    }
  }, [data])
 
   useEffect(() => {
     if (typeof window !== 'undefined') {
       setCurrentLocation(`${window?.location?.origin}`);
     }
   }, []);
 
  return { testimonialItems }
}
 
export default useGetTestimonials;