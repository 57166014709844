import PropTypes from "prop-types";
import "./styles/HeroTitle.scss";

export default function HeroTitle({ title, image, backgroundImage }) {
  return (
    <div
      className="hero-title"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="hero-title__content">
        <div className="container">
          {image && image}
          <h1 className="hero-title-heading">{title}</h1>
        </div>
      </div>
    </div>
  );
}

HeroTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  image: PropTypes.node,
  backgroundImage: PropTypes.string.isRequired,
};
