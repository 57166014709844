import { FormattedMessage } from "react-intl";
import permacool from "modules/products/ProductDetailsPage/components/ProductSpecs/img/permacool.svg";
import logoPetHorizontal from "modules/products/ProductDetailsPage/components/ProductSpecs/img/logo-pet-horizontal.svg";
import {
  OBJECT_OTHER_ATTRIBUTES,
  TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803,
  RULE_MED_VALUE_TRANSLATION,
} from "../contants";

export function handleRenderValueOtherAttributes(tableData) {
  const IMG_EXTENSIONS = [".svg", ".png", ".jpg"];
  if (IMG_EXTENSIONS.some((ext) => tableData?.value.indexOf(ext) !== -1)) {
    return (
      <>
        <td colSpan={2} className="align-middle text-capitalize">
          {tableData.message}
        </td>
        <td colSpan={2}>
          <img
            className="img img-fluid"
            src={tableData.value}
            alt={tableData.message}
            height={64}
            width={128}
          />
        </td>
      </>
    );
  }

  if (
    tableData &&
    (tableData.message ===
      OBJECT_OTHER_ATTRIBUTES.TEMPERATURE_REDUCTION_TREATMENT_EN ||
      tableData.message ===
        OBJECT_OTHER_ATTRIBUTES.TEMPERATURE_REDUCTION_TREATMENT_ES) &&
    tableData.value === TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803
  ) {
    return (
      <>
        <td colSpan={2}>{tableData.message}</td>
        <td colSpan={2}>
          <img src={permacool} alt={tableData.value} height={30} />
        </td>
      </>
    );
  }

  if (
    tableData &&
    tableData.message === OBJECT_OTHER_ATTRIBUTES.MED &&
    tableData.value
  ) {
    return (
      <>
        <td colSpan={2}>{tableData.message}</td>
        <td colSpan={2}>
          <FormattedMessage {...RULE_MED_VALUE_TRANSLATION} />
        </td>
      </>
    );
  }

  if (tableData && tableData.message === OBJECT_OTHER_ATTRIBUTES.PET_FRIENDLY) {
    return (
      <>
        <td colSpan={2} className="align-middle text-capitalize">
          {tableData.message}
        </td>
        <td colSpan={2}>
          <img
            className="logo-petfrinedly"
            src={logoPetHorizontal}
            alt="petfriendly"
          />
        </td>
      </>
    );
  }

  return (
    <>
      <td colSpan={2}>{tableData.message}</td>
      <td colSpan={2}>{tableData.value}</td>
    </>
  );
}
