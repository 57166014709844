export const HOME_SCENE = "home";
export const MARKET_SCENE = "market";
export const PRODUCTS_LIST_SCENE = "products-list";
export const MARKET_CONTRACT_ES_SCENE = "market-institucional";
export const MARKET_RESIDENTIAL_ES_SCENE = "market-residencial";
export const MARKET_MARINA_ES_SCENE = "market-marina";
export const MARKET_TRANSPORT_ES_SCENE = "market-transporte";
export const MARKET_FOOTWARE_AND_LEATHER_GOODS_ES_SCENE =
  "market-zapateria-y-marroquineria";
export const MARKET_PROTECTION_AND_APPAREL_ES_SCENE =
  "market-vestuario-y-proteccion";
export const MARKET_FLOORING_ES_SCENE = "market-pisos";
export const MARKET_CONTRACT_EN_SCENE = "market-contract";
export const MARKET_RESIDENTIAL_EN_SCENE = "market-residential";
export const MARKET_MARINA_EN_SCENE = "market-marine";
export const MARKET_TRANSPORT_EN_SCENE = "market-transportation";
export const MARKET_FOOTWARE_AND_LEATHER_GOODS_EN_SCENE =
  "market-footware-and-leather-goods";
export const MARKET_PROTECTION_AND_APPAREL_EN_SCENE =
  "market-protection-and-apparel";
export const MARKET_FLOORING_EN_SCENE = "market-flooring";
export const MARKET_PROSTOCK_SM_SCENE = "market-prostock";

export const SECTOR_HEALTHCARE_EN_SCENE = "sector-en-healthcare";
export const SECTOR_HOSPITALITY_EN_SCENE = "sector-en-hospitality";
export const SECTOR_RESTAURANT_EN_SCENE = "sector-en-restaurant";
export const SECTOR_OFFICE_EN_SCENE = "sector-en-office";
export const SECTOR_EDUCATION_EN_SCENE = "sector-en-education";
export const SECTOR_PUBLIC_SPACES_EN_SCENE = "sector-en-public-spaces";

export const SECTOR_EXTERIORS_EN_SCENE = "sector-en-exteriors";
export const SECTOR_INTERIORS_EN_SCENE = "sector-en-interiors";

export const SECTOR_MARINE_EXTERIORS_EN_SCENE = "sector-en-exteriors-2";
export const SECTOR_MARINE_INTERIORS_EN_SCENE = "sector-en-interiors-2";
export const SECTOR_MARINE_PERSONAL_WATER_CRAFT_EN_SCENE =
  "sector-en-personal-water-craft";

export const SECTOR_GENERAL_AUTOMOTIVE_EN_SCENE =
  "sector-en-general-automotive";
export const SECTOR_MASS_TRANSPORTATION_EN_SCENE =
  "sector-en-mass-transportation";
export const SECTOR_MOTORCYCLES_EN_SCENE = "sector-en-motorcycles";
export const SECTOR_RECREATIONAL_VEHICLES_EN_SCENE =
  "sector-en-recreational-vehicles";
export const SECTOR_ATV_EN_SCENE = "sector-en-atv";

export const SECTOR_FOOTWARE_EN_SCENE = "sector-en-footware";
export const SECTOR_LEATHER_GOODS_EN_SCENE = "sector-en-leather-goods";

export const SECTOR_COVERS_EN_SCENE = "sector-en-covers";
export const SECTOR_AWNINGS_EN_SCENE = "sector-en-awnings";
export const SECTOR_CLOTHING_EN_SCENE = "sector-en-clothing";

export const SECTOR_RESIDENTIAL_EN_SCENE = "sector-en-residential";
export const SECTOR_COMMERCIAL_EN_SCENE = "sector-en-commercial";
export const SECTOR_INDUSTRIAL_EN_SCENE = "sector-en-industrial";

export const SECTOR_HEALTHCARE_ES_SCENE = "sector-es-salud";
export const SECTOR_HOSPITALITY_ES_SCENE = "sector-es-hosteleria";
export const SECTOR_RESTAURANT_ES_SCENE = "sector-es-restaurantes";
export const SECTOR_OFFICE_ES_SCENE = "sector-es-oficina";
export const SECTOR_EDUCATION_ES_SCENE = "sector-es-educacion";
export const SECTOR_PUBLIC_SPACES_ES_SCENE = "sector-es-espacios-publicos";

export const SECTOR_EXTERIORS_ES_SCENE = "sector-es-exteriores";
export const SECTOR_INTERIORS_ES_SCENE = "sector-es-interiores";

export const SECTOR_MARINE_EXTERIORS_ES_SCENE = "sector-es-exteriores-2";
export const SECTOR_MARINE_INTERIORS_ES_SCENE = "sector-es-interiores-2";
export const SECTOR_MARINE_PERSONAL_WATER_CRAFT_ES_SCENE =
  "sector-es-motos-acuaticas";

export const SECTOR_GENERAL_AUTOMOTIVE_ES_SCENE = "sector-es-autos";
export const SECTOR_MASS_TRANSPORTATION_ES_SCENE =
  "sector-es-transporte-publico";
export const SECTOR_MOTORCYCLES_ES_SCENE = "sector-es-motos";
export const SECTOR_RECREATIONAL_VEHICLES_ES_SCENE =
  "sector-es-vehiculos-recreacionales";
export const SECTOR_ATV_ES_SCENE = "sector-es-atv";

export const SECTOR_FOOTWARE_ES_SCENE = "sector-es-zapateria";
export const SECTOR_LEATHER_GOODS_ES_SCENE = "sector-es-marroquineria";

export const SECTOR_COVERS_ES_SCENE = "sector-es-forros";
export const SECTOR_AWNINGS_ES_SCENE = "sector-es-carpas-toldos-parasoles";
export const SECTOR_CLOTHING_ES_SCENE = "sector-es-vestuario";

export const SECTOR_RESIDENTIAL_ES_SCENE = "sector-es-residencial";
export const SECTOR_COMMERCIAL_ES_SCENE = "sector-es-comercial";
export const SECTOR_INDUSTRIAL_ES_SCENE = "sector-es-industrial";

export const WHERE_TO_BUY_SCENE = "where-to-buy";
export const CONTACT_PQR_SCENE = "contact-pqr";
export const CONTACT_SCENE = "contact";
export const BRANDS_SCENE = "brands";
export const ECOSENSE_SCENE = "ecosense";
export const WE_ARE_SPRADLING_SCENE = "we-are-spradling";
export const WORK_WITH_US_SCENE = "work-with-us";
export const SPRADLING_IN_THE_WORLD_SCENE = "spradling-in-the-world";
export const PROJECTS_SCENE = "projects";
export const DOCUMENT_LIBRARY_SCENE = "document-library";
export const WHY_CHOOSE_US_SCENE = "why-choose-us";

export const SCENES = [
  HOME_SCENE,
  MARKET_SCENE,
  MARKET_CONTRACT_ES_SCENE,
  MARKET_RESIDENTIAL_ES_SCENE,
  MARKET_MARINA_ES_SCENE,
  MARKET_TRANSPORT_ES_SCENE,
  MARKET_FOOTWARE_AND_LEATHER_GOODS_ES_SCENE,
  MARKET_PROTECTION_AND_APPAREL_ES_SCENE,
  MARKET_FLOORING_ES_SCENE,
  MARKET_CONTRACT_EN_SCENE,
  MARKET_RESIDENTIAL_EN_SCENE,
  MARKET_MARINA_EN_SCENE,
  MARKET_TRANSPORT_EN_SCENE,
  MARKET_FOOTWARE_AND_LEATHER_GOODS_EN_SCENE,
  MARKET_PROTECTION_AND_APPAREL_EN_SCENE,
  MARKET_FLOORING_EN_SCENE,
  MARKET_PROSTOCK_SM_SCENE,
  SECTOR_HEALTHCARE_EN_SCENE,
  SECTOR_HOSPITALITY_EN_SCENE,
  SECTOR_RESTAURANT_EN_SCENE,
  SECTOR_OFFICE_EN_SCENE,
  SECTOR_EDUCATION_EN_SCENE,
  SECTOR_PUBLIC_SPACES_EN_SCENE,
  SECTOR_EXTERIORS_EN_SCENE,
  SECTOR_INTERIORS_EN_SCENE,
  SECTOR_MARINE_EXTERIORS_EN_SCENE,
  SECTOR_MARINE_INTERIORS_EN_SCENE,
  SECTOR_MARINE_PERSONAL_WATER_CRAFT_EN_SCENE,
  SECTOR_GENERAL_AUTOMOTIVE_EN_SCENE,
  SECTOR_MASS_TRANSPORTATION_EN_SCENE,
  SECTOR_MOTORCYCLES_EN_SCENE,
  SECTOR_RECREATIONAL_VEHICLES_EN_SCENE,
  SECTOR_ATV_EN_SCENE,
  SECTOR_FOOTWARE_EN_SCENE,
  SECTOR_LEATHER_GOODS_EN_SCENE,
  SECTOR_COVERS_EN_SCENE,
  SECTOR_AWNINGS_EN_SCENE,
  SECTOR_CLOTHING_EN_SCENE,
  SECTOR_RESIDENTIAL_EN_SCENE,
  SECTOR_COMMERCIAL_EN_SCENE,
  SECTOR_INDUSTRIAL_EN_SCENE,
  SECTOR_HEALTHCARE_ES_SCENE,
  SECTOR_HOSPITALITY_ES_SCENE,
  SECTOR_RESTAURANT_ES_SCENE,
  SECTOR_OFFICE_ES_SCENE,
  SECTOR_EDUCATION_ES_SCENE,
  SECTOR_PUBLIC_SPACES_ES_SCENE,
  SECTOR_EXTERIORS_ES_SCENE,
  SECTOR_INTERIORS_ES_SCENE,
  SECTOR_MARINE_EXTERIORS_ES_SCENE,
  SECTOR_MARINE_INTERIORS_ES_SCENE,
  SECTOR_MARINE_PERSONAL_WATER_CRAFT_ES_SCENE,
  SECTOR_GENERAL_AUTOMOTIVE_ES_SCENE,
  SECTOR_MASS_TRANSPORTATION_ES_SCENE,
  SECTOR_MOTORCYCLES_ES_SCENE,
  SECTOR_RECREATIONAL_VEHICLES_ES_SCENE,
  SECTOR_ATV_ES_SCENE,
  SECTOR_FOOTWARE_ES_SCENE,
  SECTOR_LEATHER_GOODS_ES_SCENE,
  SECTOR_COVERS_ES_SCENE,
  SECTOR_AWNINGS_ES_SCENE,
  SECTOR_CLOTHING_ES_SCENE,
  SECTOR_RESIDENTIAL_ES_SCENE,
  SECTOR_COMMERCIAL_ES_SCENE,
  SECTOR_INDUSTRIAL_ES_SCENE,
  WHERE_TO_BUY_SCENE,
  CONTACT_PQR_SCENE,
  CONTACT_SCENE,
  BRANDS_SCENE,
  ECOSENSE_SCENE,
  WE_ARE_SPRADLING_SCENE,
  SPRADLING_IN_THE_WORLD_SCENE,
  WORK_WITH_US_SCENE,
  PROJECTS_SCENE,
  DOCUMENT_LIBRARY_SCENE,
  WHY_CHOOSE_US_SCENE,
];
