import PropTypes from "prop-types";
import ReferenceCard from "modules/products/ProductDetailsPage/components/ProductHeader/components/ReferencesCarousel/components/ReferenceCard";
import Skeleton from "react-loading-skeleton";
import { handleProductAltName } from "utils/commons/products";

export default function ProductColorsList({ pattern }) {
  return (
    <div className="product-colors-grid">
      <h6 className="text-uppercase text-weight-bold">
        {pattern ? (
          pattern.altName ? (
            <span
              dangerouslySetInnerHTML={{
                __html: handleProductAltName(pattern.altName),
              }}
            />
          ) : (
            pattern.name
          )
        ) : (
          <Skeleton width={150} />
        )}
      </h6>
      <div className="row">
        {(pattern ? pattern.products.edges : new Array(6).fill()).map(
          (reference, index) => (
            <div key={index} className="col-4 col-md-4 col-lg-3 col-xl-2">
              <ReferenceCard
                patternSlug={pattern ? pattern.slug : undefined}
                reference={reference ? reference.node : undefined}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

ProductColorsList.propTypes = {
  pattern: PropTypes.object,
  loading: PropTypes.bool,
};
