import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/FAQItem.scss";

const FAQItem = ({question, answer}) => {
  const [isOpen, setIsOpen] = useState(false)

  function handleButtonClick() {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={classNames({
        "faq-item": true,
        "faq-item--open": isOpen
      })}>
      <button
          type="button"
          className="faq-item__toggle-button"
          onClick={handleButtonClick}>
        <i className={isOpen ? "sp-minus" : "sp-plus"}/>
      </button>
      <h3
          className="faq-item__question"
          onClick={handleButtonClick}>
        {question}
      </h3>
      <p className="faq-item__answer">
        {answer}
      </p>
    </div>
  )
}

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
};

export default FAQItem;
