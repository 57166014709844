import {connect} from "react-redux";
import SignInForm from "../index";
import {toggleAuthModal} from "components/Header/actions";

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: () => dispatch(toggleAuthModal()),
  };
};

const SignInFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInForm);

export default SignInFormContainer;