import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import RequestSamplesForAClientForm from "../RequestSamplesForAClientForm";

export default function RequestSampleForAClientModal({isOpen, toggle, mutationLoading, handleSubmit}) {
  return (
    <Modal
      size={"lg"}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader 
        tag="div"
        toggle={toggle}
      >
        <>
          <h5 className="d-block text-center text-family-alt text-size-h1">
            <FormattedMessage
                id="RequestSampleForAClientModal.title"
                description="Request sample for a client modal header title"
                defaultMessage="Detalle de entrega para un cliente"/>
          </h5>
          <p className="mb-0 gray text-center">
            <FormattedMessage
                id="RequestSampleForAClientModal.description"
                description="Request sample for a client header description"
                defaultMessage="Al completar este formulario, puede solicitar notas de 5x5 y tarjetas de muestra para sus clientes."/>
          </p>
        </>
      </ModalHeader>
      <ModalBody>
        <RequestSamplesForAClientForm
          disableSubmitButton={mutationLoading}
          handleSubmit={handleSubmit}
        />
      </ModalBody>
    </Modal>
  )
}

RequestSampleForAClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  mutationLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
